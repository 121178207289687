import { t } from "i18next";
import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

// Helpers and constants
import showToast from "@/_lib/toast";
import { currencyOptions } from "@/_constants/product";

// Services and interfaces
import { 
    subscriptionEndBehaviourItems,
    payoutFrequencyOptions, 
    payoutScheduleItems,
    payoutWeekdayOptions,
    payoutMonthDayOptions
} from "@/_constants/pay_settings";
import { ApiError } from "@/interfaces/api/error";

import { useGetStorefrontQuery, useUpdateStorefrontMutation } from "@/repositories/storefront";
import IStorefrontSettingsForm, { storefrontSettingsFormSchema } from "@/interfaces/storefront/storefront_settings_form";
import { PayoutSchedule, SubscriptionEndBehaviour } from "@/interfaces/storefront/storefront";

// Components
import { Box } from "@mui/material";
import Button from "@/components/button";
import RadioGroup from "@/components/radio_group";
import SelectInput from "@/components/select_input";


const StorefrontSettings: FC = () => {

    const {data: storefront, isLoading: isLoadingStorefront } = useGetStorefrontQuery();
    const [updateStorefront, { isLoading: isUpdatingStorefront }] = useUpdateStorefrontMutation();

    const { control, watch, setValue, reset, handleSubmit} = useForm<IStorefrontSettingsForm>({
        resolver: zodResolver(storefrontSettingsFormSchema),
        mode: 'onBlur',
        defaultValues: {
            subscription_end_behaviour: 'inactive',
            payout_schedule: 'automatic',
            payout_frequency: 'daily',
            weekly_anchor: 'monday',
            monthly_anchor: 1,
            default_currency: 'gbp'
        }
    });

    const disableFrequency = watch('payout_schedule') === 'manual';
    const showWeekdays = watch('payout_frequency') === 'weekly';
    const showMonthDays = watch('payout_frequency') === 'monthly';

    useEffect(() => {
        if (storefront) {
            reset({
                subscription_end_behaviour: storefront.subscription_end_behaviour ?? 'inactive',
                payout_schedule: storefront.payout_schedule ?? 'automatic',
                payout_frequency: storefront.payout_frequency ?? 'daily',
                weekly_anchor: storefront.weekly_anchor ?? 'monday',
                monthly_anchor: storefront.monthly_anchor ?? 1,
                default_currency: storefront.default_currency ?? 'gbp'
            });
        }
    }, [storefront, reset]);

    const handleUpdateStorefront = (data: IStorefrontSettingsForm) => {
        if (!storefront) return;

        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value);
        });

        updateStorefront({id: storefront.id, data: formData}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.storefront.updated.title'), 
            });
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Box 
            display="flex" 
            flexDirection="column" 
            rowGap="24px"
            alignItems="flex-start" 
            textAlign="left" 
            padding="24px 32px" 
            borderRadius="6px" 
            border="1px solid var(--border-subtle-01)" 
            bgcolor="var(--layer-01)"
            >
            
            <Box display="flex" flexDirection="column">
                <span className="heading-05" style={{marginBottom: '12px'}}>{t('pages.settings.storefront.settings.title')}</span>
                <span className="body-02" style={{whiteSpace: 'pre-wrap'}}>{t('pages.settings.storefront.settings.text')}</span>
            </Box>

            <RadioGroup
                defaultValue={storefront?.subscription_end_behaviour}
                label={t('components.radioGroup.titles.afterSubscriptionCancelled')}
                items={subscriptionEndBehaviourItems}
                isLoading={isLoadingStorefront}
                onChange={(v: SubscriptionEndBehaviour) => setValue('subscription_end_behaviour', v)}
                />
            <RadioGroup
                defaultValue={storefront?.payout_schedule}
                label={t('components.radioGroup.titles.payoutSchedule')}
                items={payoutScheduleItems}
                row
                isLoading={isLoadingStorefront}
                onChange={(v: PayoutSchedule) => setValue('payout_schedule', v)}
                />
            <Box display="flex" columnGap="12px">
                <SelectInput
                    name="payout_frequency"
                    control={control}
                    label={t('components.select.titles.payoutFrequency')}
                    items={payoutFrequencyOptions}
                    fullWidth={false}
                    disabled={disableFrequency}
                    />
                {showWeekdays && <SelectInput
                    name="weekly_anchor"
                    control={control}
                    label={t('components.select.titles.payoutDay')}
                    items={payoutWeekdayOptions}
                    fullWidth={false}
                    disabled={disableFrequency}
                    />}
                {showMonthDays && <SelectInput
                    name="monthly_anchor"
                    control={control}
                    label={t('components.select.titles.payoutDay')}
                    items={payoutMonthDayOptions}
                    fullWidth={false}
                    disabled={disableFrequency}
                    />}
            </Box>

            <SelectInput
                name="default_currency"
                control={control}
                label={t('components.select.titles.accountCurrency')}
                tooltip="tooltips.accountCurrency"
                items={currencyOptions}
                fullWidth={false}
                disabled
                />


            <Box display="flex" width="100%" justifyContent="flex-end">
                <Button
                    size="small"
                    label={t('components.buttons.save')}
                    loading={isUpdatingStorefront}
                    onClick={handleSubmit(handleUpdateStorefront)}
                    />
            </Box>

        </Box>
    )
}

export default StorefrontSettings;