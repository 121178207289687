import { FC } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

// Helpers
import { capitalize } from "@/_helpers/text_functions";

// Services and interfaces
import { useGetProspectsQuery } from "@/repositories/prospects";
import { selectMembers, selectSelectedMember } from "@/store/team";
import { ITransferForm } from "@/interfaces/transfer/transfer_form";

// Components
import { Box } from "@mui/material";
import SelectInput from "@/components/select_input";
import MultiselectInput from "@/components/multiselect_input";


interface _TransferFormProps {
    isMultiselect?: boolean;
}

const TransferForm: FC<_TransferFormProps> = ({
    isMultiselect = false
}) => {

    const { t } = useTranslation();
    const { control } = useFormContext<ITransferForm>();
    const selectedMember = useSelector(selectSelectedMember);
    const members = useSelector(selectMembers).filter(member => member.id !== selectedMember?.id);

    const { data: list, isLoading } = useGetProspectsQuery(selectedMember?.id);

    return (
        <Box display="flex" flexDirection="column" gap={3}>
            {isMultiselect ? 
                <MultiselectInput
                    control={control}
                    name="ids"
                    label={t('components.select.titles.selectProspect', {count: 2})}
                    items={list ?? []}
                    itemKey="id"
                    itemValue="id"
                    itemsLoading={isLoading}
                    renderLabel={(item) => `${capitalize(item.first_name)} ${capitalize(item.last_name)}`}
                    /> :
                <SelectInput
                    control={control}
                    name="id"
                    label={t('components.select.titles.selectProspect', {count: 1})}
                    items={list ?? []}
                    itemKey="id"
                    itemValue="id"
                    itemsLoading={isLoading}
                    renderLabel={(item) => `${capitalize(item.first_name)} ${capitalize(item.last_name)}`}
                    />}
            <SelectInput
                control={control}
                name="coach"
                label={t('components.select.titles.transferTo')}
                items={members ?? []}
                itemKey="id"
                itemValue="id"
                renderLabel={(item) => capitalize(item.name) ?? ''}
                />
        </Box>
    )
}

export default TransferForm;