import { t } from "i18next";
import { ISelectOption } from "@/interfaces/components/select_option";


export const transactionStatusEnums: ISelectOption<string>[] = [
    { id: 1, label: t('enums.transactionStatus.pending'), value: 'pending' },
    { id: 2, label: t('enums.transactionStatus.succeeded'), value: 'succeeded' },
    { id: 3, label: t('enums.transactionStatus.failed'), value: 'failed' },
    { id: 4, label: t('enums.transactionStatus.refunded'), value: 'refunded' },
    { id: 5, label: t('enums.transactionStatus.partially_refunded'), value: 'partially_refunded' }
];