import { FC, forwardRef } from "react";
import { Box, Chip, ChipOwnProps } from "@mui/material";


interface TagProps {
    colour?: 'high-contrast' | 'grey' | 'cool-grey' | 'warm-grey' | 'red' | 'orange' | 'magenta' | 'purple' | 'blue' | 'cyan' | 'teal' | 'green' | 'outline';
    size?: 'small' | 'medium' | 'large';
    onSelect?: ()=>void;
    active?: boolean;
    label: string;
    index?: number;
    outlined?: boolean;
    sx?: ChipOwnProps['sx']
}

const Tag: FC<TagProps> = forwardRef(({
    size = 'small',
    colour,
    onSelect,
    active = true,
    label,
    index = 0,
    outlined = false,
    sx,
    ...props
}, ref) => {

    const classes = {
        'Tag': true,
        'Tag-small': size == 'small',
        'Tag-medium': size == 'medium',
        'Tag-large': size == 'large',
      }
    
    const validClasses = Object.entries(classes)
        .filter(([,v]) => !!v)
        .map(([k,]) => k)
        .join(' ')

    // Based on the index, return the color for the avatar (10 colors available)
    const colors = [
        'blue',
        'green',
        'magenta',
        'cyan',
        'purple',
        'teal',
        'grey',
        // 'red',
        // 'high-contrast',
        // 'cool-grey',
        // 'warm-grey',
    ]

    const selectColour = active ? 'high-contrast' : 'outline' 
    const color = !!onSelect ? selectColour : colors[index % colors.length];

    return (
        // Wrapped in a Box to allow for the ref to be passed, as Chip does not accept a ref prop
        <Box ref={ref} {...props} display="inline-block">
            <Chip
                onClick={onSelect}
                className={validClasses}
                label={label}
                sx={{
                    backgroundColor: `var(--tag-${colour ?? color}-background)`,
                    color: `var(--tag-${colour ?? color}-color)`,
                    border: outlined || color === 'outline' ? `solid 1px var(--tag-${colour ?? color}-border)` : 'unset',
                    '&:hover': {
                        cursor: !!onSelect ? 'pointer' : 'default',
                        backgroundColor: `var(--tag-${colour ?? color}-hover)`,
                    },
                    ...sx,
                }}
            />
        </Box>
    )
});

export default Tag;
export type { TagProps };