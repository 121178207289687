import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { selectCoachId } from "@/store/coach";
import { ApiError } from "@/interfaces/api/error";
import { IProspect } from "@/interfaces/prospect/prospect";
import { ITransferManyForm } from "@/interfaces/transfer/transfer_form";
import { selectHasTeam, selectIsMember, selectSelectedMember } from "@/store/team";
import { useDeleteProspectsMutation, useGetProspectsQuery, useTransferProspectsMutation } from "@/repositories/prospects";

// Styles
import { Add } from "@carbon/icons-react";

// Compnents
import NoProspects from "./no_prospects";
import { Container } from "@mui/material";
import DataTable from "@/components/datatable";
import AddProspectModal from "./prospect_modal";
import DeleteProspectModal from "./delete_modal";
import TransferManyModal from "./transfer_many_modal";
import useProspectTableHeaders from "./table_headers_prospects";
import useBulkActions from "@/components/datatable/use_bulk_actions";
import { useSearchParams } from "react-router-dom";


const ProspectsTab: FC = () => {

    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const headers = useProspectTableHeaders();
    const hasTeam = useSelector(selectHasTeam);
    const [open, setOpen] = useState<string|null>(null);
    const [row, setRow] = useState<IProspect|undefined>(undefined);
    const coachId = useSelector(selectCoachId);
    const isMember = useSelector(selectIsMember);
    const selectedMember = useSelector(selectSelectedMember);
    const disableTransferMany = !hasTeam || isMember;
    const disableDeleteMany = hasTeam && selectedMember?.id !== coachId;
    const { selected, handleSetSelected, handleSetShowBulkActions } = useBulkActions();

    const [transferProspects, { isLoading: isTransferringMany }] = useTransferProspectsMutation();
    const [deleteProspects, { isLoading: isDeletingMany }] = useDeleteProspectsMutation();
    const { data: list, isLoading } = useGetProspectsQuery(hasTeam ? selectedMember?.id : undefined, {
        skip: hasTeam && !selectedMember
    });

    const noDataMessage = () => {
        if (!hasTeam) return t('components.dataTable.noData.noProspects');
        if (selectedMember?.id !== coachId) return t('components.dataTable.noData.noTeamProspects', {name: selectedMember?.name});
    }

    const handleEditProspect = (row: IProspect) => {
        setRow(row);
        setOpen('edit');
    }

    const handleTransferMany = (data: ITransferManyForm) => {
        transferProspects(data).unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.prospects.transferred.title', {count: data.ids.length})});
            setOpen(null);
            handleSetShowBulkActions(false);
            handleSetSelected([]);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const handleDeleteMany = () => {
        deleteProspects(selected).unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.prospects.deleted.title', {count: selected.length})});
            setOpen(null);
            handleSetShowBulkActions(false);
            handleSetSelected([]);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    useEffect(() => {
        const prospectId = searchParams.get('prospect');
        if (!prospectId) return;
        if (prospectId && list) {
            const prospect = list.find((prospect) => prospect.id == prospectId);
            if (prospect) {
                handleEditProspect(prospect);
            }
        }
    }, [searchParams, list]);


    return (
        <Container sx={{padding: '24px 0', overflowY: 'auto'}}>

            <DataTable
                data={list}
                dataKey="id"
                columns={headers} 
                hideHeader
                filter={{
                    showSelect: true,
                    button: {
                        label: t('components.buttons.addProspect'),
                        icon: <Add />,
                        onClick: () => setOpen('add')
                    },
                }}
                filterKeys={["full_name"]}
                bulkActions={{
                    action1: disableDeleteMany ? undefined : {
                        label: t('components.buttons.delete'),
                        onClick: () => setOpen('deleteMany'),
                    },
                    action2: disableTransferMany ? undefined : {
                        label: t('components.buttons.transfer'),
                        onClick: () => setOpen('transferMany'),
                    },
                }}
                localPagination
                noDataMessage={<NoProspects message={noDataMessage()} disabled={hasTeam && selectedMember?.id !== coachId} />}
                noDataFoundMessage={<NoProspects disabled={hasTeam && selectedMember?.id !== coachId} />}
                isLoading={isLoading}
                rowClick={handleEditProspect}
                />
            {open === 'add' && 
                <AddProspectModal 
                    open={open === 'add'} 
                    onClose={() => setOpen(null)}
                    />}
            {open === 'edit' && 
                <AddProspectModal 
                    open={open === 'edit'} 
                    onClose={() => {setOpen(null); setRow(undefined)}}
                    prospect={row}
                    />}
            {open === 'transferMany' && <TransferManyModal
                open={open === 'transferMany'}
                onClose={() => setOpen(null)}
                rowIds={selected}
                isLoading={isTransferringMany}
                onSubmit={handleTransferMany}
                />}
            {open === 'deleteMany' && <DeleteProspectModal
                open={open === 'deleteMany'}
                onClose={() => setOpen(null)}
                count={selected.length}
                isLoading={isDeletingMany}
                onDelete={handleDeleteMany}
                />}


        </Container>
    );
};

export default ProspectsTab;