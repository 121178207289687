import baseApi from '@/repositories/base_api';
import { createSelector } from '@reduxjs/toolkit';

// Interfaces
import { IAPIResponse } from '@/interfaces/api/response';
import IStorefront from '@/interfaces/storefront/storefront';
import IConnectProviderForm, { IConnectProviderResponse } from '@/interfaces/storefront/connect_provider_form';


export const storefrontApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getStorefront: build.query<IStorefront, void>({
            query: () => ({ url: '/storefront', method: 'get' }),
            transformResponse: (response: IAPIResponse<IStorefront>) => response.data,
            providesTags: ['Storefront'],
            keepUnusedDataFor: 1800
        }),
        updateStorefront: build.mutation<IStorefront, {id: number, data: FormData}>({
            query: ({id, data}) => ({ url: `/storefront/${id}`, method: 'POST', data, headers: {'Content-Type': 'multipart/form-data'} }),
            transformResponse: (response: IAPIResponse<IStorefront>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(storefrontApi.util.updateQueryData('getStorefront', undefined, storefront => {
                    return response.data;
                }))).catch(() => {});
            },
        }),
        onboardProvider: build.mutation<IConnectProviderResponse, {id: number, data: IConnectProviderForm}>({
            query: ({id, data}) => ({ url: `/storefront/${id}/onboard`, method: 'POST', data}),
            transformResponse: (response: IAPIResponse<IConnectProviderResponse>) => response.data,
        }),
        connectProvider: build.mutation<IStorefront, {id: number, data: IConnectProviderForm}>({
            query: ({id, data}) => ({ url: `/storefront/${id}/connect`, method: 'POST', data}),
            transformResponse: (response: IAPIResponse<IStorefront>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(storefrontApi.util.updateQueryData('getStorefront', undefined, storefront => {
                    return response.data;
                }))).catch(() => {});
            },
        }),
        disconnectProvider: build.mutation<IStorefront, number>({
            query: (id) => ({ url: `/storefront/${id}/disconnect`, method: 'PATCH' }),
            transformResponse: (response: IAPIResponse<IStorefront>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(storefrontApi.util.updateQueryData('getStorefront', undefined, storefront => {
                    return response.data;
                }))).catch(() => {});
            }
        })
    }),
})

export const { 
    useGetStorefrontQuery,
    useUpdateStorefrontMutation,
    useOnboardProviderMutation,
    useConnectProviderMutation,
    useDisconnectProviderMutation
} = storefrontApi;

export const selectPaymentGatewayConfigured = createSelector([state => state, (_, params) => params], (state, params) =>
    storefrontApi.endpoints.getStorefront.select(params)(state)?.data?.provider ?? null)

export const selectStorefrontCurrency = createSelector([state => state, (_, params) => params], (state, params) =>
    storefrontApi.endpoints.getStorefront.select(params)(state)?.data?.default_currency ?? null)