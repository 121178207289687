import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

// Services and interfaces
import { Options } from "@/components/menu";

import { ITransaction, ITransactionQueryParams } from "@/interfaces/transaction/transaction";

// Components
import { Box } from "@mui/material";
import DetailsModal from "./details_modal";
import OverflowMenu from "@/components/overflow_menu";
import RefundModal from "@/components/transactions/refund_modal";


interface _TransactionsMenuProps {
    row: ITransaction;
    params?: ITransactionQueryParams
}

const TransactionMenu: FC<_TransactionsMenuProps> = ({
    row,
    params
}) => {

    const { t } = useTranslation();
    const [open, setOpen] = useState<string|null>(null);
    const disableRefund = row.status === 'refunded' || row.status === 'failed';

    const options: Options[] = [
        {name: t('components.menuItems.refund'), disabled: disableRefund, action: () => setOpen('refund')},
        {name: t('components.menuItems.sendReceipt'), action: () => setOpen('send')},
        {name: t('components.menuItems.transactionDetails'), action: () => setOpen('details')},
    ];

    return (
        <Box display="flex" justifyContent="flex-end">
            <OverflowMenu 
                position={{horizontal: 'right', vertical: 'bottom'}}
                options={options}
                deleteLabel={t('components.menuItems.delete')}
                disableDelete
                />
            {open === 'refund' && 
                <RefundModal 
                    open={open === 'refund'} 
                    onClose={() => setOpen(null)}
                    transaction={row}
                    params={params}
                    />}
            {open === 'details' && 
                <DetailsModal 
                    open={open === 'details'} 
                    onClose={() => setOpen(null)}
                    transaction={row}
                    />}
            
        </Box>
    )
}

export default TransactionMenu;