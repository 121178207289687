import dayjs from "dayjs";
import { FC } from "react";
import { t } from "i18next";
import { useFormContext } from "react-hook-form";

// Helpers
import { getCurrencySymbol } from "@/_helpers/currency_functions";

// Services and interfaces
import { IPaymentProduct } from "@/interfaces/client/client";
import { ISelectOption } from "@/interfaces/components/select_option";
import { IClientSubscriptionEndForm } from "@/interfaces/client_subscription/client_subscription_form";

// Components
import { Box } from "@mui/material";
import SelectInput from "@/components/select_input";
import RadioGroup, { IRadioItem } from "@/components/radio_group";


interface _SubscriptionCancelFormProps {
    product: IPaymentProduct
}

const SubscriptionCancelForm: FC<_SubscriptionCancelFormProps> = ({
    product
}) => {

    const { control, watch, setValue } = useFormContext<IClientSubscriptionEndForm>();

    const disableRefund = watch('end_at') === 'end_of_period';
    const periodTotal = Math.ceil((new Date(product.current_period_end).getTime() - new Date(product.current_period_start).getTime()) / (1000 * 60 * 60 * 24));
    const remainingDays = Math.ceil((new Date(product.current_period_end).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
    const proratedRefund = Math.round((product.price * remainingDays) / periodTotal);
    
    const subscriptionEndOptions: IRadioItem[] = [
        { label: t('components.radioGroup.options.immediatelyDate', {date: dayjs().format('DD/MM/YYYY')}), value: 'now' },
        { label: t('components.radioGroup.options.endOfCurrentPeriod', {date: dayjs(product.current_period_end).format('DD/MM/YYYY')}), value: 'end_of_period' },
    ];

    const refundOptions: ISelectOption<string>[] = [
        { id: 1, label: t('components.select.options.refund.none'), value: 'none' },
        { id: 2, label: t('components.select.options.refund.partialValue', {value: `${getCurrencySymbol(product.currency)}${(proratedRefund/100).toLocaleString(undefined, {maximumFractionDigits: 2})}`}), value: 'partial' },
        { id: 3, label: t('components.select.options.refund.fullValue', {value: `${getCurrencySymbol(product.currency)}${(product.price/100).toLocaleString(undefined, {maximumFractionDigits: 2})}`}), value: 'full' },
    ];

    const handleEndAtChange = (value: 'now'|'end_of_period') => {
        setValue('end_at', value);
        if (value === 'end_of_period') {
            setValue('refund', 'none');
        }
    }

    return (
        <Box display="flex" flexDirection="column">
            <RadioGroup
                label={t('components.radioGroup.titles.endDate')}
                items={subscriptionEndOptions}
                defaultValue="now"
                onChange={handleEndAtChange}
                />
            <SelectInput
                name="refund"
                control={control}  
                label={t('components.select.titles.refund')}
                items={refundOptions}
                disabled={disableRefund}
                />
        </Box>
    )
}

export default SubscriptionCancelForm;