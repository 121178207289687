import { t } from "i18next";
import { Trans } from "react-i18next";
import { FC, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { IActivityPlan } from "@/interfaces/activity_plan/activity_plan";
import { useSetActiveActivityPlanMutation } from "@/repositories/activity_plan";

// Components
import Toggle from "@/components/toggle";
import { useParams } from "react-router-dom";
import useBulkActions from "@/components/datatable/use_bulk_actions";


interface _IPlanActiveForm {
    active: boolean;
}

interface TableToggleProps {
    row: IActivityPlan;
}

const TableToggle: FC<TableToggleProps> = ({
    row
}) => {

    const { id } = useParams();
    const { showBulkActions } = useBulkActions();
    const [setActive, { isLoading }] = useSetActiveActivityPlanMutation();
    const { control, reset } = useForm<_IPlanActiveForm>({
        mode: 'onBlur',
        defaultValues: {
            active: row.active ?? false
        }
    });
    const active = useWatch({control, name: 'active'});

    useEffect(() => {
        reset({active: row.active ?? false});
    }, [row])

    const submit = () => {
        setActive({client_id: Number(id), plan_id: row.id}).unwrap().then((plan) => {
            showToast({
                type: 'success', 
                title: t('notifications.plan.active.title', {context: `${plan.active}`}),
                message: <Trans 
                    i18nKey="notifications.plan.active.message"
                    components={{ strong: <strong /> }}
                    values={{context: `${plan.active}`, name: row.name}} />
            })
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Toggle 
            name="active"
            control={control}
            label={active ? t('components.toggle.planState.active') : t('components.toggle.planState.inactive')}
            showCheckedTick
            onChange={submit}
            disabled={isLoading || row.draft || showBulkActions || row.readonly}
            sx={{marginRight: '0'}}
            />
    )
}

export default TableToggle;