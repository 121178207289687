import { FC, useEffect } from "react";
import { t } from "i18next";
import { useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm, useWatch } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { IDataMetrics } from "@/interfaces/settings/data_metrics";
import { useUpdateClientSettingsMutation } from "@/repositories/client";
import { IClientSettingsForm, ISettingsForm, settingsSchema } from "@/interfaces/client/client_settings_form";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import Box from "@mui/material/Box";
import Modal from "@/components/modal/modal";
import DataMetrics from "@/pages/common/data_metrics";


interface _SettingsModalProps {
    open: boolean;
    onClose: () => void;
    settings: IDataMetrics;
}

const SettingsModal: FC<_SettingsModalProps> = ({
    open,
    onClose,
    settings
}) => {

    const { id } = useParams();
    const [updateClientSettings, { isLoading }] = useUpdateClientSettingsMutation()

    const formMethods = useForm<ISettingsForm>({
        resolver: zodResolver(settingsSchema),
        mode: 'onBlur',
        defaultValues: {
            physique: settings.physique,
            training: settings.training,
            supplementation: settings.supplementation,
            activity: settings.activity,
            recovery: settings.recovery,
            health: settings.health,
        }
    });

    const training = useWatch({ control: formMethods.control, name: 'training' });
    
    const handleClose = () => { 
        formMethods.reset(); 
        onClose(); 
    }

    const submitEdit = (data: ISettingsForm) => {
        const payload: IClientSettingsForm = {
            coach_settings: data
        }
        updateClientSettings({ id: Number(id), data: payload}).unwrap().then(() => {
            showToast({
                type: 'success',
                title: t('notifications.client.settingsUpdated.title'),
            });
            handleClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    useEffect(() => {
        if (training.rpe) return formMethods.setValue('training.rir', false, { shouldDirty: true });
    }, [training.rpe])

    useEffect(() => {
        if (training.rir) return formMethods.setValue('training.rpe', false, { shouldDirty: true });
    }, [training.rir])

    return (
        <Modal
            open={open}
            onClose={!formMethods.formState.isDirty ? onClose : undefined} 
         
            title={t('modals.clientSettings.metrics')}
            action1={{
                label: t('components.buttons.save'),
                icon: <ArrowRight />,
                disabled: !formMethods.formState.isDirty || !formMethods.formState.isValid,
                loading: isLoading,
                onClick: formMethods.handleSubmit(submitEdit)
            }}  
            cancel={{
                label: t('components.buttons.cancel'),
                onClick: handleClose
            }} 
        >
            <Box width={670} height={600}>
                <FormProvider {...formMethods}>
                    <DataMetrics />
                </FormProvider>
            </Box>
        </Modal>
    )
}

export default SettingsModal;