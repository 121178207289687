import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BaseSyntheticEvent, FC, useRef, useState } from "react"

// Interfaces
import IGallery from "@/interfaces/gallery/gallery";
import { downloadQuery } from "@/repositories/media";
import { useGalleryContext } from "@/contexts/gallery_context";
import { useGetGalleryDatesQuery, useGetGalleryQuery } from "@/repositories/gallery";

// Styles
import {  ArrowLeft, ArrowRight, ChevronDown, Close, Download } from "@carbon/icons-react";

// Components
import Box from "@mui/material/Box";
import Image from "@/components/image";
import Button from "@/components/button";
import Backdrop from "@mui/material/Backdrop";
import Menu, { Options } from "@/components/menu";
import IconButton from "@/components/icon_button";
import LoadingSpinner from "@/components/loading_spinner";


interface _ComparisonDialogProps {
    galleries: IGallery[];
    onEndReached: () => void;
}

const ComparisonDialog: FC<_ComparisonDialogProps> = ({
    galleries,
    // onEndReached
}) => {

    const defaultUUID = `${galleries?.[0]?.id}-0`

    const { lightboxState, setLightboxState } = useGalleryContext()
    
    const handleClose = (e: BaseSyntheticEvent) => {
        if (!e.target.className.includes('MuiBackdrop-root')) return;
        setLightboxState(null)
    }

    if (!Array.isArray(lightboxState)) return <></>

    return (
        <Backdrop 
            onClick={handleClose}
            open={Array.isArray(lightboxState)}
            sx={{ zIndex: 10000, p: 2, bgcolor: '#161616E5' }}
        >

            {/* Actions box */}
            <Box className='MuiBackdrop-root' display='flex' justifyContent='center' gap={2} height='100%' width='100%'>

                {/* Left side */}
                <ComparisonSide
                    value={lightboxState[0] ?? defaultUUID} 
                    onChange={(uuid) => setLightboxState([uuid, lightboxState[1]])}
                />

                {/* Right side */}
                <ComparisonSide
                    value={lightboxState[1] ?? defaultUUID}
                    onChange={(uuid) => setLightboxState([lightboxState[0], uuid])}
                />
                
                <IconButton 
                    sx={{position: 'absolute', top: 0, right: 0, m: 2, zIndex: 1000}}
                    icon={<Close />} 
                    onClick={()=>setLightboxState(null)}
                />

            </Box>
        </Backdrop>
    );
}

export default ComparisonDialog;

interface ComparisonSide {
    value: string;
    onChange: (uuid: string) => void;
}

const ComparisonSide: FC<ComparisonSide> = ({
    value,
    onChange
}) => {
    const { t } = useTranslation()

    const { id } = useParams();
    const [galleryId, imgIdx] = value.split("-")
    
    const { data: dates } = useGetGalleryDatesQuery(Number(id))
    const { data: gallery, isLoading } = useGetGalleryQuery({ clientId: Number(id), id: galleryId })

    // menu
    const options: Options[] = dates?.map(d => ({
        key: d.id.toString(),
        name: `${t(`enums.galleryType.${d.type}`)}: ${d.date}`,
        action: () => onChange(`${d.id}-0`)
    })) ?? []
    
    const [open, setOpen] = useState<boolean>(false)
    const buttonRef = useRef<HTMLButtonElement|null>(null)

    const prev = () => onChange(`${galleryId}-${Number(imgIdx)-1}`)
    const next = () => onChange(`${galleryId}-${Number(imgIdx)+1}`)

    return (
        <Box bgcolor='black' width="45%" height='100%' position='relative' borderRadius='6px' overflow='hidden' sx={{
            '& > button, & :not(.Gallery-Dialog__toolbar) button': {
                opacity: 0,
                transition: 'opacity 0.1s linear'
            },
            '&:hover button': {
                opacity: 1
            }        
        }}>
            <Box className="Gallery-Dialog__toolbar" sx={{
                bgcolor: 'var(--background)',
                display: 'flex',
                justifyContent: 'space-between',
                borderBottom: '1px solid var(--border-subtle-01)'
            }}>
                <Button
                    kind='ghost'
                    ref={buttonRef}
                    sx={{color: 'black !important'}}
                    label={!!gallery ? `${t(`enums.galleryType.${gallery?.type}`)}: ${gallery?.date}` : ''}
                    endIcon={<ChevronDown />}
                    onClick={()=>setOpen(true)}
                />
                <Menu 
                    anchorEl={buttonRef.current}
                    open={open}
                    onClose={()=>setOpen(false)}
                    disableDelete
                    zIndex={20000}
                    position={{ horizontal: 'center', vertical: 'bottom' }}
                    activeOption={gallery?.id.toString()}
                    options={options}
                />
                <IconButton 
                    kind='ghost'
                    icon={<Download />}
                    disabled={!gallery}
                    onClick={()=>downloadQuery(gallery!.content[Number(imgIdx)].original)}
                />
            </Box>

            <IconButton 
                sx={{position: 'absolute', left: 0, top: '50%', mx: 1, zIndex: 1000}}
                icon={<ArrowLeft />}
                disabled={Number(imgIdx) === 0}
                onClick={prev}
            />
            
            <IconButton 
                sx={{position: 'absolute', right: 0, top: '50%', mx: 1, zIndex: 1000}}
                icon={<ArrowRight />} 
                disabled={Number(imgIdx) === (gallery?.content.length ?? 1) - 1} 
                onClick={next} 
            />

                
            <Box height='95%' width='100%' position='relative'>
                {isLoading && (
                    <Box height='100%' display='grid'>
                        <LoadingSpinner sx={{placeSelf: 'center'}} />
                    </Box>
                )}

                {!!gallery && (
                    <Image 
                        key={gallery.content[Number(imgIdx)].uuid}
                        src={gallery.content[Number(imgIdx)].original}
                        alt={gallery.content[Number(imgIdx)].uuid}
                    />
                )}
            </Box>
        </Box>
    )
}