import { z } from "zod";
import ruleset from "@/_helpers/ruleset";


export interface IClientSubscriptionEndForm {
    end_at: 'now' | 'end_of_period';
    refund: 'none' | 'partial' | 'full';
}

export interface IClientSubscriptionChangeForm extends IClientSubscriptionEndForm {
    payment_product: number|string;
}

export const clientSubscriptionEndSchema = z.object({
    end_at: ruleset.select,
    refund: ruleset.select,
});

export const clientSubscriptionChangeSchema = z.object({
    ...clientSubscriptionEndSchema.shape,
    payment_product: ruleset.clientProduct
}); 
