import { FC } from "react";
import { useTranslation } from "react-i18next";

// Components
import Modal from "@/components/modal/modal";


interface _ArchiveProductModalProps {
    open: boolean;
    onClose: () => void;
    isLoading: boolean;
    onArchive: () => void;
}

const ArchiveProductModal: FC<_ArchiveProductModalProps> = ({
    open,
    onClose,
    isLoading,
    onArchive
}) => {

    const { t } = useTranslation();

    return (
        <Modal 
            open={open}
            onClose={onClose}
            title={t('modals.archiveProduct.title')}
            text={t('modals.archiveProduct.text')}
            action1={{
                kind: 'danger',
                label: t('components.buttons.archive'),
                loading: isLoading,
                onClick: onArchive
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '448px', background: 'var(--background)'}}}
            />
    )
}

export default ArchiveProductModal;