import { z } from "zod";
import ruleset, { transformEmptyStringToNull } from "@/_helpers/ruleset";


export default interface IStorefrontProfileForm {
    name: string;
    description: string;
    primary_color?: string;
    secondary_color?: string;
    store_logo?: string|URL;
}

export const storefrontProfileFormSchema = z.object({
    name: ruleset.required,
    description: ruleset.required,
    primary_color: ruleset.hexColour,
    secondary_color: ruleset.hexColour,
    store_logo: transformEmptyStringToNull(ruleset.blobUrl.nullable().optional())
});