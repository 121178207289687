import dayjs from "dayjs";
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Services and interfaces
import baseApi from "@/repositories/base_api";
import { selectCoachId } from "@/store/coach";
import { useLazyNotificationsQuery } from "@/repositories/notifications";
import { IEchoNotification, INotification } from "@/interfaces/notifications/notification";


export const useNotifications = ()  => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const coachId = useSelector(selectCoachId);
    const channelName = `App.Models.Coach.${coachId}`;

    const [getNotifs] = useLazyNotificationsQuery()
    const echoInstance = useRef<Echo|null>(null);
    const [notifications, setNotifs] = useState<INotification[]>([])

    const handleReceivedNotification = (payload: IEchoNotification) => {
        switch (payload.kind) {
            case 'team.invitation.sent':
                dispatch(baseApi.util.invalidateTags(['Team']));
                break;
            case 'team.invitation.accepted':
                dispatch(baseApi.util.invalidateTags(['Team']));
                break;
            case 'team.invitation.declined':
                dispatch(baseApi.util.invalidateTags(['Team']));
                break;
            case 'team.member.removed':
                dispatch(baseApi.util.invalidateTags(['Team']));
                break;
            case 'team.member.updated.role':
                dispatch(baseApi.util.invalidateTags(['Team']));
                break;
            case 'team.template.shared': {
                const cacheKey = payload.model?.split(/(?=[A-Z])/)[0].toLowerCase();
                dispatch(baseApi.util.invalidateTags([`Templates.${cacheKey}.list`]));
                break;
            }
            case 'team.client.transferred': 
                dispatch(baseApi.util.invalidateTags(['Clients.list']));
                break;
            case 'team.client.transferred_many': 
                dispatch(baseApi.util.invalidateTags(['Clients.list']));
                break;
            case 'team.prospect.transferred':
                dispatch(baseApi.util.invalidateTags(['Prospects.list']));
                break;
            case 'team.prospect.transferred_many':
                dispatch(baseApi.util.invalidateTags(['Prospects.list']));
                break;
            case 'client.checkout.completed':
                dispatch(baseApi.util.invalidateTags(['Transactions', 'Client.list', { type: 'Client', id: Number(payload.model_id)}]));
                break;
            case 'client.payment.failed':
                dispatch(baseApi.util.invalidateTags(['Transactions', 'Client.list', { type: 'Client', id: Number(payload.model_id)}]));
                break;
            case 'client.subscription.ended':
                dispatch(baseApi.util.invalidateTags(['Client.list', { type: 'Client', id: Number(payload.model_id)}]));
                break;
            case 'client.checkin_submission.submitted':
                dispatch(baseApi.util.invalidateTags(['Checkin.submission.client.list', 'Checkin.submission.list']));
                break;
            case 'client.checkin_submission.updated':
                dispatch(baseApi.util.invalidateTags(['Checkin.submission.client.list']));
                break;
            case 'prospect.lead_form.submitted':
                dispatch(baseApi.util.invalidateTags(['Prospects.list', 'LeadForms.list']));
                break;
            case 'subscription.payment.failed':
                dispatch(baseApi.util.invalidateTags(['PaymentMethod.list']));
                break;
            case 'referral.status.pending':
            case 'referral.status.paid':
            case 'referral.status.paid_silver':
            case 'referral.status.cancelled':
                dispatch(baseApi.util.invalidateTags([{ type: 'Referral', id: 'ALL' }]));
                dispatch(baseApi.util.invalidateTags([{ type: 'Referral', id: 'STATS' }]));
                break;
        }
    }

    const handleClickNotification = (notification: INotification) => {
        notifications.splice(notifications.findIndex(n => n.id === notification.id), 1)
        switch (notification.data.kind) {
            case 'team.invitation.sent':
                navigate('/settings/teams');
                break;
            case 'team.invitation.accepted':
                navigate('/settings/teams');
                break;
            case 'team.invitation.declined':
                navigate('/settings/teams');
                break;
            case 'team.template.shared': {
                const model = notification.data.model?.split(/(?=[A-Z])/)[0].toLowerCase() ?? '';
                const routeKey = ['checkin', 'programme', 'supplement'].includes(model) ? `${model}s` : model;
                navigate(`/templates/${routeKey}/team`);
                break;
            }
            case 'client.checkout.completed':
            case 'client.payment.failed':
            case 'client.subscription.ended':
                navigate(`/clients/${notification.data.model_id}`);
                break;
            case 'client.checkin_submission.submitted':
                navigate(`/clients/${notification.data.model_id}/checkins`);
                break;
            case 'client.checkin_submission.updated':
                navigate(`/clients/${notification.data.model_id}/checkins`);
                break;
            case 'prospect.lead_form.submitted':
                navigate(`/prospects/list?prospect=${notification.data.model_id}`);
                break;
            case 'subscription.payment.failed':
                navigate('/settings/billing');
                break;
            case 'referral.status.pending':
            case 'referral.status.paid':
            case 'referral.status.paid_silver':
            case 'referral.status.cancelled':
                navigate('/settings/referrals');
                break;
        }
    }  

    useEffect(() => {
        // Ensure only one Echo instance is created
        if (!echoInstance.current) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            // window.Pusher = Pusher; // Needed for the Echo configuration to work

            echoInstance.current = new Echo({
                Pusher,
                broadcaster: 'pusher',
                key: import.meta.env.VITE_PUSHER_APP_KEY,
                cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
                forceTLS: true,
                // authEndpoint: import.meta.env.VITE_BASE_URL + "broadcasting/auth",
                // authorizer: authorizer
                // auth: {
                //     headers: {
                //         'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') ?? "cant have shit",
                //     },
                //     withCredentials: true,
                // }
                // broadcaster: 'reverb',
                // key: import.meta.env.VITE_REVERB_APP_KEY,
                // wsHost: import.meta.env.VITE_REVERB_HOST,
                // wsPort: import.meta.env.VITE_REVERB_PORT,
                // forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
                // enabledTransports: ['ws', 'wss'],
                // authEndpoint: import.meta.env.VITE_AUTH_ENDPOINT,
                // auth: {
                //     headers: {
                //         'Authorization': 'Bearer ' + user?.access_token
                //     }
                // }
            });

            // Listen for successful connection
            echoInstance.current.connector.pusher.connection.bind('connected', () => {
                console.log('Successfully connected to Echo server.');
                getNotifs().unwrap().then((data) => {
                    setNotifs(s => data.concat(s))
                })
            });

            // Listen for connection errors
            echoInstance.current.connector.pusher.connection.bind('error', (error: any) => {
                console.error('Error connecting to Echo server:', error);
            });
        }

        echoInstance.current.private(channelName)
            .notification((payload: IEchoNotification) => {
                // If the notification is a service.updated, remove any existing service.updated notifications
                const isServiceUpdated = payload.kind === 'service.updated';
                setNotifs(s => [({
                    id: payload.id,
                    data: {
                        ...payload
                    },
                    created_at: dayjs().toISOString(),
                }) as INotification].concat(isServiceUpdated ? s.filter(n => n.data.kind !== 'service.updated') : s));
                handleReceivedNotification(payload);
            })

        // Cleanup function to remove listeners and Echo instance on unmount or dependency change
        return () => {
            if (echoInstance.current) {
                echoInstance.current.leaveAllChannels();
                echoInstance.current.disconnect();
                echoInstance.current = null;
            }
        };
    }, [coachId]); 

    return { notifications: notifications, handleClickNotification };
};