import { BaseSyntheticEvent, FC } from "react";

// Components
import { 
  FormControl, 
  FormControlLabel, 
  FormControlOwnProps, 
  Radio, 
  RadioGroup as MuiRadioGroup, 
  Box, 
  Skeleton 
} from "@mui/material";


export interface IRadioItem {
  label: string;
  value: string;
}

interface _RadioGroupProps {
  // name: string;
  // control: Control<any>;
  size?: 'small' | 'medium';
  row?: boolean;
  label?: string;
  items: IRadioItem[];
  defaultValue?: string;
  helperText?: string;
  tabIndex?: number;
  gutter?: string;
  isLoading?: boolean;
  hideErrorMessage?: boolean;
  disabled?: boolean;
  onChange?: (value: string) => void;
  sx?: FormControlOwnProps['sx'];
}

const RadioGroup: FC<_RadioGroupProps>  = ({
  // name,
  // control,
  size = 'small',
  row = false,
  label,
  items,
  defaultValue,
  // helperText,
  // tabIndex = -1,
  gutter = '12px',
  isLoading = false,
  // disabled = false,
  onChange,
  // sx
}) => {

  const handleChange = (event: BaseSyntheticEvent, value: string) => {
    onChange && onChange(value);
  };

  return (
    <FormControl sx={{width: '100%', marginBottom: gutter}}>
      <span className="label-text-02" style={{marginBottom: '0.5em', color: 'var(--text-secondary)'}}>{label}</span>
      {isLoading ?
        <Box display="flex" flexDirection={row ? 'row' : 'column'} width="100%" maxWidth="240px">
          {items.map((_, index) => (
            <Skeleton key={index} variant="rectangular" height="30px" width="100%" sx={{my: row ? 'unset' : '4px', mx: row ? '4px' : 'unset'}} />
          ))}
        </Box>
        : <MuiRadioGroup
            row={row}
            defaultValue={defaultValue}
            onChange={handleChange}
            >
            <Box display="flex" flexDirection={row ? 'row' : 'column'}>
              {items.map((item, index) => (
                <FormControlLabel key={index} value={item.value} control={<Radio size={size} />} label={item.label} sx={{maxHeight: '32px'}} />
              ))}
            </Box>
        </MuiRadioGroup>}
  </FormControl>
  );
};

export default RadioGroup;