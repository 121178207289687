import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";

// Helpers
import { getCurrencySymbol } from "@/_helpers/currency_functions";

// Constants
import { 
    productTypeOptions, 
    productSubtypeOptions, 
    productIntervalOptions, 
    currencyOptions, 
    productEndBehaviourOptions 
} from "@/_constants/product";

// Interfaces
import { IProductForm } from "@/interfaces/product/product_form";
import { IProductUpdateForm } from "@/interfaces/product/product_update_form";

// Styles
import { Add, TrashCan } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import Button from "@/components/button";
import TextInput from "@/components/text_input";
import IconButton from "@/components/icon_button";
import SelectInput from "@/components/select_input";
import NumberInput from "@/components/number_input";
import { InlineNotification } from "@/components/notification/notification";


interface _ProductFormProps {
    // Defines whether the coach can edit any value for the product
    canEdit: boolean;
    // Defines whether any field should be readonly once the product has been created
    readonly: boolean;
}

const ProductForm: FC<_ProductFormProps> = ({
    canEdit,
    readonly
}) => {

    const { t } = useTranslation();
    const { control, watch } = useFormContext<IProductForm|IProductUpdateForm>();
    const { fields, append, remove } = useFieldArray({ control, name: 'schedule.phases' });

    const type = watch('type');
    const subtype = watch('subtype');
    const currency = watch('currency');
    const price = watch('price');
    const schedule = useWatch({control, name: 'schedule'})

    const showStandardRecurringNotification: boolean = !!price.unit_amount && !!price.interval_count && !!price.interval;
    const showStageNotification = (index: number): boolean => {
        const phase = schedule?.phases[index];
        return phase != null && !!phase.unit_amount && !!phase.interval_count && !!phase.interval && !!phase.iterations;
    }

    const handleAddStage = () => {
        append({ unit_amount: '', interval_count: 1, interval: 'month', iterations: 1 });
    }

    return (
        <Box display="flex" flexDirection="column" rowGap="12px">

            {/* Name and description */}

                <TextInput
                    name="name"
                    control={control}
                    label={t('inputs.titles.productName')}
                    maxLength={150}
                    disabled={!canEdit}
                    />

                <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap="16px" rowGap="16px">

                    <SelectInput
                        name="type"
                        control={control}
                        label={t('inputs.titles.productType')}
                        items={productTypeOptions}
                        disabled
                        />
                    <SelectInput
                        name="subtype"
                        control={control}
                        label={t('inputs.titles.paymentType')}
                        items={type == 'service' ? productSubtypeOptions.filter(option => option.value !== 'one_time') : productSubtypeOptions}
                        disabled={readonly}
                        />
                    
                    <SelectInput
                        name="currency"
                        control={control}
                        label={t('inputs.titles.currency')}
                        items={currencyOptions}
                        disabled={readonly}
                        />
                    {subtype == "staged" && <SelectInput
                        name="schedule.end_behaviour"
                        control={control}
                        label={t('inputs.titles.endBehaviour')}
                        items={productEndBehaviourOptions}
                        disabled={readonly}
                        />}

                </Box>

                <TextInput
                    name="description"
                    control={control}
                    size="textarea"
                    label={t('inputs.titles.description')}
                    minRows={4}
                    maxLength={1000}
                    disabled={!canEdit}
                    />

                {subtype !== 'staged' && <Box display="flex" flexDirection="column">
                
                    <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" columnGap="16px" alignItems="top">

                        <NumberInput
                            name="price.unit_amount"
                            control={control}
                            label={t('inputs.titles.price')}
                            disabled={readonly}
                            />

                        <NumberInput
                            name="price.interval_count"
                            control={control}
                            label={t('inputs.titles.billingCycle')}
                            disabled={readonly}
                            />

                        <Box marginTop="22px">
                            <SelectInput
                                name="price.interval"
                                control={control}
                                label=""
                                items={productIntervalOptions}
                                disabled={readonly}
                                />
                        </Box>

                    </Box>

                    {showStandardRecurringNotification && <InlineNotification
                        type="info"
                        title=""
                        message={<Trans
                            i18nKey="inlineNotifications.product.standardRecurring.message"
                            components={{ strong: <strong /> }}
                            values={{
                                amount: `${price.unit_amount}${currency.toUpperCase()}`, 
                                interval: price.interval_count && price.interval_count > 1 ? t(`timeDate.${price.interval}`, {count: price.interval_count}) : price.interval,
                            }}/>}
                        />}
                </Box>}

                {subtype == "staged" && schedule &&
                        <span className="headong-05" style={{marginBottom: '12px'}}>{t('rightPanel.product.productStages')}</span>}

                {subtype == "staged" && schedule && fields.map((phase, index) => (
                    <Box display="flex" flexDirection="column" key={index}>

                        {/* Stage Number */}
                        <Box display="flex" justifyContent="space-between" rowGap="8px">
                            <span className="body-02-compact">{t('rightPanel.product.stage', {number: index+1})}</span>
                            <IconButton
                                size="small"
                                kind="danger-ghost"
                                icon={<TrashCan />}
                                disabled={fields.length == 1 || readonly}
                                onClick={() => remove(index)}
                                />
                        </Box>

                        {/* Stage Config */}
                        <Box 
                            key={index} 
                            display="flex" 
                            flexDirection="column" 
                            padding="16px"
                            bgcolor="var(--layer-01)" 
                            borderRadius="6px" 
                            border="solid 1px var(--border-subtle-01)"
                            >
                            <Box key={index} display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr" columnGap="16px" alignItems="flex-start">

                                <NumberInput
                                    name={`schedule.phases[${index}].unit_amount`}
                                    control={control}
                                    label={t('inputs.titles.price')}
                                    disabled={readonly}
                                    />

                                <NumberInput
                                    name={`schedule.phases[${index}].interval_count`}
                                    control={control}
                                    label={t('inputs.titles.billingCycle')}
                                    disabled={readonly}
                                    />

                                <Box marginTop="22px">
                                    <SelectInput
                                        name={`schedule.phases[${index}].interval`}
                                        control={control}
                                        label=""
                                        items={productIntervalOptions}
                                        disabled={readonly}
                                        />
                                </Box>

                                <NumberInput
                                    name={`schedule.phases[${index}].iterations`}
                                    control={control}
                                    label={t('inputs.titles.numberOfPayments')}
                                    disabled={readonly}
                                    sx={{minWidth: '120px'}}
                                    />

                            </Box>

                            {showStageNotification(index) && <InlineNotification
                                type="info"
                                title=""
                                message={<Trans
                                    i18nKey="inlineNotifications.product.staged.message"
                                    components={{ strong: <strong /> }}
                                    values={{
                                        count: schedule.phases[index].iterations, 
                                        amount: `${getCurrencySymbol(currency)}${schedule.phases[index].unit_amount}`, 
                                        interval: schedule.phases[index].interval_count > 1 ?
                                            t(`timeDate.${schedule.phases[index].interval}`, {count: schedule.phases[index].interval_count}) :
                                            phase.interval,
                                        duration: t(`timeDate.${schedule.phases[index].interval}`, {count: schedule.phases[index].interval_count * schedule.phases[index].iterations})
                                    }}/>}
                                />}

                            {schedule.phases.length-1 == index && <Box marginTop="12px">
                                <InlineNotification
                                    type="info"
                                    title=""
                                    message={<Trans
                                        i18nKey={`inlineNotifications.product.finalStage.message_${schedule.end_behaviour}`}
                                        components={{ strong: <strong /> }}
                                    />}
                                    />
                                </Box>}
                        </Box>
                    </Box>
                ))}

                {subtype == "staged" && <Button
                    label={t('components.buttons.addStage')}
                    kind="tertiary"
                    size="small"
                    endIcon={<Add />}
                    onClick={handleAddStage}
                    sx={{maxWidth: 'fit-content'}}
                    />}



        </Box>
    )
}

export default ProductForm;