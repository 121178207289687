import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AddressMode } from "@stripe/stripe-js";
import { AddressElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { useProcessCheckoutMutation } from "@/repositories/checkout";
import ICheckoutProcessed from "@/interfaces/checkout/checkout_processed";

// Styles
import { ShoppingCartPlus } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material"
import Button from '@/components/button';
import FullScreenLoader from "@/components/overlays/fullscreen_loader";


interface _CheckoutFormProps {
    onPaymentComplete: (response: ICheckoutProcessed) => void;
}

const CheckoutForm: FC<_CheckoutFormProps> = ({
    onPaymentComplete
}) => {

    const { id } = useParams();
    const { t } = useTranslation();
    const elements = useElements();
    const stripe = useStripe();

    const [loading, setLoading] = useState(false);
    const [processCheckout, { isLoading: isProcessing }] = useProcessCheckoutMutation();

    const [addressComplete, setAddressComplete] = useState(false);
    const [paymentComplete, setPaymentComplete] = useState(false);

    const addressOptions = {
        mode: 'billing' as AddressMode
    }

    const submit = async () => {
        setLoading(true);
        if (!elements || !stripe) return;

        const address = elements.getElement(AddressElement);
        const payment = elements.getElement(PaymentElement);

        if (!address || !payment) return;
        
        await elements.submit();

        const { paymentMethod, error } = await stripe.createPaymentMethod({elements: elements});
        if (error) {
            setLoading(false);
            return showToast({
                type: 'error', 
                title: t('notifications.billing.paymentFailed'), 
                message: error.message
            });
        }

        const payload = {
            session_id: id!,
            payment_method_id: paymentMethod.id
        }
        
        await processCheckout(payload).unwrap().then((response) => {
            setLoading(false);
            onPaymentComplete(response);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
            setLoading(false);
        })
    }

    return (
        <Box>

        {loading && <FullScreenLoader show={loading} loadingText={t('billing.processingPayment')} />}

            <Box 
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="100%"
                padding="24px"
                boxSizing="border-box"
                >

                {/* Payment */}
                <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%" maxWidth="540px">

                    <span className="heading-06" style={{color: 'var(--text-secondary)'}}>{t('pages.setup.subscription.form.billingAddress')}</span>
                    <Box height="24px" />
                    <AddressElement options={addressOptions} onChange={(e) => setAddressComplete(e.complete)} />

                    <Box height="30px" />

                    <span className="heading-06" style={{color: 'var(--text-secondary)'}}>{t('pages.setup.subscription.form.paymentDetails')}</span>
                    <Box height="24px" />
                    <PaymentElement
                        onChange={(e) => setPaymentComplete(e.complete)} 
                        />
                    <Box height="30px" />

                    <Box display="flex" width="100%" justifyContent="end" marginBottom="24px">
                        <Button
                            disabled={!addressComplete || !paymentComplete || loading || isProcessing}
                            loading={null}
                            endIcon={<ShoppingCartPlus />}
                            onClick={submit}
                            sx={{'@media (max-width: 768px)': {width: '100%'}}}
                            >
                            {t('components.buttons.payNow')}
                        </Button>
                    </Box>

                </Box>
                    
            </Box>

        </Box>
    )
}

export default CheckoutForm;