import baseApi from '@/repositories/base_api';
import { IAPIResponse } from '@/interfaces/api/response';
import { buildQueryString } from '@/_helpers/url_builder';
import { IRefundTransactionForm } from '@/interfaces/transaction/refund_transaction_form';
import { ITransaction, ITransactionQueryParams } from '@/interfaces/transaction/transaction';


export const transactionApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getTransactions: build.query<ITransaction[], ITransactionQueryParams|undefined>({
            query: (params) => ({ url: buildQueryString('/transactions', params), method: 'get'}),
            transformResponse: (response: IAPIResponse<ITransaction[]>) => response.data,
            providesTags: ['Transactions']
        }),
        refundTransaction: build.mutation<ITransaction, {id: number, data: IRefundTransactionForm, params?: ITransactionQueryParams}>({
            query: ({id, data}) => ({ url: `/transactions/${id}/refund`, method: 'post', data}),
            transformResponse: (response: IAPIResponse<ITransaction>) => response.data,
                async onQueryStarted({ params }, { dispatch, queryFulfilled }) {
                    queryFulfilled.then((response) => {
                    dispatch(transactionApi.util.updateQueryData('getTransactions', undefined as never, transactions => {
                        const index = transactions.findIndex(transaction => transaction.id === response.data.id);
                        transactions[index] = response.data;
                    }));
                    dispatch(transactionApi.util.updateQueryData('getTransactions', params, transactions => {
                        const index = transactions.findIndex(transaction => transaction.id === response.data.id);
                        transactions[index] = response.data;
                    }));
                }).catch(() => {});
            },
            invalidatesTags: []
        }),
    }),
})

export const { 
    useGetTransactionsQuery,
    useRefundTransactionMutation
} = transactionApi;

