import SettingsLayout from "@/layouts/settings";

// Pages
import ErrorPage from "@/pages/error";
import PayPage from "@/pages/settings/storefront";
import TeamPage from "@/pages/settings/team";
import ProfilePage from "@/pages/settings/profile";
import MetricsPage from "@/pages/settings/metrics";
import PrivacyPage from "@/pages/settings/privacy";
import BillingPage from "@/pages/settings/billing";
import ReferralPage from "@/pages/settings/referral";


export const settingsRoutes = {
  path: "settings",
  element: <SettingsLayout />,
  errorElement: <ErrorPage />,
  children: [
    {
      path: "profile",
      element: <ProfilePage />
    },
    {
      path: "privacy",
      element: <PrivacyPage />
    },
    {
      path: "billing",
      element: <BillingPage />
    },
    {
      path: "metrics",
      element: <MetricsPage />,
    },
    {
      path: "teams",
      element: <TeamPage />,
    },
    // {
    //   path: "notifications",
    //   element: <p>notifications</p>,
    // },
    {
      path: "referrals",
      element: <ReferralPage />
    },
    {
      path: "1fitpay",
      element: <PayPage />,
    },
  ],
}
