import baseApi from '@/repositories/base_api';
import { IProduct } from '@/interfaces/product/product';
import { IAPIResponse } from '@/interfaces/api/response';
import { IProductForm } from '@/interfaces/product/product_form';
import { IProductUpdateForm } from '@/interfaces/product/product_update_form';


export const productApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getProducts: build.query<IProduct[], void>({
            query: () => ({ url: `/products`, method: 'get'}),
            transformResponse: (response: IAPIResponse<IProduct[]>) => response.data,
        }),
        addProduct: build.mutation<IProduct, IProductForm>({
            query: (data) => ({ url: `/products`, method: 'post', data}),
            transformResponse: (response: IAPIResponse<IProduct>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(productApi.util.updateQueryData('getProducts', undefined as never, products => {
                    products.push(response.data);
                    products.sort((a, b) => a.name.localeCompare(b.name));
                }))).catch(() => {});
            },
        }),
        updateProduct: build.mutation<IProduct, {id: number, data: IProductUpdateForm}>({
            query: ({id, data}) => ({ url: `/products/${id}`, method: 'patch', data}),
            transformResponse: (response: IAPIResponse<IProduct>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(productApi.util.updateQueryData('getProducts', undefined as never, products => {
                    const index = products.findIndex(product => product.id === response.data.id);
                    products[index] = response.data;
                    if (response.data.default) {
                        products.map((p) => {
                            if (p.id !== response.data.id) return p.default = false;
                            return p;
                        });
                    }
                    products.sort((a, b) => a.name.localeCompare(b.name));
                }))).catch(() => {});
            },
        }),
        archiveProduct: build.mutation<IProduct, {id: number, active: boolean}>({
            query: ({id, active}) => ({ url: `/products/${id}?active=${active}`, method: 'delete'}),
            transformResponse: (response: IAPIResponse<IProduct>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(productApi.util.updateQueryData('getProducts', undefined as never, products => {
                    const index = products.findIndex(product => product.id === response.data.id);
                    products[index] = response.data;
                    products.sort((a, b) => a.name.localeCompare(b.name));
                }))).catch(() => {});
            },
        }),
    }),
})

export const { 
    useGetProductsQuery,
    useAddProductMutation,
    useUpdateProductMutation,
    useArchiveProductMutation
} = productApi;

