import dayjs from 'dayjs';
import { FC, useState } from "react";
import { useForm } from 'react-hook-form';
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";
import { usePhaseContext } from '@/contexts/phase_context';

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { IPhaseMilestone } from "@/interfaces/phase_milestone/phase_milestone";
import { useUpdatePhaseMilestoneMutation } from "@/repositories/phase_milestone";

// Components
import Box from "@mui/material/Box";
import Checkbox from '@/components/checkbox';
import Typography from "@mui/material/Typography";
import DeleteMilestoneModal from './delete_modal';
import OverflowMenu from "@/components/overflow_menu";
import MilestoneModal from "@/components/phases/milestones/milestone_modal";

interface MilestoneProps {
    milestone: IPhaseMilestone;
    phase_id: string;
    showMenu?: boolean;
}

const PhaseMilestone: FC<MilestoneProps> = ({
    milestone,
    phase_id,
    showMenu = false,
}) => {

    const { id } = useParams()
    const { t } = useTranslation()
    const { phase } = usePhaseContext();
    const [open, setOpen] = useState<string|null>(null)
    const [updateMilestone] = useUpdatePhaseMilestoneMutation()

    const formMethods = useForm<{checked: boolean}>({
        mode: 'onBlur',
        defaultValues: {
            checked: milestone.completed_at ? true : false
        }
    });

    const handleToggleComplete = (checked: boolean) => {
        const payload = {
            client_id: Number(id),
            phase_id: phase_id,
            id: milestone.id,
            data: {
                title: milestone.title,
                date: milestone.date,
                completed_at: checked ? dayjs.utc().startOf('day').toISOString() : null
            }
        }
        updateMilestone(payload).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: checked ? t('notifications.phaseMilestone.completed.title') : t('notifications.phaseMilestone.uncompleted.title'),
                message: checked ? t('notifications.phaseMilestone.completed.message', {name: milestone.title}) : undefined
            });
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }  

    return ( 
        <Box position='relative' display='flex'>
            <Box>
                <Checkbox
                    name="checked"
                    control={formMethods.control}
                    onChange={(v) => handleToggleComplete(v)}
                    sx={{width: '30px'}}
                    />
            </Box>
            <Box>
                <Typography mb={0.5}>
                    <span className='label-text-02 text-secondary'>
                        {dayjs.utc(milestone.date).format('DD/MM/YYYY')}
                    </span>
                </Typography>
                <Typography mb={0.5}>
                    <span className='heading-07-compact max-lines-3'>{milestone.title}</span>
                </Typography>
            </Box>
            {showMenu && (
                <>
                    <Box position='absolute' top={0} right={0}>
                        <OverflowMenu
                            options={[
                                { name: t('components.menuItems.edit'), action: () => setOpen('edit') }
                            ]}
                            deleteLabel={t('components.menuItems.delete')}
                            onDelete={() => setOpen('delete')}
                            disabled={phase?.readonly}
                        />
                    </Box>
                    {open === 'edit' && <MilestoneModal 
                        open={open === 'edit'} 
                        onClose={() => setOpen(null)} 
                        phase_id={phase_id} 
                        milestone={milestone} 
                        />}
                    {open === 'delete' && <DeleteMilestoneModal 
                        milestone={milestone} 
                        phase_id={phase_id} 
                        open={open === 'delete'} 
                        onClose={() => setOpen(null)} />}
                </>
            )}
        </Box>
    );
}

export default PhaseMilestone;