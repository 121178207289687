export const ApiErrorEnum = {

    // Auth errors
    auth_invalid_credentials: 'auth_invalid_credentials',
    auth_missing_email: 'auth_missing_email',
    auth_missing_password: 'auth_missing_password',
    auth_password_reset_failed: 'auth_password_reset_failed',

    // Verification errors
    verification_not_verified: 'verification_not_verified',
    verification_already_verified: 'verification_already_verified',
    verification_url_expired: 'verification_url_expired',
    verification_invalid_signature: 'verification_invalid_signature',

    // User errors
    user_not_found: 'user_not_found',
    user_create_failed: 'user_create_failed',
    user_update_failed: 'user_update_failed',
    user_suspended: 'user_suspended',
    user_not_active: 'user_not_active',
    user_not_coach: 'user_not_coach',

    // Email errors
    email_already_registered: 'email_already_registered',

    // Coach errors
    coach_not_found: 'coach_not_found',
    coach_create_failed: 'coach_create_failed',
    coach_update_failed: 'coach_update_failed',
    coach_settings_update_failed: 'coach_settings_update_failed',

    // Setup errors
    setup_already_completed: 'setup_already_completed',

    // Team errors
    team_not_authorised: 'team_not_authorised',
    team_role_not_found: 'team_role_not_found',
    team_not_found: 'team_not_found',
    team_already_exists: 'team_already_exists',
    team_create_failed: 'team_create_failed',
    team_update_failed: 'team_update_failed',
    team_delete_failed: 'team_delete_failed',
    team_owner_not_found: 'team_owner_not_found',
    team_invalid_role: 'team_invalid_role',
    team_invalid_permissions: 'team_invalid_permissions',
    team_owner_exists: 'team_owner_exists',
    team_coach_exists: 'team_coach_exists',
    team_share_resources_failed: 'team_share_resources_failed',
    team_unshare_templates_failed: 'team_unshare_templates_failed',

    // Team invitation errors
    team_invitation_not_authorised: 'team_invitation_not_authorised',
    team_invitation_not_valid: 'team_invitation_not_valid',
    team_invitation_not_found: 'team_invitation_not_found',
    team_invitation_create_failed: 'team_invitation_create_failed',
    team_invitation_update_failed: 'team_invitation_update_failed',
    team_invitation_delete_failed: 'team_invitation_delete_failed',
    team_invitation_decline_failed: 'team_invitation_decline_failed',
    team_invitation_accept_failed: 'team_invitation_accept_failed',
    team_invitation_exists: 'team_invitation_exists',

    // Team member errors
    team_member_not_authorised: 'team_member_not_authorised',
    team_member_not_found: 'team_member_not_found',
    team_member_not_in_team: 'team_member_not_in_team',
    team_member_create_failed: 'team_member_create_failed',
    team_member_update_failed: 'team_member_update_failed',
    team_member_delete_failed: 'team_member_delete_failed',

    // Subscription errors
    subscription_not_found: 'subscription_not_found',
    subscription_not_authorised: 'subscription_not_authorised',
    subscription_not_active: 'subscription_not_active',
    subscription_not_cancelled: 'subscription_not_cancelled',
    subscription_not_ended: 'subscription_not_ended',
    subscription_not_renewed: 'subscription_not_renewed',
    subscription_not_resumed: 'subscription_not_resumed',
    subscription_not_updated: 'subscription_not_updated',

    // Subscription Module errors
    subscription_module_not_found: 'subscription_module_not_found',
    subscription_module_not_active: 'subscription_module_not_active',
    subscription_module_not_cancelled: 'subscription_module_not_cancelled',
    subscription_module_not_resumed: 'subscription_module_not_resumed',

    // Payment errors
    payment_method_not_created: 'payment_method_not_created',
    payment_method_not_found: 'payment_method_not_found',
    payment_method_not_authorised: 'payment_method_not_authorised',
    payment_method_update_failed: 'payment_method_update_failed',
    payment_method_delete_failed: 'payment_method_delete_failed',
    payment_method_delete_all_failed: 'payment_method_delete_all_failed',
    payment_failure_not_found: 'payment_failure_not_found',
    payment_failure_clear_failed: 'payment_failure_clear_failed',
    payment_failure_clear_all_failed: 'payment_failure_clear_all_failed',

    // Invoice errors
    invoice_not_found: 'invoice_not_found',

    // Code errors
    code_not_found: 'code_not_found',
    code_invalid: 'code_invalid',
    code_create_failed: 'code_create_failed',
    code_use_failed: 'code_use_failed',

    // Client errors
    client_not_found: 'client_not_found',
    client_not_authorised: 'client_not_authorised',
    client_already_setup: 'client_already_setup',
    client_already_exists: 'client_already_exists',
    client_create_failed: 'client_create_failed',
    client_update_failed: 'client_update_failed',
    client_delete_failed: 'client_delete_failed',
    client_delete_many_failed: 'client_delete_many_failed',
    client_onboard_failed: 'client_onboard_failed',
    client_transfer_failed: 'client_transfer_failed',
    client_transfer_many_failed: 'client_transfer_many_failed',
    client_not_inactive: 'client_not_inactive',

    // Phase errors
    phase_not_found: 'phase_not_found',
    phase_not_authorised: 'phase_not_authorised',
    phase_create_failed: 'phase_create_failed',
    phase_update_failed: 'phase_update_failed',
    phase_delete_failed: 'phase_delete_failed',
    phase_start_invalid: 'phase_start_invalid',

    // Phase Milestone errors
    phase_milestone_not_found: 'phase_milestone_not_found',
    phase_milestone_not_authorised: 'phase_milestone_not_authorised',
    phase_milestone_create_failed: 'phase_milestone_create_failed',
    phase_milestone_update_failed: 'phase_milestone_update_failed',
    phase_milestone_delete_failed: 'phase_milestone_delete_failed',

    // Phase Note errors
    phase_note_not_found: 'phase_note_not_found',
    phase_note_not_authorised: 'phase_note_not_authorised',
    phase_note_create_failed: 'phase_note_create_failed',
    phase_note_update_failed: 'phase_note_update_failed',
    phase_note_delete_failed: 'phase_note_delete_failed',

    // Workout errors
    workout_not_found: 'workout_not_found',
    workout_not_authorised: 'workout_not_authorised',

    // Plan errors
    plan_not_found: 'plan_not_found',
    plan_not_authorised: 'plan_not_authorised',
    plan_create_failed: 'plan_create_failed',
    plan_update_failed: 'plan_update_failed',
    plan_delete_failed: 'plan_delete_failed',
    plan_delete_many_failed: 'plan_delete_many_failed',

    // Checkin Submission errors
    checkin_submission_not_found: 'checkin_submission_not_found',
    checkin_submission_not_authorised: 'checkin_submission_not_authorised',
    checkin_submission_update_failed: 'checkin_submission_update_failed',
    checkin_submission_delete_failed: 'checkin_submission_delete_failed',

    // Prospect errors
    prospect_not_found: 'prospect_not_found',
    prospect_not_authorised: 'prospect_not_authorised',
    prospect_create_failed: 'prospect_create_failed',
    prospect_update_failed: 'prospect_update_failed',
    prospect_delete_failed: 'prospect_delete_failed',
    prospect_delete_many_failed: 'prospect_delete_many_failed',
    prospect_transfer_failed: 'prospect_transfer_failed',
    prospect_transfer_many_failed: 'prospect_transfer_many_failed',

    // Form errors
    form_not_found: 'form_not_found',
    form_not_authorised: 'form_not_authorised',
    form_create_failed: 'form_create_failed',
    form_update_failed: 'form_update_failed',
    form_share_failed: 'form_share_failed',
    form_delete_failed: 'form_delete_failed',
    form_delete_many_failed: 'form_delete_many_failed',

    // Response errors
    response_not_found: 'response_not_found',
    response_not_authorised: 'response_not_authorised',
    response_create_failed: 'response_create_failed',
    response_delete_failed: 'response_delete_failed',

    // Template errors
    template_not_found: 'template_not_found',
    template_not_authorised: 'template_not_authorised',
    template_create_failed: 'template_create_failed',
    template_update_failed: 'template_update_failed',
    template_assign_failed: 'template_assign_failed',
    template_assign_many_failed: 'template_assign_many_failed',
    template_share_failed: 'template_share_failed',
    template_delete_failed: 'template_delete_failed',
    template_delete_many_failed: 'template_delete_many_failed',

    // Catalogue errors
    catalogue_not_found: 'catalogue_not_found',
    catalogue_not_authorised: 'catalogue_not_authorised',
    catalogue_create_failed: 'catalogue_create_failed',
    catalogue_update_failed: 'catalogue_update_failed',
    catalogue_delete_failed: 'catalogue_delete_failed',
    catalogue_delete_many_failed: 'catalogue_delete_many_failed',

    // Exercise errors
    exercise_not_found: 'exercise_not_found',
    exercise_not_authorised: 'exercise_not_authorised',
    exercise_create_failed: 'exercise_create_failed',
    exercise_update_failed: 'exercise_update_failed',
    exercise_delete_failed: 'exercise_delete_failed',

    // Supplement errors
    supplement_not_found: 'supplement_not_found',
    supplement_not_authorised: 'supplement_not_authorised',
    supplement_create_failed: 'supplement_create_failed',
    supplement_update_failed: 'supplement_update_failed',
    supplement_delete_failed: 'supplement_delete_failed',

    // Food errors
    food_not_found: 'food_not_found',
    food_not_authorised: 'food_not_authorised',
    food_create_failed: 'food_create_failed',
    food_update_failed: 'food_update_failed',
    food_delete_failed: 'food_delete_failed',

    // Vault errors
    vault_not_found: 'vault_not_found',
    vault_not_authorised: 'vault_not_authorised',

    // Folder errors
    folder_not_found: 'folder_not_found',
    folder_not_authorised: 'folder_not_authorised',
    folder_create_failed: 'folder_create_failed',
    folder_update_failed: 'folder_update_failed',
    folder_delete_failed: 'folder_delete_failed',
    folder_delete_many_failed: 'folder_delete_many_failed',

    // File errors
    file_not_found: 'file_not_found',
    file_not_authorised: 'file_not_authorised',
    file_create_failed: 'file_create_failed',
    file_update_failed: 'file_update_failed',
    file_delete_failed: 'file_delete_failed',
    file_delete_many_failed: 'file_delete_many_failed',
    file_upload_failed: 'file_upload_failed',

    // Storefront errors
    storefront_not_found: 'storefront_not_found',
    storefront_not_authorised: 'storefront_not_authorised',
    storefront_create_failed: 'storefront_create_failed',
    storefront_update_failed: 'storefront_update_failed',
    storefront_delete_failed: 'storefront_delete_failed',

    // Payment Provider errors
    payment_provider_not_found: 'payment_provider_not_found',
    payment_provider_not_authorised: 'payment_provider_not_authorised',
    payment_provider_not_onboarded: 'payment_provider_not_onboarded',
    payment_provider_not_connected: 'payment_provider_not_connected',
    payment_provider_connect_failed: 'payment_provider_connect_failed',
    payment_provider_disconnect_failed: 'payment_provider_disconnect_failed',
    payment_provider_disconnect_not_permitted: 'payment_provider_disconnect_not_permitted',

    // Customer errors
    customer_not_found: 'customer_not_found',
    customer_not_authorised: 'customer_not_authorised',
    customer_create_failed: 'customer_create_failed',
    customer_update_failed: 'customer_update_failed',
    customer_delete_failed: 'customer_delete_failed',

    // Product errors
    product_not_found: 'product_not_found',
    product_not_authorised: 'product_not_authorised',
    product_create_failed: 'product_create_failed',
    product_update_failed: 'product_update_failed',
    product_archive_failed: 'product_archive_failed',
    product_restore_failed: 'product_restore_failed',
    product_delete_failed: 'product_delete_failed',

    // Price errors
    price_not_found: 'price_not_found',
    price_not_authorised: 'price_not_authorised',
    price_create_failed: 'price_create_failed',
    price_update_failed: 'price_update_failed',
    price_archive_failed: 'price_archive_failed',
    price_delete_failed: 'price_delete_failed',

    // Schedule errors
    schedule_not_found: 'schedule_not_found',
    schedule_not_authorised: 'schedule_not_authorised',
    schedule_create_failed: 'schedule_create_failed',
    schedule_update_failed: 'schedule_update_failed',
    schedule_delete_failed: 'schedule_delete_failed',

    // Connect Subscription errors
    connect_subscription_not_found: 'connect_subscription_not_found',
    connect_subscription_not_authorised: 'connect_subscription_not_authorised',
    connect_subscription_already_exists: 'connect_subscription_already_exists',
    connect_subscription_create_failed: 'connect_subscription_create_failed',
    connect_subscription_update_failed: 'connect_subscription_update_failed',
    connect_subscription_delete_failed: 'connect_subscription_delete_failed',
    connect_subscription_cancel_failed: 'connect_subscription_cancel_failed',
    connect_subscription_revert_failed: 'connect_subscription_revert_failed',
    
    // Connect Schedule errors
    connect_schedule_not_found: 'connect_schedule_not_found',
    connect_schedule_not_authorised: 'connect_schedule_not_authorised',
    connect_schedule_already_exists: 'connect_schedule_already_exists',
    connect_schedule_create_failed: 'connect_schedule_create_failed',
    connect_schedule_update_failed: 'connect_schedule_update_failed',
    connect_schedule_delete_failed: 'connect_schedule_delete_failed',

    // Connect Transaction errors
    connect_transaction_not_found: 'connect_transaction_not_found',
    connect_transaction_not_authorised: 'connect_transaction_not_authorised',
    connect_transaction_create_failed: 'connect_transaction_create_failed',
    connect_transaction_update_failed: 'connect_transaction_update_failed',
    connect_transaction_delete_failed: 'connect_transaction_delete_failed',
    connect_transaction_refund_failed: 'connect_transaction_refund_failed',

    // Connect Refund errors
    connect_refund_not_found: 'connect_refund_not_found',
    connect_refund_not_authorised: 'connect_refund_not_authorised',
    connect_refund_create_failed: 'connect_refund_create_failed',
    connect_refund_update_failed: 'connect_refund_update_failed',
    connect_refund_delete_failed: 'connect_refund_delete_failed',

    // Validation errors
    validation_failed: 'validation_failed',
    validation_value_missing: 'validation_value_missing',

    // Notification errors
    notification_failed: 'notification_failed',

    // Token errors
    token_not_found: 'token_not_found',
    token_not_valid: 'token_not_valid',
    token_delete_failed: 'token_delete_failed',

    // Device id errors
    device_id_not_found: 'device_id_not_found',
    device_id_delete_failed: 'device_id_delete_failed',

    // Generic errors
    maintenance: 'maintenance',
    unauthorized: 'unauthorized',
    invalid_method: 'invalid_method',
    invalid_property: 'invalid_property',
    invalid_enum: 'invalid_enum',
    invalid_link: 'invalid_link',
    timeout: 'timeout',
    unexpected_error: 'unexpected_error',
    invalid_hex_colour: 'invalid_hex_colour',

    // Realm errors
    realm_not_authorised: 'realm_not_authorised',
    realm_user_delete_failed: 'realm_user_delete_failed',

    // Invalid card errors
    invalid_card_type: 'invalid_card_type',
    invalid_characters: 'invalid_characters',
    invalid_charge_amount: 'invalid_charge_amount',
    invalid_cvc: 'invalid_cvc',
    invalid_expiry_month: 'invalid_expiry_month',
    invalid_expiry_year: 'invalid_expiry_year',
    invalid_number: 'invalid_number',
    invalid_source_usage: 'invalid_source_usage',
    invalid_tax_location: 'invalid_tax_location',

    // Card decliend errors
    card_declined: 'card_declined',
    card_declined_insufficient_funds: 'card_declined_insufficient_funds',
    card_declined_card_not_supported: 'card_declined_card_not_supported',
    card_declined_duplicate_transaction: 'card_declined_duplicate_transaction',
    card_declined_expired_card: 'card_declined_expired_card',
    card_declined_fraudulent: 'card_declined_fraudulent',
    card_declined_invalid_account: 'card_declined_invalid_account',
    card_declined_processing_error: 'card_declined_processing_error',
    card_declined_stolen_card: 'card_declined_stolen_card',
    card_declined_try_again_later: 'card_declined_try_again_later',

    // Charge errors
    charge_already_captured: 'charge_already_captured',
    charge_already_refunded: 'charge_already_refunded',
    charge_disputed: 'charge_disputed',
    charge_exceeds_source_limit: 'charge_exceeds_source_limit',
    charge_expired_for_capture: 'charge_expired_for_capture',
    charge_invalid_parameter: 'charge_invalid_parameter',
    charge_not_refundable: 'charge_not_refundable',
    
    // Invoice errors
    invoice_no_customer_line_items: 'invoice_no_customer_line_items',
    invoice_no_payment_method_types: 'invoice_no_payment_method_types',
    invoice_no_subscription_line_items: 'invoice_no_subscription_line_items',
    invoice_not_editable: 'invoice_not_editable',
    invoice_on_behalf_of_not_editable: 'invoice_on_behalf_of_not_editable',
    invoice_payment_intent_requires_action: 'invoice_payment_intent_requires_action',
    invoice_upcoming_none: 'invoice_upcoming_none',

    // Payment intent errors
    payment_intent_action_required: 'payment_intent_action_required',
    payment_intent_amount_reconfirmation_required: 'payment_intent_amount_reconfirmation_required',
    payment_intent_authentication_failure: 'payment_intent_authentication_failure',
    payment_intent_automatic_tax_incomplete: 'payment_intent_automatic_tax_incomplete',
    payment_intent_incompatible_payment_method: 'payment_intent_incompatible_payment_method',
    payment_intent_invalid_parameter: 'payment_intent_invalid_parameter',
    payment_intent_konbini_rejected_confirmation_number: 'payment_intent_konbini_rejected_confirmation_number',
    payment_intent_mandate_invalid: 'payment_intent_mandate_invalid',
    payment_intent_payment_attempt_expired: 'payment_intent_payment_attempt_expired',
    payment_intent_payment_attempt_failed: 'payment_intent_payment_attempt_failed',
    payment_intent_unexpected_state: 'payment_intent_unexpected_state',

    // Payment method errors (stripe)
    payment_method_bank_account_already_verified: 'payment_method_bank_account_already_verified',
    payment_method_bank_account_blocked: 'payment_method_bank_account_blocked',
    payment_method_billing_details_address_missing: 'payment_method_billing_details_address_missing',
    payment_method_configuration_failures: 'payment_method_configuration_failures',
    payment_method_currency_mismatch: 'payment_method_currency_mismatch',
    payment_method_customer_decline: 'payment_method_customer_decline',
    payment_method_invalid_parameter: 'payment_method_invalid_parameter',
    payment_method_microdeposit_failed: 'payment_method_microdeposit_failed',
    payment_method_microdeposit_verification_amounts_invalid: 'payment_method_microdeposit_verification_amounts_invalid',
    payment_method_microdeposit_verification_amounts_mismatch: 'payment_method_microdeposit_verification_amounts_mismatch',
    payment_method_microdeposit_verification_attempts_exceeded: 'payment_method_microdeposit_verification_attempts_exceeded',
    payment_method_microdeposit_verification_descriptor_code_mismatch: 'payment_method_microdeposit_verification_descriptor_code_mismatch',
    payment_method_microdeposit_verification_timeout: 'payment_method_microdeposit_verification_timeout',
    payment_method_not_available: 'payment_method_not_available',
    payment_method_provider_decline: 'payment_method_provider_decline',
    payment_method_provider_timeout: 'payment_method_provider_timeout',
    payment_method_unactivated: 'payment_method_unactivated',
    payment_method_unexpected_state: 'payment_method_unexpected_state',
    payment_method_unsupported_type: 'payment_method_unsupported_type',

    // Setup intent errors
    setup_attempt_failed: 'setup_attempt_failed',
    setup_intent_authentication_failure: 'setup_intent_authentication_failure',
    setup_intent_invalid_parameter: 'setup_intent_invalid_parameter',
    setup_intent_mandate_invalid: 'setup_intent_mandate_invalid',
    setup_intent_setup_attempt_expired: 'setup_intent_setup_attempt_expired',
    setup_intent_unexpected_state: 'setup_intent_unexpected_state',

} as const
  
export type ApiErrorEnum = typeof ApiErrorEnum[keyof typeof ApiErrorEnum]