import { t } from "i18next";
import { FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// Helpers
import showToast from "@/_lib/toast";
import { useFirstRender } from "@/_helpers/hooks";
import { capitalize } from "@/_helpers/text_functions";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { Provider } from "@/interfaces/storefront/storefront";
import { useConnectProviderMutation } from "@/repositories/storefront";
import FullScreenLoader from "@/components/overlays/fullscreen_loader";
import IConnectProviderForm from "@/interfaces/storefront/connect_provider_form";


interface _StripeOAuthConnectorProps {
    storefront_id: number;
}

const StripeOAuthConnector: FC<_StripeOAuthConnectorProps> = ({
    storefront_id,
}) => {

    const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const isFirstRender = useFirstRender();
    const [searchParams] = useSearchParams(); 
    const [connectProvider] = useConnectProviderMutation();

    const provider = searchParams.get('provider');
    const mode = searchParams.get('mode');
    const code = searchParams.get('code');

    useEffect(() => {
        if (!isFirstRender.current) return;
        if (!provider || !mode || (mode == 'oauth' && !code)) {
            showToast({
                type: 'error', 
                title: t('notifications.storefront.providerConnectFailed.title'),
                message: t('notifications.storefront.providerConnectFailed.message')
            });
            return setOpen(false);
        }
        
        const payload: IConnectProviderForm = {
            provider: provider as Provider,
            oauth_code: code,
        }

        connectProvider({id: storefront_id, data: payload}).unwrap().then((response) => {
            navigate('/business/payments/products', {replace: true});
            showToast({
                type: 'success', 
                title: t('notifications.storefront.providerConnected.title', {provider: capitalize(response.provider ?? '')}), 
                message: t('notifications.storefront.providerConnected.message', {provider: capitalize(response.provider ?? '')})
            });
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type});
            window.history.replaceState({}, '', window.location.pathname);
            setOpen(false);
        });

    }, [isFirstRender, provider]);

    return (
        <>
            {open && <FullScreenLoader
                show={open}
                loadingText={t('pages.business.chooseProvider.configuringStripe')}
                />}
        </>
    );
}

export default StripeOAuthConnector;