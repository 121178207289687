import { FC } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Services and interfaces
import { ICheckinSubmission } from "@/interfaces/checkin_submission/checkin_submission";
import { useGetClientCheckinSubmissionsQuery } from "@/repositories/checkin_submission";

// Components
import DataTable from "@/components/datatable";
import { Box, Container } from "@mui/material";
import useCheckinPopup from "./use_checkin_popup";
import useCheckinsTableHeaders from "./table_headers";
import SecondaryToolbar from "@/components/secondary_toolbar";


const CheckinsPage: FC = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    const headers = useCheckinsTableHeaders();

    const { open, handleSetOpen, handleSetCheckin } = useCheckinPopup();

    const { data: list, isLoading } = useGetClientCheckinSubmissionsQuery(Number(id));

    const handleRowClick = (row: ICheckinSubmission) => {
        if (['overdue', 'due_today', 'missed'].includes(row.status) || open) return;
        handleSetCheckin(Number(id), row.id);
        handleSetOpen(true);
    }

    return (
        <Box width='100%' height='100%'>

            <SecondaryToolbar
                title={t('pages.client.checkin')}
            />

            <Box sx={{overflowY: 'auto', height: 'calc(100% - 48px)'}}>
            <Container sx={{py: '24px'}}>

                <DataTable
                    data={list}
                    dataKey="id"
                    columns={headers} 
                    hideHeader
                    hideFilter
                    localPagination
                    noDataMessage={t('components.dataTable.noData.noLoggedData')}
                    isLoading={isLoading}
                    rowClick={handleRowClick}
                    />

            </Container>
            </Box>
            
        </Box>
    )
}

export default CheckinsPage;