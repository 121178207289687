import { FC } from "react";
import { useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";
import { capitalize } from "@/_helpers/text_functions";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import IStorefront from "@/interfaces/storefront/storefront";

// Styles
import { ConnectionSignalOff } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal";
import { useDisconnectProviderMutation } from "@/repositories/storefront";


interface _DisconnectModalProps {
    open: boolean;
    onClose: () => void;
    storefront: IStorefront;
}
const DisconnectModal: FC<_DisconnectModalProps> = ({
    open,
    onClose,
    storefront,
}) => {

    const { t } = useTranslation();
    const [disconnectProvider, { isLoading }] = useDisconnectProviderMutation();

    const submit = () => {
        disconnectProvider(storefront.id).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.storefront.providerDisconnected.title', {provider: capitalize(storefront.provider ?? '')}), 
                message: t('notifications.storefront.providerDisconnected.message', {provider: capitalize(storefront.provider ?? '')})}
            )
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
            onClose();
        });
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            showClose={!storefront.can_disconnect}
            title={t('modals.disconnectProvider.title', {context: `${storefront.can_disconnect}`, provider: capitalize(storefront.provider ?? '')})}
            text={t('modals.disconnectProvider.text', {context: `${storefront.can_disconnect}`, provider: storefront.provider})}
            action1={!storefront.can_disconnect ? undefined : {
                kind: 'danger',
                label: t('components.buttons.disconnectProvider', {provider: capitalize(storefront.provider ?? '')}),
                icon: <ConnectionSignalOff />,
                onClick: submit,
                loading: isLoading
            }}
            cancel={!storefront.can_disconnect ? undefined :{
                label: t('components.buttons.cancel'),
                onClick: onClose,
                disabled: isLoading
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '448px'}}}
            />
    )
}

export default DisconnectModal;