import { z } from 'zod';
import ruleset, { transformEmptyStringToNull } from '@/_helpers/ruleset';


export interface IClientProductForm {
    payment_product: number|string,
    payment_start: string,
}


export const clientProductSchema = z.object({
    payment_product: ruleset.clientProduct,
    payment_start: transformEmptyStringToNull(ruleset.dateOptional),
})