import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { useDeleteCheckinSubmissionMutation } from "@/repositories/checkin_submission";
import { ICheckinSubmission } from "@/interfaces/checkin_submission/checkin_submission";

// Styles
import { TrashCan } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import DeleteCheckinModal from "./delete_modal";
import IconButton from "@/components/icon_button";


interface _DeleteCheckinProps {
    row: ICheckinSubmission;
}

const DeleteCheckin: FC<_DeleteCheckinProps> = ({
    row
}) => {

    const { id } = useParams();
    const { t } = useTranslation();
    const [open, setOpen] = useState<string|null>(null);
    const [deleteSubmission, { isLoading: isDeleting }] = useDeleteCheckinSubmissionMutation();

    const handleDelete = () => {
        deleteSubmission({client_id: Number(id), submission_id: row.id}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.checkins.deleted.title'),
            });
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Box display="flex" justifyContent="flex-end">
            <IconButton
                kind="ghost"
                size="small"
                icon={<TrashCan style={{color: row.readonly ? 'var(--icon-disabled)' : 'var(--support-error)'}} />}
                disabled={row.readonly}
                onClick={() => setOpen('delete')}
                />
            {open === 'delete' && <DeleteCheckinModal
                open={open === 'delete'}
                onClose={() => setOpen(null)}
                isLoading={isDeleting}
                onDelete={handleDelete}
                />}
            
        </Box>
    )
}

export default DeleteCheckin;