import { useTranslation } from "react-i18next";
import { NavItem } from "@/interfaces/components/nav_item";
import { useGetClientQuery } from "@/repositories/client";


const useClientDetailMenuItems = (id: number): Array<NavItem> => {

    const { t } = useTranslation();
    const { data: client } = useGetClientQuery(id);
    const showSupplements = client?.coach_settings.supplementation.supplements;
    const showActivity = Object.values(client?.coach_settings.activity ?? {}).some(value => value === true);
    const showRecovery = Object.values(client?.coach_settings.recovery ?? {}).some(value => value === true);
    const showHealth = Object.values(client?.coach_settings.health ?? {}).some(value => value === true);

    const MenuItems: Array<NavItem> = [
        {
            "label": t("components.clientMenu.overview"), 
            "path": `/clients/${id}/overview`
        },
        {
            "label": t("components.clientMenu.physique"), 
            "path": `/clients/${id}/physique/bodyweight`,
            "tabsRoot": "physique"
        },
        {
            "label": t("components.clientMenu.training"), 
            "path": `/clients/${id}/training/data`,
            "tabsRoot": "training"
        },
        {
            "label": t("components.clientMenu.nutrition"), 
            "path": `/clients/${id}/nutrition/data`,
            "tabsRoot": "nutrition"
        },
        ...showSupplements ? [{
            "label": t("components.clientMenu.supplements"), 
            "path": `/clients/${id}/supplements`
        }] : [],
        ...showActivity ? [{
            "label": t("components.clientMenu.activity"), 
            "path": `/clients/${id}/activity/data`,
            "tabsRoot": "activity"
        }] : [],
        ...showRecovery ? [{
            "label": t("components.clientMenu.recovery"), 
            "path": `/clients/${id}/recovery`
        }] : [],
        ...showHealth ? [{
            "label": t("components.clientMenu.health"), 
            "path": `/clients/${id}/health`
        }] : [],
        {
            "label": t("components.clientMenu.gallery"), 
            "path": `/clients/${id}/gallery`
        },
        {
            "label": t("components.clientMenu.checkins"), 
            "path": `/clients/${id}/checkins`
        },
        // {
        //     "label": t("components.clientMenu.habits"), 
        //     "path": `/clients/${id}/habits`
        // },
        // {
        //     "label": t("components.clientMenu.files"), 
        //     "path": `/clients/${id}/files`
        // }
    ]

    return MenuItems;
}

export default useClientDetailMenuItems;