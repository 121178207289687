import { ISelectOption } from "@/interfaces/components/select_option"

//TODO Come back to this and refactor to strongly type each array (split out)
export const units = {
    currency: [
        { label: 'GBP', value: '£' },
        { label: 'USD', value: '$' },
        { label: 'EUR', value: '€' },
        { label: 'AED', value: 'د.إ' },
        { label: 'AUD', value: 'A$' },
    ],
    weight: [
        { label: 'Kilograms', value: 'kg' },
        { label: 'Pounds', value: 'lbs' }
    ],
    measurements: [
        { label: 'Centimeters', value: 'cm' },
        { label: 'Inches', value: 'in' }
    ]
}

export const currencyOptions: ISelectOption<string>[] = [
    { id: 1, label: 'GBP', value: '£' },
    { id: 2, label: 'USD', value: '$' },
    { id: 3, label: 'EUR', value: '€' },
    { id: 4, label: 'AED', value: 'د.إ' },
    { id: 5, label: 'AUD', value: 'A$' }
]