import { FC } from "react";
import { t } from "i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { useAddClientProductMutation } from "@/repositories/client";
import { clientProductSchema, IClientProductForm } from "@/interfaces/client/client_product";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal";
import AddProductForm from "./add_product_form";


interface _AddProductModalProps {
    open: boolean;
    onClose: () => void;
    client_id: number;
    client_name: string;
}

const AddProductModal: FC<_AddProductModalProps> = ({
    open,
    onClose,
    client_id,
    client_name,
}) => {

    const [addProduct, { isLoading }] = useAddClientProductMutation();

    const formMethods = useForm<IClientProductForm>({
        resolver: zodResolver(clientProductSchema),
        mode: 'onBlur',
        defaultValues: {
            payment_product: '',
            payment_start: ''
        }
    });

    const handleAddProduct = (data: IClientProductForm) => {
        addProduct({id: client_id, data}).unwrap().then((session) => {
            showToast({
                type: 'success',
                title: t('notifications.client.productAdded.title'),
                message: t('notifications.client.productAdded.message', {name: client_name, product: session.product_name})
            });
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Modal 
            open={open}
            onClose={onClose}
            title={t('modals.clientAddProduct.title')}
            text={t('modals.clientAddProduct.text')}
            children={
                <FormProvider {...formMethods}>
                    <AddProductForm />
                </FormProvider>
            }
            action1={{
                label: t('components.buttons.add'),
                icon: <ArrowRight />,
                loading: isLoading,
                onClick: formMethods.handleSubmit(handleAddProduct)
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            sx={{'& .MuiPaper-root': {minWidth: '480px', background: 'var(--background)'}}}
            />
    )
}

export default AddProductModal;