import baseApi from '@/repositories/base_api';
import { IAPIResponse } from '@/interfaces/api/response';
import { ICheckout } from '@/interfaces/checkout/checkout';
import ICheckoutProcessed from '@/interfaces/checkout/checkout_processed';


export const checkoutApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getCheckout: build.query<ICheckout, string>({
            query: (session_id) => ({ url: `/checkouts/${session_id}`, method: 'get'}),
            transformResponse: (response: IAPIResponse<ICheckout>) => response.data,
        }),
        processCheckout: build.mutation<ICheckoutProcessed, {session_id: string, payment_method_id: string}>({
            query: ({session_id, payment_method_id}) => ({ url: `/checkouts/${session_id}`, method: 'post', data: {session_id, payment_method_id} }),
            transformResponse: (response: IAPIResponse<ICheckoutProcessed>) => response.data,
        }),
        cancelCheckout: build.mutation<any, string>({
            query: (session_id) => ({ url: `/checkouts/${session_id}`, method: 'delete'}),
            transformResponse: (response: IAPIResponse<any>) => response.data,
        })
    }),
})

export const { 
    useGetCheckoutQuery,
    useProcessCheckoutMutation
} = checkoutApi;

