import { t } from "i18next";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

// Styles
import Payments from "@/assets/business/payments.png";
import Products from "@/assets/business/products.png";
import Scale from "@/assets/business/scale.png";
import Connect from "@/assets/business/connect.png";

// Components
import { Box } from "@mui/material";
import Button from "@/components/button";
import { Money } from "@carbon/icons-react";


const PaymentsPreview: FC = () => {

    const navigate = useNavigate();
    const [selectedMenuItem, setSelectedMenuItem] = useState(0);

    const menuItems = [
        {title: t('components.businessSetup.menuItems.products.title'), description: t('components.businessSetup.menuItems.products.description')},
        {title: t('components.businessSetup.menuItems.payments.title'), description: t('components.businessSetup.menuItems.payments.description')},
        {title: t('components.businessSetup.menuItems.performance.title'), description: t('components.businessSetup.menuItems.performance.description')},
        {title: t('components.businessSetup.menuItems.marketing.title'), description: t('components.businessSetup.menuItems.marketing.description')},
    ]

    const content = () => {
        switch (selectedMenuItem) {
            case 0:
                return <_ProductsContent />
            case 1:
                return <_PaymentsContent />
            case 2:
                return <_ScaleContent />
            case 3:
                return <_ConnectContent />
            default:
                return <></>
        }
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="center">

            <span className="heading-02" style={{color: 'var(--teal-60)', marginBottom: '20px'}}>{t('pages.business.setup.title')}</span>
            <span className="body-01-compact">{t('pages.business.setup.subtitle')}</span>

            {/* Flexible space */}
            <Box sx={{height: 'clamp(8px, 48px, 48px)'}} />

            {/* Pamphlet */}
            <Box 
                display="grid" 
                gridTemplateColumns="1fr 1fr" 
                width="100%" 
                maxWidth="1164px" 
                borderRadius="6px"
                minHeight="592px"
                border="solid 1px var(--border-subtle-01)"
                bgcolor="var(--layer-01)"
                boxShadow="0px 2px 6px 0px rgba(0, 0, 0, 0.30)"
                >

                {/* Menu */}
                <Box 
                    display="flex" 
                    flexDirection="column" 
                    padding="32px 48px" 
                    rowGap="12px"
                    minWidth="clamp(200px, 25%, 350px)"
                    sx={{
                        borderTopLeftRadius: '6px', 
                        borderBottomLeftRadius: '6px'}}
                    >
                    {menuItems.map((item, index) => (
                        <Box 
                            key={index} 
                            display="flex" 
                            padding="16px 24px" 
                            boxSizing="border-box" 
                            borderRadius="6px"
                            onClick={() => setSelectedMenuItem(index)}
                            sx={{
                                bgcolor: selectedMenuItem == index ? 'var(--teal-10)' : '',
                                cursor: 'pointer',
                                ":hover": {bgcolor: 'var(--teal-10-hover)'}
                            }}
                            >

                            {/* Number */}
                            <span className="heading-07-compact" style={{marginRight: '16px'}}>{index+1}</span>

                            {/* Item title and description */}
                            <Box key={index} display="flex" flexDirection="column" textAlign="left" sx={{overflow: 'hidden'}}>
                                <span className="heading-07-compact">{item.title}</span>
                                <span className="body-02">{item.description}</span>
                            </Box>

                        </Box>
                    ))}

                    {/* Spacer */}
                    <Box height="48px" />

                    {/* Actions */}
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Box display="flex" flexDirection="column" textAlign="left">
                            <span className="heading-06-compact">{`£10 /${t('timeDate.month')}`}</span>
                            <span className="label-text-02" style={{color: 'var(--text-placeholder)'}}>{t('components.businessSetup.action.text')}</span>
                        </Box>
                        
                        <Button
                            label={t('components.buttons.getStarted')}
                            endIcon={<Money />}
                            onClick={() => navigate('/settings/billing?connectsetup=true')}
                            />
                    </Box>
                
                </Box>

                {/* Content */}
                <Box 
                    display="flex" 
                    flexDirection="column"
                    sx={{
                        bgcolor: 'var(--teal-10)', 
                        borderTopRightRadius: '6px', 
                        borderBottomRightRadius: '6px',
                        overflow: 'hidden'
                    }}
                    >
                    {content()}
                </Box>

            </Box>
        
        </Box>
    )
}

export default PaymentsPreview;

const _ProductsContent: FC = () => {

    return (
        <Box display="flex" height="100%" alignItems="center" justifyContent="center">

            <Box
                className="fade-in"
                component="img"
                src={Products}
                sx={{
                    maxWidth: '505px',
                    // maxHeight: '233px',
                    width: '100%',
                    height: 'auto',
                }}
            />

        </Box>
    )
}

const _PaymentsContent: FC = () => {

    return (
        <Box display="flex" height="100%" alignItems="flex-end" justifyContent="center">

            <Box
                className="fade-in"
                component="img"
                src={Payments}
                sx={{
                    maxWidth: '440px',
                    maxHeight: '509px',
                    width: '100%',
                    height: 'auto',
                }}
            />

        </Box>
    )
}

const _ScaleContent: FC = () => {

    return (
        <Box display="flex" height="100%" alignItems="flex-end" justifyContent="center">

            <Box
                className="fade-in"
                component="img"
                src={Scale}
                sx={{
                    maxWidth: '498px',
                    maxHeight: '570px',
                    width: '100%',
                    height: 'auto',
                }}
            />

        </Box>
    )
}

const _ConnectContent: FC = () => {

    return (
        <Box display="flex" height="100%" alignItems="center" justifyContent="flex-end">

            <Box
                className="fade-in"
                component="img"
                src={Connect}
                sx={{
                    maxWidth: '528px',
                    maxHeight: '480px',
                    width: '100%',
                    height: 'auto',
                }}
            />

        </Box>
    )
}