import dayjs from "dayjs";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Helpers
import { roundNumber } from "@/_helpers/number_functions";

// Services and interfaces
import { selectSiteSettingsBodyweight } from "@/store/settings";
import { TotalValues } from "@/interfaces/components/totals_card";
import { useGetClientDataQuery } from "@/repositories/client_data";
import { IBodyweightData } from "@/interfaces/client/bodyweight_data";

// Components
import TotalsCard from "./totals_card";
import { Box, Container } from "@mui/material";
import DataTable from "@/components/datatable";
import Chart from "@/pages/client/components/chart";
import useBodyweightTableHeaders from "./bodyweight_headers";


const BodyweightTab: FC = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const { data, isLoading } = useGetClientDataQuery(Number(id));
    const headers = useBodyweightTableHeaders();
    const weightUnit = useSelector(selectSiteSettingsBodyweight) ?? { value: 'kg' };

    const calculateChange = (bodyweight: number, index: number): number => {
        if (!data || !bodyweight) return 0;
        const next = data?.slice(index + 1).find((next) => next.bodyweight)?.bodyweight
        if (!next) return 0;
        return roundNumber(bodyweight - next, 2);
    }

    const chartData = data?.map((item, i): IBodyweightData => {
        return {
            id: item.id,
            date: item.date,
            bodyweight: item.bodyweight,
            change: calculateChange(item.bodyweight, i)
        }
    }) ?? [];
    const hasWeightData = chartData.some(item => item.bodyweight);

    const now = dayjs();
    const sevenDaysAgo = now.subtract(7, 'day');
    const forteenDaysAgo = now.subtract(14, 'day');

    const thisWeek = chartData.filter((item) => item.bodyweight && dayjs(item.date).isAfter(sevenDaysAgo));
    const lastWeek = chartData.filter((item) => item.bodyweight && dayjs(item.date).isAfter(forteenDaysAgo) && dayjs(item.date).isBefore(sevenDaysAgo));

    const averageTotals = (): TotalValues => {
        if (!chartData || !thisWeek.length) return {value: 0, change: 0, daysLogged: 0};

        const average = roundNumber(thisWeek.reduce((acc, item) => {
            return acc + item.bodyweight;
        }, 0) / thisWeek.length, 2);
        const lastWeekAverage = roundNumber(lastWeek.reduce((acc, item) => {
            return acc + item.bodyweight;
        }, 0) / lastWeek.length, 2);

        const change = average && lastWeekAverage ? roundNumber(average - lastWeekAverage, 2) : 0;
        return {value: average, change: change, daysLogged: thisWeek.length};
    }

    const highTotals = (): TotalValues => {
        if (!chartData || !thisWeek.length) return {value: 0, change: 0, daysLogged: 0};

        const high = roundNumber(thisWeek.reduce((max, item) => {
            return item.bodyweight > max ? item.bodyweight : max;
        }, 0), 2);
        const lastWeekHigh = roundNumber(lastWeek.reduce((max, item) => {
            return item.bodyweight > max ? item.bodyweight : max;
        }, 0), 2);

        const change = high && lastWeekHigh ? roundNumber(high - lastWeekHigh, 2) : 0;
        return {value: high, change: change, daysLogged: thisWeek.length};
    }

    const lowTotals = (): TotalValues => {
        if (!chartData || !thisWeek.length) return {value: 0, change: 0, daysLogged: 0};

        const low = roundNumber(thisWeek.reduce((min, item) => {
            return item.bodyweight < min ? item.bodyweight : min;
        }, thisWeek[0]?.bodyweight ?? 0), 2);
        const lastWeekLow = roundNumber(lastWeek.reduce((min, item) => {
            return item.bodyweight < min ? item.bodyweight : min;
        }, lastWeek[0]?.bodyweight ?? 0), 2);

        const change = low && lastWeekLow ? roundNumber(low - lastWeekLow, 2) : 0;
        return {value: low, change: change, daysLogged: thisWeek.length};
    }

    return (
        <Box sx={{overflowY: 'auto', height: 'calc(100% - 48px)'}}>
        <Container sx={{py: '24px'}}>

            <Box display="flex" columnGap="16px">

                <TotalsCard metric="average" totals={averageTotals()} isLoading={isLoading} onShowChart={hasWeightData ? () => setOpen(true) : undefined} />
                <TotalsCard metric="high" totals={highTotals()} isLoading={isLoading} hideChartButton />
                <TotalsCard metric="low" totals={lowTotals()} isLoading={isLoading} hideChartButton />
                {open == true && 
                    <Chart
                        open={open}
                        onClose={() => setOpen(false)}
                        title={t('metrics.bodyweight')}
                        data={data ?? []}
                        metric="bodyweight"
                        yLabel={`${t('metrics.bodyweight')} (${weightUnit.value})`}
                        />}

            </Box>

            <DataTable
                data={chartData}
                dataKey="id"
                columns={headers} 
                hideHeader
                hideFilter
                localPagination
                noDataMessage={t('components.dataTable.noData.noLoggedData')}
                isLoading={isLoading}
            />

        </Container>
        </Box>
    )
}

export default BodyweightTab;