

const currencySymbols: { [key: string]: string } = {
    USD: "$",  // US Dollar
    EUR: "€",  // Euro
    GBP: "£",  // British Pound
    JPY: "¥",  // Japanese Yen
    AUD: "A$", // Australian Dollar
    CAD: "C$", // Canadian Dollar
    CHF: "CHF",// Swiss Franc
    CNY: "¥",  // Chinese Yuan
    SEK: "kr", // Swedish Krona
    NZD: "NZ$",// New Zealand Dollar
    INR: "₹",  // Indian Rupee
    ZAR: "R",  // South African Rand
    BRL: "R$", // Brazilian Real
    RUB: "₽",  // Russian Ruble
    MXN: "$",  // Mexican Peso
    SGD: "S$", // Singapore Dollar
    HKD: "HK$",// Hong Kong Dollar
    NOK: "kr", // Norwegian Krone
    KRW: "₩",  // South Korean Won
    TRY: "₺",  // Turkish Lira
    AED: "د.إ", // UAE Dirham
    SAR: "﷼",  // Saudi Riyal
    THB: "฿",  // Thai Baht
  };

  export const getCurrencySymbol = (code?: string) => {
    if (!code) return "?";
    code = code.toUpperCase();
    return currencySymbols[code] || code; // Return ISO code if symbol is not found
  };