import { useTranslation } from "react-i18next";

// Helpers
import { capitalizeWords } from "@/_helpers/text_functions";
import { getCurrencySymbol } from "@/_helpers/currency_functions";

// Interfaces
import { ITransaction, ITransactionQueryParams } from "@/interfaces/transaction/transaction";

// Components
import Tag from "@/components/tag";
import Link from "@/components/link";
import { ColDef } from "@/components/datatable";
import TransactionMenu from "@/components/transactions/transaction_menu";


interface _TransactionTableHeadersProps {
    params?: ITransactionQueryParams
}

const useTransactionTableHeaders = ({
    params
}: _TransactionTableHeadersProps): ColDef<ITransaction>[] => {

    const { t } = useTranslation();

    const tagColor = (status: string) => {
        switch (status) {
            case 'pending':
                return 'grey';
            case 'succeeded':
                return 'green';
            case 'failed':
                return 'red';
            default:
                return 'grey';
        }
    }

    const headers: ColDef<ITransaction>[] = [
        {
            field: 'date',
            headerName: t('components.dataTable.headers.date'),
            width: '10%',
            sortable: true,
            render: (row) => new Date(row.paid_at).toLocaleDateString()
        },
        {
            field: 'product_name',
            headerName: t('components.dataTable.headers.product'),
            width: '25%',
            sortable: true,
            render: (row) => row.product_name ? capitalizeWords(row.product_name) : '-'
        },
        {
            field: 'amount',
            headerName: t('components.dataTable.headers.total'),
            width: '10%',
            // sortable: true,
            render: (row) => row.amount ? `${getCurrencySymbol(row.currency)}${row.amount.toFixed(2)}` : '-'
        },
        {
            field: 'status',
            headerName: t('components.dataTable.headers.status'),
            width: '15%',
            sortable: true,
            render: (row) => <Tag label={t(`enums.transactionStatus.${row.status}`)} colour={tagColor(row.status)} />
        },
        {
            field: 'invoice_url',
            headerName: t('components.dataTable.headers.invoice'),
            width: '10%',
            render: (row: ITransaction) => 
                <Link 
                    label={t('components.links.view')} 
                    to={row.invoice_url && row.status !== 'failed' ? row.invoice_url : ''} 
                    newWindow
                    />
        },
        {
            field: 'actions',
            headerName: '',
            width: '6%',
            render: (row: ITransaction) => <TransactionMenu row={row} params={params} />
        }
    ];

    return headers;
}

export default useTransactionTableHeaders;