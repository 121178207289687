import { FC } from "react";
import { t } from "i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { IPaymentProduct } from "@/interfaces/client/client";
import { useCancelClientSubscriptionMutation } from "@/repositories/client_subscription";
import { clientSubscriptionEndSchema, IClientSubscriptionEndForm } from "@/interfaces/client_subscription/client_subscription_form";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal";
import SubscriptionCancelForm from "./subscription_cancel_form";


interface _SubscriptionCancelModalProps {
    open: boolean;
    onClose: () => void;
    client_id: number;
    product: IPaymentProduct;
}

const SubscriptionCancelModal: FC<_SubscriptionCancelModalProps> = ({
    open,
    onClose,
    client_id,
    product
}) => {

    const [cancelSubscription, { isLoading }] = useCancelClientSubscriptionMutation();

    const formMethods = useForm<IClientSubscriptionEndForm>({
        resolver: zodResolver(clientSubscriptionEndSchema),
        mode: 'onBlur',
        defaultValues: {
            end_at: 'now',
            refund: 'none'
        }
    });

    const handleCancelSubscription = (data: IClientSubscriptionEndForm) => {
        const product_name = product?.product_name || '';
        cancelSubscription({client_id, data}).unwrap().then((d) => {
            showToast({
                type: 'success',
                title: t('notifications.client.productCancelled.title'),
                message: t('notifications.client.productCancelled.message', {name: `${d.first_name} ${d.last_name}`, product: product_name})
            });
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Modal 
            open={open}
            onClose={onClose}
            title={t('modals.cancelClientSubscription.title')}
            text={t('modals.cancelClientSubscription.text')}
            children={
                <FormProvider {...formMethods}>
                    <SubscriptionCancelForm product={product} />
                </FormProvider>
            }
            action1={{
                kind: 'danger',
                label: t('components.buttons.cancelSubscription'),
                icon: <ArrowRight />,
                loading: isLoading,
                onClick: formMethods.handleSubmit(handleCancelSubscription)
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '480px', background: 'var(--background)'}}}
            />
    )
}

export default SubscriptionCancelModal;