import { FC } from "react";
import { t } from "i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { useCancelPendingProductChangeMutation } from "@/repositories/client_subscription";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal";


interface _CancelChangeModalProps {
    open: boolean;
    onClose: () => void;
    client_id: number;
}

const CancelChangeModal: FC<_CancelChangeModalProps> = ({
    open,
    onClose,
    client_id,
}) => {

    const [cancelPendingChange, { isLoading }] = useCancelPendingProductChangeMutation();

    const handleCancelChange = () => {
        cancelPendingChange(client_id).unwrap().then((response) => {
            showToast({
                type: 'success',
                title: t('notifications.client.pendingProductChangeCancelled.title'),
                message: t('notifications.client.pendingProductChangeCancelled.message', {name: `${response.first_name} ${response.last_name}`})
            });
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Modal 
            open={open}
            onClose={onClose}
            title={t('modals.cancelClientChangeProduct.title')}
            text={t('modals.cancelClientChangeProduct.text')}
            action1={{
                kind: 'danger',
                label: t('components.buttons.cancelPendingChange'),
                icon: <ArrowRight />,
                loading: isLoading,
                onClick: handleCancelChange
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '480px', background: 'var(--background)'}}}
            />
    )
}

export default CancelChangeModal;