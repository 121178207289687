import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom"

// Repositories
import baseApi from "@/repositories/base_api";
import { useGetTeamQuery } from "@/repositories/team";
import { useGetUserQuery } from "@/repositories/auth";
import { useGetSubscriptionQuery } from "@/repositories/subscription";

// Selectors
import { selectIsAuthenticated } from "@/store/auth";
import { selectHasCompletedSetup } from "@/store/coach";
import { selectHasTeamOrInvitation, selectIsOwner } from "@/store/team";

// Components
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";


const AuthGuard = () => {

  const dispatch = useDispatch();
  const { isLoading: userIsLoading } = useGetUserQuery();
  const { isLoading: subIsLoading } = useGetSubscriptionQuery();
  const { isLoading: teamIsLoading } = useGetTeamQuery();

  const loaders = [userIsLoading, subIsLoading, teamIsLoading];
  const isLoading = loaders.some(l => l);

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const completedSetup = useSelector(selectHasCompletedSetup);
  const hasTeamOrInvitation = useSelector(selectHasTeamOrInvitation);
  const isOwner = useSelector(selectIsOwner);
  const location = useLocation();

  useEffect(() => {
    return () => {
      dispatch(baseApi.util.resetApiState());
    } 
  }, [])

  if (isLoading) {
    //TODO replace with loader from designs
    return (
      <Backdrop open={true}>
        <CircularProgress />
      </Backdrop>
    ) 
  }
  
  if (!isAuthenticated) {
    return <Navigate to="/auth/login" replace />
  }

  if (!completedSetup && hasTeamOrInvitation && !isOwner && !['/setup/team', '/setup/profile', '/setup/settings', '/setup/checkin'].includes(location.pathname)) {
    return <Navigate to="/setup/team" replace />;
  }
  
  if (!completedSetup && !location.pathname.startsWith('/setup')) {
    return <Navigate to="/setup/subscription" replace />;
  }

  if (completedSetup && location.pathname.startsWith('/setup')) {
    return <Navigate to="/clients/active" replace />;
  }

  return (
    <Outlet />
  );
};

export default AuthGuard;