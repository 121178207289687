import { t } from "i18next";
import { useSelector } from "react-redux";
import { NavItem } from "@/interfaces/components/nav_item";
import { selectHasTeam, selectIsOwner } from "@/store/team";
import { selectPaymentsEnabled } from "@/store/subscription";


const useSettingsMenuItems = (): Array<NavItem> => {

    const hasTeam = useSelector(selectHasTeam);
    const isTeamOwner = useSelector(selectIsOwner);
    const paymentsEnabled = useSelector(selectPaymentsEnabled);
    const showPay = (paymentsEnabled && !hasTeam) || (paymentsEnabled && hasTeam && isTeamOwner)

    const SettingsMenuItems: Array<NavItem> = [
        {
            "label": t("components.settingsMenu.profile"), 
            "path": "/settings/profile"
        },
        {
            "label": t("components.settingsMenu.privacy"), 
            "path": "/settings/privacy"
        },
        {
            "label": t("components.settingsMenu.billing"), 
            "path": "/settings/billing"
        },
        {
            "label": t("components.settingsMenu.settings"), 
            "path": "/settings/metrics"
        },
        {
            "label": t("components.settingsMenu.teams"), 
            "path": "/settings/teams"
        },
        // {
        //     "label": t("components.settingsMenu.notifications"), 
        //     "path": "/settings/notifications"
        // },
        {
            "label": t("components.settingsMenu.referrals"), 
            "path": "/settings/referrals"
        },
        ...(showPay ? [{
            "label": t("components.settingsMenu.1FitPay"), 
            "path": "/settings/1fitpay"
        }] : []),
    ]

    return SettingsMenuItems;
}

export default useSettingsMenuItems;