import { z } from "zod";
import { t } from "i18next";
import ruleset from "@/_helpers/ruleset";


export interface ITemplateAssignForm {
    client_id: number;
    template_id: string;
}

export interface ITemplateAssignManyForm {
    id: string;
    clients: number[];
    active?: boolean;
}

export const templateAssignSchema = z.object({
    client_id: ruleset.requiredNumber,
    template_id: ruleset.required
});

export const templateAssignManySchema = z.object({
    // The ID of the template to assign
    id: ruleset.required,
    // An array of client IDs that must contain at least one element
    clients: z.array(z.union([z.string(), z.number()]))
        .nonempty({ message: t('inputs.errors.selectClients') })
            .transform(clients => clients.map(id => id)),
    active: ruleset.booleanOptional
});