import dayjs from "dayjs";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Services and interfaces
import { useGetClientQuery } from "@/repositories/client";
import IGallery, { IGalleryContent } from "@/interfaces/gallery/gallery";

// Styles
import { Download } from "@carbon/icons-react";

// Components
import Box from "@mui/material/Box"
import Image from "@/components/image";
import Avatar from "@/components/avatar";
import IconButton from "@/components/icon_button";
import { downloadQuery } from "@/repositories/media";


interface LightboxProps {
    index: number;
    total: number;
    content: IGalleryContent & IGallery;
    hideComments?: boolean;
}

const Lightbox: FC<LightboxProps> = ({
    index,
    total,
    content,
    // hideComments = false
}) => {

    const { id } = useParams();
    const { data: client } = useGetClientQuery(Number(id)); 

    const { t } = useTranslation()

    return (
        <Box height='95vh' maxWidth='90vw' width="100%" display='flex' borderRadius='6px' bgcolor='white' overflow='hidden'>
            {/* lhs */}
            <Image 
                key={content.uuid}
                // src='https://images.unsplash.com/photo-1526506118085-60ce8714f8c5' // portrait image
                // src='https://images.unsplash.com/photo-1514994444123-10094655bdb5' // landscape image
                src={content.original} 
            />

            {/* rhs */}
            <Box display='flex' flexDirection='column' width={400}>
                {/* comment section */}
                <Box sx={{flexGrow: 1, p: 0}}>
                    <Box p={1.25} display='flex' alignItems='center' borderBottom='1px solid #F0F0F0'>
                        <Avatar 
                            name={`${client?.first_name} ${client?.last_name}`} 
                            src={client?.avatar ?? ''}
                            index={0}
                        />
                        <Box flexShrink={1} mx={1} textAlign='left'>
                            <Box className='text-primary heading-07'>
                                {`${client?.first_name} ${client?.last_name}`}
                            </Box>
                            <span className='label-text-02 text-secondary'>
                                {dayjs(content.date).format('DD/MM/YYYY')}
                                <Box component='span' px={1}>·</Box>
                                {index+1} - {total}
                            </span>
                        </Box>
                        <Box flexGrow={1} />
                        <IconButton 
                            kind='ghost'
                            icon={<Download />}
                            onClick={()=>downloadQuery(content.original)}
                        />
                    </Box>

                    {/* comments */}
                    {!!content.description && (
                        <_LightboxComment 
                            name={`${client?.first_name} ${client?.last_name}`} 
                            avatarSrc={client?.avatar ?? ''}
                            date={content.date!}
                            comment={content.description}
                        />
                    )}
                </Box>

                {/* no comments */}
                {!content.description && (
                    <Box flexGrow={100} display='grid'>
                        <Box className='text-primary' sx={{placeSelf: 'center'}}>
                            <Box className='heading-06'>{t('components.gallery.noComments')}</Box>
                            <Box className='body-02'>
                                {t('components.gallery.noCommentsText', {name: client?.first_name})}
                            </Box>
                        </Box>
                    </Box>
                )}

                {/* new comment */}
                {/* {!hideComments && (
                    <Box display='flex' justifyContent='space-between' alignItems='center' p={2} borderTop='1px solid #F0F0F0'>
                        <span className='body-02 text-secondary'>
                            {t('components.gallery.addComment')}
                        </span>
                        <Button 
                            label={t('components.gallery.comment')}
                            minWidth={false}
                            size='small'
                            kind='ghost'
                            disabled
                            onClick={(()=>{})}
                        />
                    </Box>
                )} */}
            </Box>
        </Box>
    );
}

export default Lightbox;

interface CommentProps {
    name: string;
    avatarSrc: string;
    date: string;
    comment: string;
    index?: number;
}

const _LightboxComment: FC<CommentProps> = ({
    name,
    avatarSrc,
    date,
    comment,
    index = 0
}) => (
    <Box display='flex' p={1.25} borderBottom='1px solid #F0F0F0'>
        <Avatar 
            index={index}
            name={name} 
            src={avatarSrc}
        />
        <Box mx={1} textAlign='left'>
            <Box className='body-02 text-primary'>{comment}</Box>
            <Box className='label-text-02 text-secondary'>{dayjs(date).fromNow()}</Box>
        </Box>
    </Box>
)