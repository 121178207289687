import { FC } from "react";
import { useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

// Services and interfaces
import { useGetPhaseQuery } from "@/repositories/phase";

// Components
import PhaseCards from "./phase_cards";
import PhaseDialogs from "./phase_dialogs";
import DataTable from "@/components/datatable";
import usePhaseTableHeaders from "./phase_headers";
import { PhaseProvider } from "@/contexts/phase_context";


interface _PhaseDetailsProps {
    phase_id: string;
}

const PhaseDetails: FC<_PhaseDetailsProps> = ({
    phase_id
}) => {

    const { id } = useParams()
    const { data: phase, isLoading } = useGetPhaseQuery({client_id: Number(id), phase_id: phase_id}, {skip: !id});

    const colDefs = usePhaseTableHeaders();

    return (
        <PhaseProvider phase={phase}>
            <Box>
                <Box mx={3}>
                    {isLoading && <>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </>}
                    <p className="body-02-compact max-lines-3" style={{color: 'var(--text-secondary)'}}>{phase?.description}</p>
                    <PhaseCards />
                </Box>
                
                <DataTable
                    data={phase?.weeks ?? []}
                    columns={colDefs}
                    size="large"
                    dataKey='week'
                    hideHeader
                    hideFilter
                    transparent
                    localPagination
                    isLoading={isLoading}
                    />

                <PhaseDialogs />
            </Box>
        </PhaseProvider>
    );
}

export default PhaseDetails;