import { FC } from "react";
import { t } from "i18next";
import { Trans } from "react-i18next";

// Helpers
import { getCurrencySymbol } from "@/_helpers/currency_functions";
import { primaryFontFromBackground, secondaryFontFromBackground } from "@/_helpers/colour_functions";

// Services and interfaces
import { ICheckout } from "@/interfaces/checkout/checkout";

// Components
import { Box } from '@mui/material';
import Avatar from "@/components/avatar";


interface _ProductPaneProps {
    session: ICheckout;
    isLoading?: boolean;
    isComplete?: boolean;
}

const ProductPane: FC<_ProductPaneProps> = ({
    session,
    isLoading = false,
    isComplete = false
}) => {

    const product = session?.product;

    const backgroundColor = session?.storefront.primary_color;
    const accentColour = session?.storefront.secondary_color;
    const primaryFont = primaryFontFromBackground(backgroundColor ?? 'var(--white)');
    const secondaryFont = secondaryFontFromBackground(backgroundColor ?? 'var(--white)');

    const isRecurring = session?.product.subtype === 'recurring';
    const currencySymbol = getCurrencySymbol(product?.currency);

    return (
        <Box display="flex" flexDirection="column" flexGrow={1} bgcolor={session.storefront.primary_color} padding="16px" alignItems="center">

            {/* Flexible space */}
            <Box sx={{height: 'clamp(30px, 10vh, 70px)'}} />

            <Box display="flex" flexDirection="column" width="100%" maxWidth="400px">

                <Box display="flex" alignItems="center">
                    <Avatar 
                        index={0}
                        isLoading={isLoading}
                        size='medium' 
                        alt={session.storefront.name}
                        name={session.storefront.name}
                        src={session.storefront.logo}
                        sx={{mr: 2, border: `solid 2px ${accentColour}`}}
                        />
                    <span className="heading-05" style={{color: primaryFont}}>{session.storefront.name}</span>
                </Box>

                <Box height="48px" />

                {product && <Box display="flex" flexDirection="column" width="100%" alignItems="flex-start" textAlign="left">
                    {!isComplete ? 
                        <span className="heading-06" style={{color: secondaryFont}}>{t('pages.checkout.subscribeTo', {product: product.name})}</span> :
                        <span className="heading-06" style={{color: secondaryFont}}>{t('pages.checkout.success.paymentConfirmed')}</span>}
                    <Box display="flex" alignItems="flex-end">
                        <span className="heading-04" style={{color: primaryFont}}>{`${product.currency.toUpperCase()} ${currencySymbol}${product.price.unit_amount}`}</span>
                        {isRecurring && <span className="body-02" style={{color: secondaryFont, marginLeft: '8px', marginBottom: '4px'}}>{t(`pages.checkout.per.${product.price.interval}`, {count: product.price.interval_count})}</span>}
                        {product.schedule && <span className="body-02" style={{color: secondaryFont, marginLeft: '8px', marginBottom: '4px'}}>{product.schedule.phases[0].iterations == 1 ? t(`pages.checkout.billedOnce`, {period: t(`timeDate.${product.schedule.phases[0].interval}`, {count: product.schedule.phases[0].interval_count})}) : t(`pages.checkout.${product.schedule.phases[0].interval}`, {count: product.price.interval_count})}</span>}
                    </Box>

                    <Box width="100%" border={`solid 0.5px ${secondaryFont}`} marginTop="16px" />

                    {/* Recurring product cost and term */}
                    {isRecurring && <Box display="flex" justifyContent="space-between" width="100%" marginTop="32px">
                        <Box display="flex" flexDirection="column" alignItems="flex-start">
                            <span className="heading-07" style={{color: primaryFont}}>{product.name}</span>
                            <span className="body-02" style={{color: secondaryFont}}>{t('pages.checkout.billedEveryUntilCancelled', {interval: t(`timeDate.${product.price.interval}`, {count: product.price.interval_count})})}</span>
                        </Box>
                        <span className="heading-07" style={{color: primaryFont}}>{`${product.currency.toUpperCase()} ${currencySymbol}${product.price.unit_amount}`}</span>
                    </Box>}

                    {/* Phased product costs */}
                    {product.schedule && <Box display="flex" flexDirection="column" width="100%" marginTop="32px">
                        {product.schedule.phases.map((phase, index) => {
                            return (<Box key={index} display="flex" justifyContent="space-between" width="100%" marginBottom="12px">
                                <Box display="flex" flexDirection="column" alignItems="flex-start">
                                    <span className="heading-07" style={{color: primaryFont}}>{t('pages.checkout.phase', {number: index+1})}</span>
                                    {phase.iterations == 1 ? 
                                        <span className="body-02" style={{color: secondaryFont}}>{t('pages.checkout.billedOnce', {period: t(`pages.checkout.${phase.interval}`, {count: phase.interval_count})} )}</span> :
                                        <span className="body-02" style={{color: secondaryFont}}>{t('pages.checkout.billedEvery', {
                                            interval: t(`pages.checkout.${phase.interval}`, {count: phase.interval_count}),
                                            period: t(`timeDate.${phase.interval}`, {count: phase.iterations})
                                        })}</span>}
                                </Box>
                                <span className="heading-07" style={{color: primaryFont}}>{`${product.currency.toUpperCase()} ${currencySymbol}${phase.unit_amount}`}</span>
                            </Box>)
                        })}
                    </Box>}

                    {product.schedule && <Box display="flex" width="100%" marginTop="16px">
                        {product.schedule.end_behaviour === 'cancel' ? 
                            <span className="body-02" style={{color: secondaryFont}}>{t('pages.checkout.cancelAtEnd')}</span> :
                            <span className="body-02" style={{color: secondaryFont}}>{t('pages.checkout.releaseAtEnd', {count: product.schedule.phases.length})}</span>}
                    </Box>}

                    {/* <Box display="flex" justifyContent="space-between" width="100%" marginTop="32px">
                        <span className="heading-07" style={{color: primaryFont}}>{t('pages.checkout.subtotal')}</span>
                        <span className="heading-07" style={{color: primaryFont}}>{`${product.currency.toUpperCase()} ${currencySymbol}${product.schedule ? product.schedule.phases[0].unit_amount : product.price.unit_amount}`}</span>
                    </Box> */}

                    <Box width="100%" border={`solid 0.5px ${secondaryFont}`} marginTop="16px" />

                    {/* Total */}
                    <Box display="flex" justifyContent="space-between" width="100%" marginTop="18px">
                        <span className="heading-07" style={{color: primaryFont}}>{isComplete ? t('pages.checkout.success.totalPaid') : t('pages.checkout.totalDue')}</span>
                        <span className="heading-07" style={{color: primaryFont}}>{`${product.currency.toUpperCase()} ${currencySymbol}${product.schedule ? product.schedule.phases[0].unit_amount : product.price.unit_amount}`}</span>
                    </Box>

                </Box>}
            </Box>

            <Box height="100%" />

            <Box alignItems="center" paddingBottom="40px" sx={{display: {xs: 'none', md: 'flex'}}}>
                <span className="body-02" style={{color: secondaryFont}}>{
                    <Trans 
                        i18nKey="pages.checkout.poweredBy"
                        components={{strong: <strong />}}
                        />
                }</span>
            </Box>

            
            
        </Box>
    )
}

export default ProductPane;