import { t } from "i18next";
import { FC, useState } from "react";

// Services and interfaces
import { IProduct } from "@/interfaces/product/product";
import { useGetProductsQuery } from "@/repositories/product";

// Components
import NoProducts from "./no_products";
import ProductPanel from "./product_panel";
import { Container, Box } from "@mui/material";
import DataTable from "@/components/datatable";
import useProductTableHeaders from "./table_headers";


const ProductsTab: FC = () => {

    const [selectedProduct, setSelectedProduct] = useState<IProduct|null>(null);
    const headers = useProductTableHeaders();
    const { data, isLoading } = useGetProductsQuery();

    const handleRowClick = (row: IProduct) => {
        setSelectedProduct(row);
    }

    return (
        <Box height='100%' overflow='auto'>
            <Container sx={{padding: '24px 0'}}>

                <DataTable
                    data={data ?? []}
                    dataKey="id"
                    columns={headers} 
                    hideHeader
                    localPagination
                    filterKeys={['name']}
                    noDataMessage={<NoProducts message={t('components.dataTable.noData.noProducts')} />}
                    noDataFoundMessage={<NoProducts />}
                    isLoading={isLoading}
                    rowClick={handleRowClick}
                    />
                {selectedProduct && 
                    <ProductPanel 
                        open={selectedProduct !== null} 
                        onClose={() => setSelectedProduct(null)}
                        product={selectedProduct}
                        />}

            </Container>
        </Box>
    );
};

export default ProductsTab;