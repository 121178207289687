import { t } from "i18next";
import { FC, useState } from "react";

// Services and interfaces
import IStorefront from "@/interfaces/storefront/storefront";

// Styles
import StripeCard from "@/assets/payment_providers/stripe_card.svg";
import { ArrowRight, ConnectionSignalOff } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import Link from "@/components/link";
import Button from "@/components/button";
import DisconnectModal from "./disconnect_modal";


interface _PayHeaderProps {
    storefront: IStorefront;
}

const StripeProviderConnected: FC<_PayHeaderProps> = ({
    storefront
}) => {

    const [open, setOpen] = useState<string|null>(null);

    const openAccount = () => {
        if (storefront.provider === 'stripe') window.open('https://dashboard.stripe.com/dashboard', '_blank');
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="flex-start" textAlign="left" padding="24px 32px" borderRadius="6px" border="1px solid var(--border-subtle-01)" bgcolor="var(--layer-01)">
            
            <Box display="flex" alignItems="center" sx={{marginBottom: '12px'}}>
                <img src={StripeCard} alt="Stripe" style={{maxWidth: '35px'}} />
                <span className="heading-05" style={{marginLeft: '12px'}}>{t('pages.settings.storefront.header.title')}</span>
            </Box>

            <span className="body-02" style={{whiteSpace: 'pre-wrap'}}>{t('pages.settings.storefront.header.text')}</span>

            <Box display="flex" width="100%" sx={{marginTop: '32px'}}>

                <Link
                    label={t('components.links.transactionFees')}
                    icon={<ArrowRight />}
                    to=""
                    onClick={() => {}}
                    />
                <Box display="flex" flexGrow={1} />
                {storefront.provider && <Button
                    kind="danger-ghost"
                    size="small"
                    label={t('components.buttons.disconnectStripe')}
                    endIcon={<ConnectionSignalOff />}
                    onClick={() => setOpen('disconnect')}
                    />}
                    {open === 'disconnect' && <DisconnectModal
                        open={open === 'disconnect'}
                        onClose={() => setOpen(null)}
                        storefront={storefront}
                        />}
                <Button
                    size="small"
                    label={t('components.buttons.goToStripeAccount')}
                    endIcon={<ConnectionSignalOff />}
                    onClick={openAccount}
                    sx={{marginLeft: '16px'}}
                    />

            </Box>

        </Box>
    )
}

export default StripeProviderConnected;