import { z } from "zod";
import dayjs from "dayjs";
import { t } from "i18next";
import ruleset, { addIssue } from "@/_helpers/ruleset";
import IPaymentMethod from "@/interfaces/payment_methods/payment_method";


export default interface IModuleForm {
    payment_method: IPaymentMethod;
    module: string
}

export const moduleFormSchema = z.object({
    payment_method: ruleset.selectedPaymentMethod,
    module: ruleset.required
}).superRefine((data, ctx) => {
    if (data.payment_method && dayjs().isAfter(dayjs(`${data.payment_method.exp_year}-${data.payment_method.exp_month}-01`), "month")) addIssue(ctx, ['payment_method'], t('inputs.errors.paymentMethodExpired'));
});