import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Services and interfaces
import { selectHasTeam, selectIsOwner } from "@/store/team";
import { useGetUpcomingInvoiceQuery } from "@/repositories/invoice";
import { selectPaymentsCancelled, selectPaymentsEnabled } from "@/store/subscription";

// Styles
import { CircleDash, CircleFill } from "@carbon/icons-react";

// Components
import Link from "@/components/link";
import { Box, Card, Skeleton } from "@mui/material";
import InvoiceBreakdownModal from "./invoice_breakdown_modal";


const BillingHeader: FC = () => {

    const { t } = useTranslation();
    const hasTeam = useSelector(selectHasTeam);
    const isTeamOwner = useSelector(selectIsOwner);
    const paymentModuleEnabled = useSelector(selectPaymentsEnabled);
    const paymentsModuleCancelled = useSelector(selectPaymentsCancelled);
    const {data: invoice, isLoading} = useGetUpcomingInvoiceQuery(undefined);
    const [open, setOpen] = useState(false);
    const nextInvoiceDate = invoice ? new Date(invoice.date).toLocaleDateString() : '-';
    const invoiceTotal = invoice?.total ? `£${invoice?.total/100}` : '-';

    return (
        <>
            {/* Title */}
            <span className="heading-07-compact">{t('pages.settings.billing.paymentSummary')}</span>
            <Box height={8} />

            {/* Summary Card */}
            <Card elevation={0} sx={{padding: '24px 32px', border: 'solid 1px var(--border-subtle-01)', borderRadius: '6px'}}>

                <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr">

                    {/* Next Payment */}
                    <Box display="flex" flexDirection="column" alignItems="flex-start" rowGap="4px">

                        <span className="label-01">{t('pages.settings.billing.nextPayment')}</span>
                        {isLoading ? <Skeleton variant="text" width={90} height={20} /> : 
                            <span className="heading-07">{hasTeam && !isTeamOwner ? t('pages.settings.billing.managedByTeam') : nextInvoiceDate}</span>}

                    </Box>

                    {/* Next Invoice */}
                    <Box display="flex" flexDirection="column" alignItems="flex-start" rowGap="4px">
                        <span className="label-01">{t('pages.settings.billing.nextInvoice')}</span>
                        {isLoading ? <Skeleton variant="text" width={70} height={20} /> : 
                            <span className="heading-07">{invoiceTotal}</span>}
                        {isLoading ? <Skeleton variant="text" width={115} height={18} /> : 
                            <Link
                                size="small"
                                label={t('components.links.seeBreakdown')}
                                onClick={invoice ? () => setOpen(true) : undefined}
                                />}
                            {invoice && 
                                <InvoiceBreakdownModal open={open} onClose={() => setOpen(false)} invoice={invoice} />}
                    </Box>

                    {/* Clients */}
                    <Box display="flex" flexDirection="column" alignItems="flex-start" rowGap="4px">
                        <span className="label-01">{t('pages.settings.billing.clients')}</span>
                        {isLoading ? <Skeleton variant="text" width={30} height={20} /> : 
                            <span className="heading-07">{hasTeam && !isTeamOwner ? t('pages.settings.billing.managedByTeam') : invoice?.quantity}</span>}
                    </Box>

                    {/* Payments & Business */}
                    <Box display="flex" flexDirection="column" alignItems="flex-start" rowGap="4px">
                        <span className="label-01">{t('pages.settings.billing.paymentsEnabled')}</span>
                        {isLoading ? <Skeleton variant="text" width={30} height={20} /> : 
                            <Box display="flex" alignItems="center">
                                {paymentModuleEnabled ?
                                    <CircleFill style={{color: paymentsModuleCancelled ? 'var(--support-warning)' : 'var(--teal-60)'}} /> :
                                    <CircleDash />}
                                <Box width="4px" />
                                {paymentModuleEnabled ? 
                                    <span className="heading-07">{t(`general.${paymentsModuleCancelled ? 'cancelled' : 'on'}`)}</span> :
                                    <span className="body-02" style={{color: 'var(--text-placeholder)'}}>{t('general.off')}</span>}
                            </Box>}
                    </Box>

                </Box>
            </Card>
        </>
    )
}

export default BillingHeader;