import dayjs from "dayjs";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm, useFormContext } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";
import { usePhaseContext } from "@/contexts/phase_context";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { IPhaseNote } from "@/interfaces/phase_note/phase_note";
import { IPhaseNoteForm, noteSchema } from "@/interfaces/phase_note/phase_note_form";
import { useAddPhaseNoteMutation, useUpdatePhaseNoteMutation } from "@/repositories/phase_note";

// Components
import { Box } from "@mui/material";
import Modal from "@/components/modal/modal";
import TextInput from "@/components/text_input";
import DateInput from "@/components/date_input";


interface _NoteModalProps {
    open: boolean;
    onClose: () => void;
    phase_id: string;
    note?: IPhaseNote;
}

const NoteModal: FC<_NoteModalProps> = ({
    open,
    onClose,
    phase_id,
    note
}) => {

    const { id } = useParams();
    const { t } = useTranslation();
    const { phase } = usePhaseContext();
    const [addNote, { isLoading: isAdding }] = useAddPhaseNoteMutation();
    const [updateNote, { isLoading: isEditing }] = useUpdatePhaseNoteMutation();

    const date = (): string => {
        const start = dayjs(dayjs.utc(phase?.start ?? phase?.expected_start).format('YYYY-MM-DDTHH:mm:ss'));
        const end = dayjs(dayjs.utc(phase?.end ?? phase?.expected_end).format('YYYY-MM-DDTHH:mm:ss'));
        if (dayjs.utc().isBetween(start, end)) return dayjs.utc().startOf('day').toISOString();
        if (dayjs.utc().isBefore(start)) return start.toISOString();
        return end.toISOString();
    }

    const formMethods = useForm<IPhaseNoteForm>({
        resolver: zodResolver(noteSchema),
        mode: 'onBlur',
        defaultValues: {
            title: note?.title ?? '',
            content: note?.content ?? '',
            date: note?.date ?? date()
        }
    });

    const submitAddNote = (data: IPhaseNoteForm) => {
        addNote({client_id: Number(id), phase_id, data}).unwrap().then((n) => {
            showToast({
                type: 'success', 
                title: t('notifications.phaseNote.created.title'), 
                message: t('notifications.phaseNote.created.message', {name: n.title})
            });
            handleClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    const submitEditNote = (data: IPhaseNoteForm) => {
        if (!note) return;
        const req = {client_id: Number(id), phase_id, id: note.id, data: data}
        updateNote(req).unwrap().then((n) => {
            showToast({
                type: 'success', 
                title: t('notifications.phaseNote.updated.title'), 
                message: t('notifications.phaseNote.updated.message', {name: n.title})
            });
            handleClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    const handleClose = () => { 
        formMethods.reset(); 
        onClose(); 
    }

    return (
        <Modal 
            open={open} 
            onClose={formMethods.formState.isDirty ? undefined : handleClose}
            title={!note ? t('modals.addPhaseNote') : t('modals.editPhaseNote')}
            children={
                <FormProvider {...formMethods}>
                    <_NoteForm />
                </FormProvider>
            }
            action1={{
                label: t('components.buttons.save'), 
                disabled: !formMethods.formState.isDirty, 
                loading: isAdding || isEditing,
                onClick: formMethods.handleSubmit(note ? submitEditNote : submitAddNote)

            }}
            cancel={{onClick: handleClose, label: t('components.buttons.cancel')}}
        />
    )
}

export default NoteModal;


const _NoteForm: FC = () => {

    const { t } = useTranslation();
    const { phase } = usePhaseContext();
    const { control } = useFormContext<IPhaseNoteForm>();

    const availableDates = {
        start: dayjs(dayjs.utc(phase?.start ?? phase?.expected_start).format('YYYY-MM-DDTHH:mm:ss')), 
        end: dayjs(dayjs.utc(phase?.end ?? phase?.expected_end).format('YYYY-MM-DDTHH:mm:ss'))
    }

    return (
        <Box display="flex" flexDirection="column" minWidth="448px">

            <TextInput 
                name='title' 
                control={control} 
                maxLength={40}
                label={t('inputs.titles.noteTitle')}
            />

            <DateInput
                name='date'
                control={control} 
                label={t('inputs.titles.date')}
                availableDates={availableDates}
            />
            
            <TextInput 
                name='content' 
                control={control} 
                size='textarea'
                minRows={6}
            />

        </Box>
    )
}