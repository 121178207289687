import { FC } from "react";
import { Control, useController } from "react-hook-form";
import { FormControlLabel, FormControlLabelProps, Switch as MuiSwitch } from "@mui/material";


interface _ToggleProps {
    name: string;
    control: Control<any>;
    size?: 'small'|'medium';
    label?: string;
    labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
    tabIndex?: number;
    disabled?: boolean;
    onChange?: () => void;
    showCheckedTick?: boolean;
    sx?: FormControlLabelProps['sx'];
}

const Toggle: FC<_ToggleProps> = ({
    name,
    control,
    label,
    labelPlacement = 'end',
    tabIndex = -1,
    disabled = false,
    showCheckedTick = false,
    onChange,
    sx
}) => {

    const labelStyles = {
        display: 'flex',
        color: labelPlacement == 'top' ? 'var(--text-secondary)' : 'var(--text-primary)',
        marginLeft: labelPlacement == 'top' ? '0' : '8px',
        marginBottom: labelPlacement == 'top' ? '8px' : '0'
    }

    const { field } = useController({name, control});

    const handleChange = () => {
        field.onChange(!field.value);
        onChange?.();
    }
    
    return (
        <FormControlLabel
            name={name}
            checked={field.value}
            labelPlacement={labelPlacement}
            label={<span className={labelPlacement == 'top' ? 'label-text-02' : 'body-02-compact'} style={labelStyles}>{label}</span>}
            control={
                <MuiSwitch 
                    value={field.value}
                    onChange={handleChange}
                    onClick={(e) => e.stopPropagation()}
                    tabIndex={tabIndex}
                    disableRipple
                    disabled={disabled}
                    sx={showCheckedTick ? { '& .Mui-checked .MuiSwitch-thumb::before': {
                        content: '""',
                        position: 'absolute',
                        width: 14, height: 14,
                        top: 5, left: 5, zIndex: 100,
                        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="%2324a148"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>')`
                    }}: undefined}
                />
            } 
            sx={{...sx, marginLeft: 0, marginRight: 0}}
            />
    )

}

export default Toggle;