import { FC } from "react";
import { useTranslation } from "react-i18next";

// Comoponents
import Modal from "@/components/modal/modal";
import PaymentMethodForm from "./payment_method_form";

interface _AddPaymentModalProps {
    open: boolean;
    onClose: (() => void);
}

const AddPaymentModal: FC<_AddPaymentModalProps> = ({
    open,
    onClose
}) => {

    const { t } = useTranslation();

    return (
        <Modal
            open={open}
            onClose={undefined}
            title={t('modals.addPaymentMethod')}
            children={<PaymentMethodForm onClose={onClose} />}
            />
    )
}

export default AddPaymentModal;