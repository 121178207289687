import { z } from "zod";
import { t } from "i18next";
import ruleset, { addIssue } from "@/_helpers/ruleset";


export interface IRefundTransactionForm {
    refund: 'partial' | 'full';
    amount?: number|string;
}

export const refundTransactionFormSchema = (transaction_total: number, transaction_currency: string) => {

    return z.object({
        refund: ruleset.select,
        amount: ruleset.numberOptional,
    }).superRefine((data, context) => {
        if (!data.amount || data.amount === 0) 
            addIssue(context, ['amount'], t('inputs.errors.refundNotZero', {currency: `${transaction_currency}`}));
        if (data.refund === 'partial' && data.amount && data.amount > transaction_total) 
            addIssue(context, ['amount'], t('inputs.errors.refundGreater', {amount: `${transaction_currency}${transaction_total}`}));
    })

}
