import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

// Helpers
import { roundNumber } from "@/_helpers/number_functions";

// Interfaces
import { useGetClientQuery } from "@/repositories/client";
import { IClientActivity } from "@/interfaces/client/client_activity";
import { IActivityLog } from "@/interfaces/activity_log/activity_log";

// Components
import { Box } from "@mui/material";
import { ColDef } from "@/components/datatable";
import ComplianceBar from "@/pages/client/components/compliance_bar";

const useActivityDataTableHeaders = (): ColDef<IClientActivity>[] => {

    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { data: client } = useGetClientQuery(Number(id));
    const showSteps = client?.coach_settings.activity.steps;
    const showCardio = client?.coach_settings.activity.cardio;

    const totalCardio = (logs: IActivityLog[]): number => {
        const total = logs.reduce((acc, log) => acc + log.duration, 0);
        return roundNumber(total / 60, 0);
    }

    useEffect(() => {
        if (Object.values(client?.coach_settings.activity ?? {}).every(value => value === false)) navigate(`/clients/${id}/overview`);
    }, [id, client?.coach_settings.activity, navigate]);

    const headers: ColDef<IClientActivity>[] = [
        {
            field: 'date',
            headerName: t('components.dataTable.headers.date'),
            width: '10%',
            render: (row) => new Date(row.date).toLocaleDateString()
        },
        ...showSteps ? [{
            field: 'steps',
            headerName: t('components.dataTable.headers.steps'),
            width: '20%',
            render: (row: IClientActivity) => row.steps ? <_ActualTarget actual={row.steps} target={row.plans?.steps?.steps ?? null} metric="steps" /> : '-'
        },
        {
            field: 'steps_compliance',
            width: '20%',
            headerName: t('components.dataTable.headers.stepsCompliance'),
            render: (row: IClientActivity) => <ComplianceBar target={row.plans?.steps?.steps ?? null} actual={row.steps} />
        }] : [],
        ...showCardio ? [{
            field: 'cardio',
            headerName: t('components.dataTable.headers.cardio'),
            width: '20%',
            render: (row: IClientActivity) => row.activity.length > 0 ? <_Cardio logs={row.activity} target={row.plans?.cardio?.duration ?? null} /> : '-'
        },
        {
            field: 'cardio_compliance',
            width: '20%',
            headerName: t('components.dataTable.headers.cardioCompliance'),
            render: (row: IClientActivity) => <ComplianceBar target={roundNumber(row.plans?.cardio?.duration / 60, 0) ?? null} actual={totalCardio(row.activity)} />
        }] : [],
    ];

    return headers;
}

export default useActivityDataTableHeaders;

interface _CardioProps {
    logs: IActivityLog[];
    target: number|null;
}

const _Cardio: FC<_CardioProps> = ({logs, target}) => {
    
    const total = logs.reduce((acc, log) => acc + log.duration, 0);

    return <_ActualTarget actual={total} target={target ? Math.floor(target / 60) : target} metric="cardio" />
}

interface _ActualTargetProps {
    actual: number;
    target: number|null;
    metric: 'steps' | 'cardio';
}

const _ActualTarget: FC<_ActualTargetProps> = ({
    actual,
    target,
    metric
}) => {
    const { t } = useTranslation();

    return (
        <Box display="flex" minWidth="125px">
            <span>{`${metric === 'steps' ? actual : roundNumber(actual/60, 0)}`}</span>
            {target !== null && <span style={{marginLeft: '4px', color: 'var(--text-placeholder)'}}>{`/ ${target}`}</span>}
            <span style={{marginLeft: '4px', color: 'var(--text-placeholder)'}}>{metric === 'steps' ? t('metrics.stepsValue') : t('metrics.minutes')}</span>
        </Box>
    )
}