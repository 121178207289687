import { FC } from "react";
import { useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import IPhase from "@/interfaces/phase/phase";
import { ApiError } from "@/interfaces/api/error";
import { useDeletePhaseMutation } from "@/repositories/phase";

// Components
import Modal from "@/components/modal/modal";
import { TrashCan } from "@carbon/icons-react";


interface DeletePhaseModalProps {
    open: boolean;
    onClose: () => void;
    phase: IPhase;
}

const DeletePhaseModal: FC<DeletePhaseModalProps> = ({
    open,
    onClose,
    phase
}) => {

    const { id } = useParams();
    const { t } = useTranslation();
    const [deletePhase, { isLoading }] = useDeletePhaseMutation();

    const handleDelete = () => {
        const req = {client_id: Number(id), phase_id: phase.id}
        deletePhase(req).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.phase.deleted.title'), 
                message: t('notifications.phase.deleted.message', {name: phase.name})
            });
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Modal 
            open={open}
            onClose={onClose}
            title={t('modals.deletePhase.title', {name: phase.name})}
            text={<Trans i18nKey="modals.deletePhase.text" values={{ name: phase.name }} />}
            action1={{
                kind: 'danger',
                label: t('components.buttons.delete'),
                loading: isLoading,
                icon: <TrashCan />,
                onClick: handleDelete,
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '448px', background: 'var(--background)'}}}
        />
    )
}

export default DeletePhaseModal;