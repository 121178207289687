import { FC } from "react";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

// Services and interfaces
import { ITransferForm, prospectTransferSchema } from "@/interfaces/transfer/transfer_form";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import TransferForm from "./transfer_form";
import Modal from "@/components/modal/modal";


interface _TransferModalProps {
    open: boolean;
    onClose: () => void;
    rowId: string;
    isLoading: boolean;
    onSubmit: (data: ITransferForm) => void;
}

const TransferModal: FC<_TransferModalProps> = ({
    open,
    onClose,
    rowId,
    isLoading,
    onSubmit
}) => {
    const { t } = useTranslation();

    const formMethods = useForm<ITransferForm>({
        resolver: zodResolver(prospectTransferSchema),
        mode: 'onBlur',
        defaultValues: {
            id: Number(rowId),
            coach: null
        }
    });

    const handleClose = () => {
        formMethods.reset();
        onClose();
    }

    return (
        <Modal 
            open={open}
            onClose={formMethods.formState.isDirty ? () => {} : handleClose}
            title={t('modals.transfer.title', {model: t('modals.transfer.prospect'), count: 1} )}
            text={t('modals.transfer.text', {model: t('modals.transfer.prospect'), count: 1} )}
            children={
                <FormProvider {...formMethods}>
                    <TransferForm />
                </FormProvider>}
            action1={{
                kind: 'primary',
                label: t('components.buttons.transfer'),
                icon: <ArrowRight />,
                loading: isLoading,
                onClick: formMethods.handleSubmit(onSubmit)
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: handleClose
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '448px'}}}
            />
    )
}

export default TransferModal;