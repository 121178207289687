import { FC } from "react";
import { useTranslation } from "react-i18next";

// Styles
import { Save, ArrowRight } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal";
import { InlineNotification } from "@/components/notification/notification";


interface _ClientStatusModalProps {
    open: boolean;
    onClose: () => void;
    // Boolean to indicate if the client has a payment product pending or live
    hasPayment: boolean;
    isActive: boolean;
    isLoading: boolean;
    onUpdate: () => void;
}

const ClientStatusModal: FC<_ClientStatusModalProps> = ({
    open,
    onClose,
    hasPayment,
    isActive,
    isLoading,
    onUpdate
}) => {

    const { t } = useTranslation();

    return (
        <Modal 
            open={open}
            onClose={onClose}
            title={isActive ? t('modals.clientStatus.title_inactive') : t('modals.clientStatus.title_active')}
            text={isActive ? t('modals.clientStatus.text_inactive') : t('modals.clientStatus.title_active')}
            children={hasPayment && isActive && <InlineNotification
                type="warning"
                title={''}
                message={t('inlineNotifications.client.activeProduct.message')}
                />
            }
            action1={{
                kind: 'primary',
                label: isActive ? t(`components.buttons.${hasPayment ? 'manageSubscription' : 'setInactive'}`) : t('components.buttons.setActive'),
                icon: hasPayment ? <ArrowRight /> : <Save />,
                loading: isLoading,
                onClick: onUpdate
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '540px'}}}
            />
    )
}

export default ClientStatusModal;