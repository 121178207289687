import { t } from "i18next";
import { FC, useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Trans, useTranslation } from "react-i18next";
import { FormProvider, useForm, useFormContext } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";
import { capitalize } from "@/_helpers/text_functions";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { useGetUpcomingInvoiceQuery } from "@/repositories/invoice";
import IPaymentMethod from "@/interfaces/payment_methods/payment_method";
import { useGetPaymentMethodsQuery } from "@/repositories/payment_methods";
import { useEnableModuleMutation } from "@/repositories/subscription_module";
import IModuleForm, { moduleFormSchema } from "@/interfaces/storefront/module_form";

// Styes
import { ArrowRight } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal";
import { Box, Skeleton } from "@mui/material";
import SelectInput from "@/components/select_input";
import CardIcon, { CardKey } from "@/components/card_icon";


interface _BuyPaymentsModuleModalProps {
    open: boolean;
    onClose: () => void;
}

const BuyPaymentsModuleModal: FC<_BuyPaymentsModuleModalProps> = ({
    open,
    onClose
}) => {

    const { t } = useTranslation();
    const [enablePaymentsModule, { isLoading: isEnablingModule }] = useEnableModuleMutation();
    const { data: paymentMethods, isLoading: isLoadingPaymentMethods } = useGetPaymentMethodsQuery(undefined);

    const formMethods = useForm<IModuleForm>({
        resolver: zodResolver(moduleFormSchema),
        mode: 'onBlur',
        defaultValues: {
            payment_method: undefined,
            module: 'payments'
        }
    });

    const selectedCard = formMethods.watch('payment_method');

    useEffect(() => {
        if (paymentMethods && !selectedCard) {
            const card = paymentMethods.find((item) => item.default == true);
            if (card) {
                formMethods.setValue('payment_method', card);
            }
        }
    }, [paymentMethods, selectedCard])

    const handleClose = () => {
        formMethods.reset();
        onClose();
    }

    const submit = (data: IModuleForm) => {
        const payload = {
            payment_method_id: data.payment_method.id,
            module: data.module
        }
        enablePaymentsModule(payload).unwrap().then(() => {
            showToast({
                type: 'success',
                title: t('notifications.billing.paymentModuleEnabled.title'),
                message: t('notifications.billing.paymentModuleEnabled.message')
            });
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Modal  
            open={open}
            title={t('modals.purchaseAddOn')}
            children={
                <FormProvider {...formMethods}>
                    <PaymentsModuleForm
                        paymentMethods={paymentMethods ?? []}
                        isLoading={isLoadingPaymentMethods}
                        selectedCard={selectedCard}
                        />
                </FormProvider>
            }
            action1={{
                label: t('components.buttons.purchaseAddOn'),
                icon: <ArrowRight />,
                loading: isEnablingModule,
                onClick: formMethods.handleSubmit(submit),
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isEnablingModule,
                onClick: handleClose,
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '1000px', width: '100%', background: 'var(--background)', boxSizing: 'border-box'}}}
            />
    )
}

export default BuyPaymentsModuleModal;


interface _PaymentsModuleFormProps {
    paymentMethods: IPaymentMethod[];
    isLoading: boolean;
    selectedCard?: IPaymentMethod;
}

const PaymentsModuleForm: FC<_PaymentsModuleFormProps> = ({
    paymentMethods,
    isLoading,
    selectedCard,
}) => {

    const { control } = useFormContext();
    const { data: invoice } = useGetUpcomingInvoiceQuery(undefined);
    const nextBillingDate = invoice ? new Date(invoice.date).toLocaleDateString() : '-';

    return (
        <Box display="flex" flexDirection="column" rowGap="16px">

            <Box 
                display="flex"
                justifyContent="space-between"
                padding="24px 32px"
                border="solid 1px var(--teal-60)"
                borderRadius="6px"
                bgcolor="var(--layer-01)"
                >

                {/* Title and description */}
                <Box display="flex" flexDirection="column">
                    <span className="heading-04" style={{color: 'var(--teal-70)', marginBottom: '20px'}}>{t('pages.business.setup.title')}</span>
                    <Box display="flex" flexDirection="column" px="8px">
                        <span className="body-02">{t('pages.settings.billing.paymentsModule.productsText')}</span>
                        <span className="body-02">{t('pages.settings.billing.paymentsModule.paymentsText')}</span>
                        <span className="body-02" style={{marginBottom: '6px'}}>{t('pages.settings.billing.paymentsModule.performanceText')}</span>
                    </Box>

                </Box>
                
            </Box>

            <Box display="flex" alignItems="center" padding="16px 32px" borderRadius="6px" border="solid 1px var(--border-subtle-01)" bgcolor="var(--layer-01)">

                {isLoading || !selectedCard ?
                    <Skeleton variant="rounded" width="70px" height="48px" />:
                    <CardIcon type={selectedCard?.brand as CardKey} size="large" />}

                <Box width="32px" />

                <SelectInput
                    name="payment_method"
                    control={control}
                    label={t('components.select.titles.paymentMethod')}
                    items={paymentMethods}
                    renderLabel={(item) => `${capitalize(item.brand)} - ${t('billing.endsWith', {last4: item.last_four})}`}
                    returnObject
                    gutter="0"
                    fullWidth
                    itemsLoading={isLoading}
                    sx={{maxWidth: '300px', width: '100%'}}
                    />
            </Box>

            <span className="body-02-compact">{<Trans
                i18nKey="pages.settings.billing.paymentsModule.infoText"
                components={{ strong: <strong /> }}
                values={{ date: nextBillingDate}}
                />}</span>

        </Box>
    )
}