import { t } from "i18next";
import { ISelectOption } from "@/interfaces/components/select_option";


export const productTypeOptions: ISelectOption<string>[] = [
    { id: 1, label: t('components.select.options.productType.service'), value: 'service' },
    // { id: 2, label: t('components.select.options.productType.digital'), value: 'digital' },
    // { id: 3, label: t('components.select.options.productType.physical'), value: 'physical' },
];

export const productSubtypeOptions: ISelectOption<string>[] = [
    { id: 1, label: t('components.select.options.productSubtype.oneTime'), value: 'one_time' },
    { id: 2, label: t('components.select.options.productSubtype.recurring'), value: 'recurring' },
    { id: 3, label: t('components.select.options.productSubtype.staged'), value: 'staged' },
    // { id: 3, label: t('components.select.options.productType.physical'), value: 'physical' },
];

export const productEndBehaviourOptions: ISelectOption<string>[] = [
    { id: 1, label: t('components.select.options.productEndBehaviour.release'), value: 'release' },
    { id: 2, label: t('components.select.options.productEndBehaviour.cancel'), value: 'cancel' },
];

export const productIntervalOptions: ISelectOption<string>[] = [
    { id: 1, label: t('components.select.options.priceInterval.day'), value: 'day' },
    { id: 2, label: t('components.select.options.priceInterval.week'), value: 'week' },
    { id: 3, label: t('components.select.options.priceInterval.month'), value: 'month' },
    { id: 4, label: t('components.select.options.priceInterval.year'), value: 'year' },
];

export const currencyOptions: ISelectOption<string>[] = [
    { id: 1, label: 'GBP', value: 'gbp', symbol: '£' },
    { id: 2, label: 'USD', value: 'usd', symbol: '$' },
    { id: 3, label: 'EUR', value: 'eur', symbol: '€' },
    { id: 4, label: 'AED', value: 'aed', symbol: 'د.إ' },
    { id: 5, label: 'AUD', value: 'aud', symbol: 'A$' }
]