import _ from 'lodash';
import { Trans, useTranslation } from "react-i18next";
import { Control, useController } from "react-hook-form";
import { BaseSyntheticEvent, useEffect, useState } from "react";

// Styles
import { Error } from '@mui/icons-material';
import { Checkmark, ChevronDown, Close, Help } from "@carbon/icons-react";

// Components
import Tooltip from "./tooltip";
import IconButton from "@/components/icon_button";
import { 
  Select, 
  InputAdornment, 
  FormControl, 
  FormHelperText, 
  MenuItem, 
  ListItemIcon, 
  SelectChangeEvent, 
  Box, 
  CircularProgress, 
  SelectProps 
} from "@mui/material";


export interface ISelectItem {
  [key: string]: any;
}

interface SelectInputProps {
  name: string;
  control: Control<any>;
  size?: 'small' | 'medium' | 'large';
  style?: 'default' | 'inline';
  // Label for the select
  label?: string;
  // Takes the key of the tooltip from the i18n file
  tooltip?: string;
  // Placeholder text for the select
  placeHolder?: string;
  // Tab index for accessibility
  tabIndex?: number;
  // Allows the select to have an empty value (to reset to null for example)
  allowEmpty?: boolean;
  // Forces the select to take up the full width of the container
  fullWidth?: boolean;
  // Forces the select to have a minimum width of 160px
  minWidth?: boolean;
  // Override the default gutter of 12px
  gutter?: string;
  // If set, the error message will be hidden
  hideErrorMessage?: boolean;
  // If set, the select will be disabled
  disabled?: boolean;
  // Items to be shown in the dropdown
  items: ISelectItem[];
  // If set, the key of the item to be used as the value, e.g. "id"
  itemKey?: string;
  // If set, the value will be shown in the dropdown, e.g. "label (value)"
  itemLabel?: string;
  // If set, the value will be returned, e.g. "value"
  itemValue?: string;
  // Optional loader for items if they are derived from an API
  itemsLoading?: boolean;
  // Function to render the label
  renderLabel?: (item: ISelectItem) => string;
  // If set, the value will be returned as an object
  returnObject?: boolean;
  // Optional on change event
  onChange?: (value: any) => void;
  // Optional sx prop
  sx?: SelectProps['sx'];
}

const SelectInput: React.FC<SelectInputProps>  = ({
  name,
  control,
  size = 'small',
  style = 'default',
  label,
  tooltip,
  placeHolder,
  tabIndex = -1,
  allowEmpty = false,
  fullWidth = true,
  minWidth = true,
  gutter = '12px',
  hideErrorMessage = false,
  disabled = false,
  items,
  itemKey = 'id',
  itemLabel = 'label',
  itemValue = 'value',
  itemsLoading = false,
  renderLabel,
  returnObject = false,
  onChange,
}) => {

  const { t } = useTranslation();
  const { field, fieldState } = useController({name, control});
  const [value, setValue] = useState<string>('');
  const [open, setOpen] = useState(false);
  const valueInRange = items.some((i) => i[itemKey] == value);

  const classes = {
    'SelectInput': true,
    'SelectInput-inline': style == 'inline',
    'SelectInput-small': size == 'small',
    'SelectInput-medium': size == 'medium',
    'SelectInput-large': size == 'large',
  }

  const validClasses = Object.entries(classes)
      .filter(([,v]) => !!v)
      .map(([k,]) => k)
      .join(' ')

  const formStyles = {
    display: 'flex',
    flexDirection: style == 'default' ? 'column' : 'row',
    alignItems: style == 'default' ? 'start' : 'center',
    marginBottom: style == 'default' ? gutter : '0',
    width: fullWidth ? '100%' : 'auto',
  }

  const selectStyles = {
    width: fullWidth ? '100%' : 'auto',
    minWidth: minWidth ? '160px' : 'auto',
    textAlign: 'left',
    '& .MuiSelect-select': {
      paddingRight: '8px !important'
    },
  }

  const CustomArrowIcon: React.FC = (props) => (
    <ChevronDown {...props} />
  );

  const handleOpen = (e: BaseSyntheticEvent) => {
    if (e.target.tagName !== 'DIV' && e.target.tagName !== 'SPAN') return;
    setOpen(true);
  };

  useEffect(() => {
    if (field.value == null) {
      setValue('');
      return;
    }
    if (returnObject) {
      const item = items.find(item => item[itemKey] == field.value[itemKey]);
      setValue(item?.[itemKey] ?? '');
    } else if (field.value instanceof Object || field.value instanceof Array) {
      const item = items.find(item => _.isEqual(item[itemValue], field.value));
      setValue(item?.[itemKey] ?? '');
    } else {
      const item = items.find(item => item[itemValue] == field.value)
      setValue(item?.[itemKey]);
    }
  },[field.value, items, itemKey, itemValue, returnObject])

  const handleChange = (e: SelectChangeEvent<string>) => {
    const v = items.find(item => item[itemKey] == e.target.value) ?? null;
    field.onChange(returnObject ? v : v?.[itemValue]);
    onChange && onChange(returnObject ? v : v?.[itemValue]);
  }

  const handleDelete = () => {
    // e.stopPropagation();
    setValue('');
    field.onChange('');
  }

  return (
    // Width set to 100% to always fill what ever container it is in
    <FormControl sx={formStyles}>

      {/* Label and tooltip row */}
      {(label || tooltip) && <Box display="flex" width="100%" justifyContent="space-between" alignItems="center" 
        sx={{
          marginBottom: style == 'default' ? '6px' : '0',
          marginRight: style == 'default' ? '0' : '8px'
        }}>

        {/* Label */}
        {label && <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{label}</span>}

        {/* Tooltip */}
        {tooltip && <Tooltip kind="nav" size="medium" title={<Trans className="body-01" i18nKey={tooltip}></Trans>} placement="top">
            <Help />
        </Tooltip>}

      </Box>}
        
      {/* Select */}
      <Select
        className={validClasses}
        sx={selectStyles}
        {...field}
        // Checks if the value used exists in the items arrray (useful when parent can change items list)
        value={valueInRange ? value : ''}
        onChange={handleChange}
        open={open}
        onOpen={handleOpen}
        onClose={() => setOpen(false)}
        displayEmpty
        tabIndex={tabIndex}
        autoComplete="true"
        disabled={disabled}
        error={fieldState.invalid}
        IconComponent={CustomArrowIcon}
        renderValue={(selected) => {
          if (selected == '') {
            return <span style={{color: 'var(--text-secondary)'}}>{placeHolder ?? t('components.select.placeholder')}</span>;
          }
          const selectedItem = items.find(item => item[itemKey] == selected);
          if (!selectedItem) return '';
          if (!allowEmpty) return renderLabel?.(selectedItem) ?? selectedItem[itemLabel];
          return <Box display="flex" alignItems="center" gap={0.5} position="relative">
            {renderLabel?.(selectedItem) ?? selectedItem[itemLabel]}
            <Box width="100%" />
              <IconButton
                kind="ghost"
                size={size}
                sx={{padding: '0px', }}
                icon={<Close size="16px" />}
                disabled={disabled}
                onClick={handleDelete}
                />
              <Box 
                width="1px" height={`calc(100% - 18px)`} 
                sx={{position: 'absolute', right: '1px', backgroundColor: 'var(--border-strong-01)'}} 
                />
          </Box>
        }}
        endAdornment={
          <InputAdornment position="end">
            {fieldState.invalid && <IconButton
              kind="ghost"
              size={size}
              sx={{paddingRight: '20px', '&:hover': {backgroundColor: 'transparent !important'}}}
              icon={fieldState.invalid && <Error color="error" sx={{fontSize: '16px'}} />}
              onClick={() => setOpen(!open)}
              />}
          </InputAdornment>
        }
        MenuProps={{
          sx: {
            '& .MuiMenu-list': {
              maxHeight: '320px'
            }
          }
        }}
        >
        {allowEmpty && <MenuItem value='' sx={{height: '32px'}}>
          {value == '' && <Checkmark />}
        </MenuItem>}
        {itemsLoading && <MenuItem value=''>
            <Box display="flex" width="100%" alignItems="center" justifyContent="center">
              <CircularProgress size="16px" />
            </Box>
          </MenuItem>}
        {items.map((item) => (
          <MenuItem key={item[itemKey]} value={item[itemKey]}>
            <ListItemIcon>
              {value == item[itemKey] && <Checkmark />}
            </ListItemIcon>
            <span className="body-02">{renderLabel?.(item) ?? item[itemLabel]}</span>
          </MenuItem>
        ))}
      </Select>
      {/* Span inside form helper text due to inability for classes to take precedence */}
      {!hideErrorMessage && fieldState.error?.message && <FormHelperText sx={{color: 'var(--text-error)'}}>
        <span className="helper-text-02">{fieldState.error?.message}</span>
      </FormHelperText>}
    </FormControl>
  );
};

export default SelectInput;