import baseApi from '@/repositories/base_api';
import { IAPIResponse } from '@/interfaces/api/response';
import { ISubscription } from '@/interfaces/subscription/subscription';


export const subscriptionModuleApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        enableModule: build.mutation<ISubscription, {payment_method_id: number, module: string}>({
            query: (data) => ({ url: '/subscription/modules', method: 'post', data: data}),
            transformResponse: (response: IAPIResponse<ISubscription>) => response.data,
            invalidatesTags: ['Invoice.upcoming', 'Invoice.list']
        }),
        cancelModule: build.mutation<ISubscription, {module: string}>({
            query: (data) => ({ url: '/subscription/modules/cancel', method: 'patch', data }),
            transformResponse: (response: IAPIResponse<ISubscription>) => response.data,
            invalidatesTags: ['Invoice.upcoming']
        }),
        renewModule: build.mutation<ISubscription, {module: string}>({
            query: (data) => ({ url: '/subscription/modules/renew', method: 'patch', data }),
            transformResponse: (response: IAPIResponse<ISubscription>) => response.data,
            invalidatesTags: ['Invoice.upcoming']
        })
    }),
})

export const { 
    useEnableModuleMutation, 
    useCancelModuleMutation,
    useRenewModuleMutation
} = subscriptionModuleApi;

