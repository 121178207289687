import { FC } from "react";
import { t } from "i18next";
import { useFormContext } from "react-hook-form";

// Helpers
import { getCurrencySymbol } from "@/_helpers/currency_functions";

// Services and interfaces
import { ITransaction } from "@/interfaces/transaction/transaction";
import { ISelectOption } from "@/interfaces/components/select_option";
import { IRefundTransactionForm } from "@/interfaces/transaction/refund_transaction_form";

// Components
import { Box } from "@mui/material";
import SelectInput from "@/components/select_input";
import NumberInput from "@/components/number_input";


interface _RefundFormProps {
    transaction: ITransaction;
    onRefundChange: (value: 'full'|'partial') => void;
}

const RefundForm: FC<_RefundFormProps> = ({
    transaction,
    onRefundChange
}) => {

    const { control, watch } = useFormContext<IRefundTransactionForm>();

    const disableValue = watch('refund') === 'full';
    
    const refundOptions: ISelectOption<string>[] = [
        { id: 1, label: t('components.select.options.refund.full'), value: 'full' },
        { id: 2, label: t('components.select.options.refund.partial'), value: 'partial' },
    ];

    return (
        <Box display="flex" flexDirection="column">
            <SelectInput
                name="refund"
                control={control}  
                label={t('components.select.titles.refund')}
                items={refundOptions}
                onChange={onRefundChange}
                />
            <NumberInput
                name="amount"
                control={control}
                label={t('inputs.titles.amount')}
                prefixText={getCurrencySymbol(transaction.currency)}
                decimalPlaces={2}
                maxLength={transaction.amount.toString().length}
                disabled={disableValue}
                />
        </Box>
    )
}

export default RefundForm;