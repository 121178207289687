import { t } from "i18next";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";

// Services and interfaces
import { selectHasTeam, selectIsOwner } from "@/store/team";

// Components
import { Box } from "@mui/material";
import { TabProps } from "@/components/tabs";
import ProductPanel from "./products/product_panel";
import SecondaryToolbar from "@/components/secondary_toolbar";


const PaymentsPage: FC = () => {

    const location = useLocation();
    const hasTeam = useSelector(selectHasTeam);
    const isTeamOwner = useSelector(selectIsOwner);
    const [open, setOpen] = useState<string|null>(null);
    const isProductTab = location.pathname.includes('products');
    const showAddProduct = (isProductTab && !hasTeam) || (isProductTab && hasTeam && isTeamOwner)

    const tabs: TabProps[] = [
        {label: t('tabs.products'), link: '/business/payments/products', sx: {width: '90px'}},
        {label: t('tabs.transactions'), link: '/business/payments/transactions', sx: {width: '90px'}},
    ]

    return (
        <Box display="flex" flexDirection="column" height="calc(100vh - 40px)" sx={{overflow: 'hidden'}}>

            <SecondaryToolbar 
                title={t('pages.business.payments.title')}
                tabs={tabs}
                action1={showAddProduct ? {label: t('components.buttons.addProduct'), onClick: () => setOpen('product')} : undefined}
                />
                {open === 'product' && <ProductPanel
                    open={open === 'product'}
                    onClose={() => setOpen(null)}
                     />}

            <Outlet />
            
        </Box>
    );
}

export default PaymentsPage;