import { t } from "i18next";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { ITemplateAssignForm } from "@/interfaces/template/assign_form";
import { IActivityPlan } from "@/interfaces/activity_plan/activity_plan";
import { useAddActivityPlanMutation } from "@/repositories/activity_plan";
import { IActivityBuilder } from "@/interfaces/activity_plan/activity_builder";
import { useAssignActivityTemplateMutation, useGetActivityTemplatesQuery } from "@/repositories/activity_template";

// Styles
import { Add } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import Button from "@/components/button";
import AssignModal from "@/pages/client/components/assign_modal";
import ActivityModal from "@/components/activity_builder/activity_modal";


interface _NoPlansProps {
    message?: string;
    disabled?: boolean;
}

const NoPlans: FC<_NoPlansProps> = ({
    message = t('components.dataTable.noData.noPlansFound'),
    disabled = false
}) => {

    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = useState<string|null>(null);
    const [addActivity, { isLoading: isSaving }] = useAddActivityPlanMutation();
    const [assignActivityTemplate, { isLoading: isAssigning }] = useAssignActivityTemplateMutation();

    const { data: templates, isLoading: isTemplatesLoading } = useGetActivityTemplatesQuery();

    const handleAdd = (data: IActivityBuilder) => {
        if (!id) return;
        addActivity({id: Number(id), data: data as IActivityPlan}).unwrap().then((plan) => {
            showToast({
                type: 'success', 
                title: t('notifications.plan.created.title'), 
                message: t('notifications.plan.created.message', {name: plan.name})
            });
            setOpen(null);
            navigate(`/clients/${id}/activity/${plan.id}`)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const handleAssign = (data: ITemplateAssignForm) => {
        assignActivityTemplate(data).unwrap().then((plan) => {
            showToast({
                type: 'success', 
                title: t('notifications.template.assigned.title', { count: 1 }), 
            });
            setOpen(null);
            navigate(`/clients/${id}/activity/${plan.id}`)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%" width="100%" paddingBottom="24px">

            <Box height="32px" />
            <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{message}</span>
            <Box height="12px" />

            <Box display="flex"justifyContent="center" alignItems="center" columnGap="8px">
                <Button
                    kind="ghost"
                    size="small"
                    label={t('components.buttons.addPlan')}
                    endIcon={<Add />}
                    minWidth={false}
                    disabled={disabled}
                    onClick={() => setOpen('add')}
                    />
                    {open === 'add' && <ActivityModal 
                        open={open === 'add'}
                        onClose={() => setOpen(null)}
                        isLoading={isSaving}
                        onSave={handleAdd}
                        />}
                <Button
                    kind="ghost"
                    size="small"
                    label={t('components.buttons.assignTemplate')}
                    endIcon={<Add />}
                    minWidth={false}
                    disabled={disabled}
                    onClick={() => setOpen('assign')}
                    />
                    {open === 'assign' && <AssignModal 
                        open={open === 'assign'} 
                        onClose={() => setOpen(null)}
                        client_id={Number(id)}
                        templates={templates?.filter((t) => !t.draft) ?? []}
                        isLoading={isAssigning}
                        isTemplatesLoading={isTemplatesLoading}
                        onSave={handleAssign}
                        />}
            </Box>

        </Box>
    )
}

export default NoPlans;