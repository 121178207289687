import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { INutritionPlan } from "@/interfaces/nutrition/nutrition_plan";
import { useCopyNutritionPlanMutation, useDeleteNutritionPlanMutation } from "@/repositories/nutrition_plan";

// Components
import { Box } from "@mui/material";
import { Options } from "@/components/menu";
import OverflowMenu from "@/components/overflow_menu";
import CopyPlanModal from "@/pages/client/components/copy_modal";
import DeletePlanModal from "@/pages/client/components/delete_modal";
import useBulkActions from "@/components/datatable/use_bulk_actions";


interface PlanMenuProps {
    row: INutritionPlan;
}

const PlanMenu: FC<PlanMenuProps> = ({
    row
}) => {

    const { id } = useParams();
    const { t } = useTranslation();
    const { showBulkActions } = useBulkActions();
    const [open, setOpen] = useState<string|null>(null);
    const [copyPlan, { isLoading: isCopying }] = useCopyNutritionPlanMutation();
    const [deletePlan, { isLoading: isDeleting }] = useDeleteNutritionPlanMutation();

    const disableMenu: boolean = showBulkActions || row.readonly;
    const disableDelete: boolean = row.active;

    const options: Options[] = [
        {name: t('components.menuItems.copyPlan'), disabled: disableMenu, action: () => setOpen('copy')},
        {name: t('components.menuItems.copyToTemplate'), disabled: disableMenu, action: () => setOpen('template')},
    ]

    const handleCopy = (template: boolean) => {
        copyPlan({client_id: Number(id), plan_id: row.id, copy_to_template: template}).unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.plan.copied.title'), message: t('notifications.plan.copied.message', {name: row.name})});
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    const handleDelete = () => {
        deletePlan({client_id: Number(id), plan_id: row.id}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.plan.deleted.title', {count: 1}), 
                message: <Trans 
                    i18nKey="notifications.plan.deleted.message" 
                    values={{name: row.name, count: 1}}
                    components={{bold: <strong />}}
            />
            });
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Box display="flex" justifyContent="flex-end">
            <OverflowMenu 
                position={{horizontal: 'right', vertical: 'bottom'}}
                options={options}
                deleteLabel={t('components.menuItems.delete')}
                disableDelete={disableMenu || disableDelete}
                disabled={disableMenu}
                onDelete={() => setOpen('delete')}
                />
            {open === 'copy' && <CopyPlanModal
                open={open === 'copy'}
                onClose={() => setOpen(null)}
                name={row.name}
                isLoading={isCopying}
                onCopy={() => handleCopy(false)}
                />}
            {open === 'template' && <CopyPlanModal
                open={open === 'template'}
                onClose={() => setOpen(null)}
                name={row.name}
                isTemplate={true}
                isLoading={isCopying}
                onCopy={() => handleCopy(true)}
                />}
            {open === 'delete' && <DeletePlanModal
                open={open === 'delete'}
                onClose={() => setOpen(null)}
                name={row.name}
                isLoading={isDeleting}
                onDelete={handleDelete}
                />}
            
        </Box>
    )
}

export default PlanMenu;