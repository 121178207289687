import baseApi from '@/repositories/base_api';

// Interfaces
import IPhase from '@/interfaces/phase/phase';
import { IAPIResponse } from '@/interfaces/api/response';
import { IPhaseForm } from '@/interfaces/phase/phase_form';
import { IEndPhaseForm } from '@/interfaces/phase/end_phase_form';
import { IActivePhaseForm } from '@/interfaces/phase/active_phase_form';


export const phaseApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPhases: build.query<IPhase[], number>({
      query: (id) =>  ({ url: `clients/${id}/phases` }),
      transformResponse: (response: IAPIResponse<IPhase[]>) => response.data.sort((a, b) => a.expected_start.localeCompare(b.expected_start)),
      providesTags: ['Phase.overview']
    }),
    getPhase: build.query<IPhase, {client_id: number, phase_id: string}>({
      query: ({phase_id, client_id}) =>  ({ url: `clients/${client_id}/phases/${phase_id}` }),
      transformResponse: (response: IAPIResponse<IPhase>) => response.data,
      providesTags: (_, __, args) => [
        { type: 'Phase', id: args.client_id },
        { type: 'Phase', id: args.phase_id },
        { type: 'Phase', id: 'ALL' }
      ]
    }),
    addPhase: build.mutation<IPhase, {client_id: number, data: IPhaseForm}>({
      query: ({client_id, data}) => ({ url: `clients/${client_id}/phases`, method: 'post', data }),
      transformResponse: (response: IAPIResponse<IPhase>) => response.data,
      async onQueryStarted({ client_id }, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) =>
        dispatch(phaseApi.util.updateQueryData('getPhases', client_id, phases => {
          phases.push(response.data);
          phases.sort((a, b) => a.expected_start.localeCompare(b.expected_start));
        }))).catch(() => {});
      },
    }),
    updatePhase: build.mutation<IPhase, {client_id: number, phase_id: string, data: IPhaseForm}>({
      query: ({client_id, phase_id, data}) => ({ url: `clients/${client_id}/phases/${phase_id}`, method: 'patch', data }),
      transformResponse: (response: IAPIResponse<IPhase>) => response.data,
      async onQueryStarted({ client_id }, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) =>
        dispatch(phaseApi.util.updateQueryData('getPhases', client_id, phases => {
          const index = phases.findIndex((item) => item.id === response.data.id);
          phases.splice(index, 1, response.data);
          phases.sort((a, b) => a.expected_start.localeCompare(b.expected_start));
        }))).catch(() => {});
      },
      invalidatesTags: (_,__,args) => [{ type: 'Phase', id: args.phase_id }]
    }),
    deletePhase: build.mutation<IPhase, {client_id: number, phase_id: string}>({
      query: ({client_id, phase_id}) => ({ url: `clients/${client_id}/phases/${phase_id}`, method: 'delete' }),
      transformResponse: (response: IAPIResponse<IPhase>) => response.data,
      async onQueryStarted({ client_id, phase_id }, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() =>
        dispatch(phaseApi.util.updateQueryData('getPhases', client_id, phases => {
          return phases.filter((item) => item.id !== phase_id)
        }))).catch(() => {});
      },
    }),
    activePhase: build.mutation<IPhase[], {client_id: number, phase_id: string, data: IActivePhaseForm}>({
      query: ({client_id, phase_id, data}) => ({ url: `clients/${client_id}/phases/${phase_id}/active`, method: 'patch', data: data }),
      transformResponse: (response: IAPIResponse<IPhase[]>) => response.data,
      invalidatesTags: (_,__,args) => [{ type: 'Phase', id: args.phase_id }, {type: 'Phase.overview'}],
    }),
    endPhase: build.mutation<IPhase, {client_id: number, phase_id: string, data: IEndPhaseForm}>({
      query: ({client_id, phase_id, data}) => ({ url: `clients/${client_id}/phases/${phase_id}/end${data.reset ? '?reset=true' : ''}`, method: 'patch', data: data }),
      transformResponse: (response: IAPIResponse<IPhase>) => response.data,
      async onQueryStarted({ client_id }, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) =>
        dispatch(phaseApi.util.updateQueryData('getPhases', client_id, phases => {
          const index = phases.findIndex((item) => item.id === response.data.id);
          phases.splice(index, 1, response.data);
          phases.sort((a, b) => a.expected_start.localeCompare(b.expected_start));
        }))).catch(() => {});
      },
    }),
  }),
})

export const { 
  useGetPhasesQuery, 
  useGetPhaseQuery, 
  useAddPhaseMutation, 
  useUpdatePhaseMutation, 
  useDeletePhaseMutation, 
  useActivePhaseMutation,
  useEndPhaseMutation
} = phaseApi