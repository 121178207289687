import { FC } from "react";
import { t } from "i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";
import { getCurrencySymbol } from "@/_helpers/currency_functions";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { useRefundTransactionMutation } from "@/repositories/transaction";
import { ITransaction, ITransactionQueryParams } from "@/interfaces/transaction/transaction";
import { IRefundTransactionForm, refundTransactionFormSchema } from "@/interfaces/transaction/refund_transaction_form";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import RefundForm from "./refund_form";
import Modal from "@/components/modal/modal";


interface _RefundModalProps {
    open: boolean;
    onClose: () => void;
    transaction: ITransaction;
    params?: ITransactionQueryParams
}

const RefundModal: FC<_RefundModalProps> = ({
    open,
    onClose,
    transaction,
    params
}) => {

    const hasRefunds = transaction.refunds.length > 0;
    const refundTotal = transaction.refunds.reduce((acc, refund) => acc + refund.amount, 0);
    const refundLimit = transaction.amount - refundTotal;
    const [refundTransaction, { isLoading: isRefunding }] = useRefundTransactionMutation();

    const formMethods = useForm<IRefundTransactionForm>({
        resolver: zodResolver(refundTransactionFormSchema(refundLimit, getCurrencySymbol(transaction.currency))),
        mode: 'onBlur',
        defaultValues: {
            refund: 'full',
            amount: refundLimit
        }
    });

    const handleFullRefund = (value: 'full'|'partial') => {
        if (value === 'full') {
            formMethods.setValue('amount', refundLimit);
            formMethods.clearErrors('amount');
        }
    }

    const handleRefundTransaction = (data: IRefundTransactionForm) => {
        refundTransaction({id: transaction.id, data, params}).unwrap().then(() => {
            showToast({
                type: 'success',
                title: t('notifications.transaction.refunded.title'),
                message: t('notifications.transaction.refunded.message', {
                    context: `${data.refund == 'full'}`,
                    currency: getCurrencySymbol(transaction.currency),
                    amount: data.amount
                })
            });
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Modal 
            open={open}
            onClose={formMethods.formState.isDirty ? undefined : onClose}
            title={t('modals.refundTransaction.title')}
            text={t('modals.refundTransaction.text', {
                context: `${hasRefunds}`,
                currency: getCurrencySymbol(transaction.currency),
                amount: refundTotal,
                balance: refundLimit
            })}
            children={
                <FormProvider {...formMethods}>
                    <RefundForm transaction={transaction} onRefundChange={handleFullRefund} />
                </FormProvider>
            }
            action1={{
                label: t('components.buttons.refund'),
                icon: <ArrowRight />,
                loading: isRefunding,
                onClick: formMethods.handleSubmit(handleRefundTransaction)
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isRefunding,
                onClick: onClose
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '480px', background: 'var(--background)'}}}
            />
    )
}

export default RefundModal;