import { FC } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Services and interfaces
import { selectHasTeam } from "@/store/team";
import { useGetReferralsQuery } from "@/repositories/referral";

// Components
import DataTable from "@/components/datatable";
import { Box, Container } from "@mui/material";
import ReferralHeader from "./referrals/header";
import ReferralStatsHeader from "./referrals/stats_header";
import useReferralsTableHeaders from "./referrals/table_headers";


const ReferralPage: FC = () => {
    
    const { t } = useTranslation();
    const hasTeam = useSelector(selectHasTeam);
    const headers = useReferralsTableHeaders();
    const { data: referrals, isLoading } = useGetReferralsQuery();

    return (
        <Container sx={{py: '24px'}}>

            {/* Flexible space */}
            {/* <Box sx={{height: 'clamp(30px, 10vh, 70px)'}} /> */}

            <Box display="flex" flexDirection="column" justifyContent="flex-start" rowGap="24px" paddingBottom="40px">

                <ReferralHeader />

                <ReferralStatsHeader />

                <DataTable
                    data={referrals}
                    columns={headers}
                    header={{
                        title: t(`components.dataTable.titles.${hasTeam ? 'teamReferrals' : 'yourReferrals'}`),
                    }}
                    hideFilter
                    isLoading={isLoading}
                    noDataMessage={t('components.dataTable.noData.noReferrals')}
                    />

            </Box>
        </Container>
    )
}

export default ReferralPage;