import { FC } from "react"
import { t } from "i18next";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import Button from "@/components/button";


interface _SuccessPageProps {
    email: string;
    invoice_url: string;
}

const SuccessPage: FC<_SuccessPageProps> = ({
    email,
    invoice_url
}) => {

    const handleViewInvoice = () => {
        window.open(invoice_url, '_blank');
    }

    return (
        <Box 
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            padding="24px"
            boxSizing="border-box"
            >

            <Box 
                display="flex" flexDirection="column" 
                alignItems="flex-start" 
                maxWidth="540px" 
                width="100%" height="100%"
                rowGap="24px"
                textAlign="left"
                >

                {/* Flexible space */}
                <Box sx={{height: '75px', '@media (max-width: 768px)': {height: '0px'}}} />

                <span className="heading-04">{t('pages.checkout.success.title')}</span>
                <span className="body-02" style={{whiteSpace: 'pre-wrap'}}>{t('pages.checkout.success.text', {email})}</span>

                <Button
                    label={t('components.buttons.viewInvoice')}
                    endIcon={<ArrowRight />}
                    onClick={handleViewInvoice}
                    sx={{
                        my: '24px',
                        '@media (max-width: 768px)': {width: '100%'}
                    }}
                    />

            </Box>

        </Box>
    )
}

export default SuccessPage;