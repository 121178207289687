import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import IClient from "@/interfaces/client/client";
import { ApiError } from "@/interfaces/api/error";
import { useDeleteClientMutation } from "@/repositories/client";
import { ITransferForm } from "@/interfaces/transfer/transfer_form";
import { selectHasTeam  , selectIsMember, selectMembers } from "@/store/team";
import { useTransferClientMutation, useUpdateClientStatusMutation } from "@/repositories/client";

// Components
import { Box } from "@mui/material";
import { Options } from "@/components/menu";
import TransferModal from "./transfer_modal";
import DeleteClientModal from "./delete_modal";
import OverflowMenu from "@/components/overflow_menu";
import useBulkActions from "@/components/datatable/use_bulk_actions";
import ClientStatusModal from "@/pages/client/components/status_modal";


interface _ClientMenuProps {
    row: IClient;
}

const ClientMenu: FC<_ClientMenuProps> = ({
    row,
}) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const hasTeam = useSelector(selectHasTeam);
    const members = useSelector(selectMembers);
    const isMember = useSelector(selectIsMember);
    const { showBulkActions } = useBulkActions();
    const [open, setOpen] = useState<string|null>(null);
    const status = location.pathname.includes('inactive') ? 'setActive' : 'setInactive';

    const [updateStatus, { isLoading: isSettingStatus }] = useUpdateClientStatusMutation();
    const [transferClient, { isLoading: isTransferring }] = useTransferClientMutation();
    const [deleteClient, { isLoading: isDeleting }] = useDeleteClientMutation();

    if (open) {null}

    const options: Options[] = [
        {name: t('components.menuItems.edit'), disabled: false, action: () => navigate(`/clients/${row.id}/overview`)},
        {name: t(`components.menuItems.${status}`), disabled: row.readonly, action: () => setOpen('status')},
    ]

    if (hasTeam && !isMember && !options.some(option => option.name === t('components.menuItems.transfer'))) {
        // Add to index 2 in the array
        options.splice(2, 0, {name: t('components.menuItems.transfer'), disabled: false, action: () => setOpen('transfer')})
    }

    const handleGoToClient = () => {
        navigate(`/clients/${row.id}/overview?subscription=open`);
    }

    const handleUpdateStatus = () => {
        updateStatus(row.id).unwrap().then((c) => {
            showToast({
                type: 'success', 
                title: t(`notifications.client.${c.active ? 'setActive' : 'setInactive'}.title`, {name: c.full_name})
            });
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const handleTransfer = (data: ITransferForm) => {
        const name = members.find(m => m.id === data.coach)?.name;
        transferClient(data).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.client.transferred.title', {count: 1, coach: name}),
            });
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const handleDelete = () => {
        deleteClient(row.id).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.client.deleted.title', {count: 1}), 
                message: <Trans 
                    i18nKey="notifications.client.deleted.message" 
                    values={{name: row.full_name}}
                    components={{bold: <strong />}}
                />});
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Box display="flex" justifyContent="flex-end">
            <OverflowMenu 
                position={{horizontal: 'right', vertical: 'bottom'}}
                options={options}
                deleteLabel={t('components.menuItems.delete')}
                disableDelete={row.readonly}
                disabled={showBulkActions}
                onDelete={row.active ? undefined : () => setOpen('delete')}
                />
            {open === 'status' && <ClientStatusModal 
                open={open === 'status'} 
                onClose={() => setOpen(null)}
                hasPayment={!!row.product}
                isActive={row.active}
                isLoading={isSettingStatus}
                onUpdate={row.product && row.active ? handleGoToClient : handleUpdateStatus}
                />}
            {open === 'transfer' && <TransferModal
                open={open === 'transfer'}
                onClose={() => setOpen(null)}
                id={row.id}
                isLoading={isTransferring}
                onSubmit={handleTransfer}
                />}
            {open === 'delete' && <DeleteClientModal
                open={open === 'delete'}
                onClose={() => setOpen(null)}
                name={`${row.full_name}`}
                isLoading={isDeleting}
                onDelete={handleDelete}
                />}
            
        </Box>
    )
}

export default ClientMenu;