import { HexColorPicker } from "react-colorful";
import { Control, useController } from "react-hook-form";
import { BaseSyntheticEvent, FC, useState } from "react";

// Constants
import { emojiRegex } from "@/_constants/text";

// Components
import { 
  TextField, 
  Box, 
  TextFieldProps, 
  Skeleton, 
  Popper, 
  ClickAwayListener 
} from "@mui/material";


interface ColourPickerProps {
  name: string;
  control: Control<any>;
  size?: 'small' | 'medium' | 'large';
  label?: string;
  placeHolder?: string;
  helperText?: string;
  tabIndex?: number;
  gutter?: string;
  isLoading?: boolean;
  hideErrorMessage?: boolean;
  disabled?: boolean;
  sx?: TextFieldProps['sx'];
}

const ColourPicker: FC<ColourPickerProps>  = ({
  name,
  control,
  size = 'small',
  label,
  placeHolder,
  helperText,
  tabIndex = -1,
  gutter = '12px',
  isLoading = false,
  hideErrorMessage = false,
  disabled = false,
  sx
}) => {

  const { field, fieldState } = useController({name, control});
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const skeletonClasses = {
    'Skeleton__field-small': size == 'small',
    'Skeleton__field-medium': size == 'medium',
    'Skeleton__field-large': size == 'large',
  }

  const colourClasses = {
    'ColourPicker__colourBox': true,
    'ColourPicker__colourBox-small': size == 'small',
    'ColourPicker__colourBox-medium': size == 'medium',
    'ColourPicker__colourBox-large': size == 'large',
  }

  const classes = {
    'ColourPicker': true,
    'ColourPicker-small': size == 'small',
    'ColourPicker-medium': size == 'medium',
    'ColourPicker-large': size == 'large',
  };

  const validSkeletonClasses = Object.entries(skeletonClasses)
      .filter(([,v]) => !!v)
      .map(([k,]) => k)
      .join(' ')

  const validColourClasses = Object.entries(colourClasses)
      .filter(([,v]) => !!v)
      .map(([k,]) => k)
      .join(' ')

  const validClasses = Object.entries(classes)
      .filter(([,v]) => !!v)
      .map(([k,]) => k)
      .join(' ')
    
  const handleBoxClick = (event: React.MouseEvent<HTMLElement>) => {
    if (disabled) return;
    setAnchorEl(event.currentTarget);
    setColorPickerOpen(true);
  };

  const handleColorChange = (color: string) => {
    field.onChange(color);
  };

  const handleClose = () => {
    setColorPickerOpen(false);
    setAnchorEl(null);
  };

  const handleBeforeInput = (event: BaseSyntheticEvent) => {
    const nativeEvent = event.nativeEvent as InputEvent;
    const inputValue = nativeEvent.data;

    if (inputValue && emojiRegex.test(inputValue)) {
      event.preventDefault();
    }
  };

  return (
    // Width set to 100% to always fill what ever container it is in
    <Box className='input__container' sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', marginBottom: gutter, width: '100%'}}>
      
      <Box display="flex" width="100%">
        {label && (isLoading ? <Skeleton variant="text" width="100" /> : 
          <span className="label-text-02" style={{marginBottom: '0.5em', color: 'var(--text-secondary)'}}>{label}</span>)}
      </Box>

      <Box display="flex" width="100%">
        {isLoading ? <Skeleton className={validColourClasses} variant="rounded" style={{marginRight: '6px'}} /> : <Box 
          className={validColourClasses} 
          marginRight="6px" 
          bgcolor={field.value || 'unset'}
          borderRadius="6px"
          border="solid 1px var(--border-strong-01)"
          onClick={handleBoxClick}
          />}
        {isLoading ? <Skeleton className={validSkeletonClasses} variant="rounded" width="100%" /> :
          <TextField
            ref={field.ref}
            className={validClasses}
            value={field.value || ''}
            onChange={(e) => field.onChange(e.target.value)}
            onBlur={field.onBlur}
            onBeforeInput={handleBeforeInput}
            placeholder={placeHolder}
            tabIndex={tabIndex}
            autoComplete="true"
            error={fieldState.invalid}
            helperText={helperText && !fieldState.error?.message ? helperText : hideErrorMessage ? null : fieldState.error?.message}
            disabled={disabled}
            sx={sx}
          />}
      </Box>
      <Popper
        open={colorPickerOpen}
        anchorEl={anchorEl}
        placement="bottom-start"
        disablePortal
        >
        <ClickAwayListener onClickAway={handleClose}>
          <Box
            sx={{
              padding: "8px",
              backgroundColor: "var(--layer-01)",
              borderRadius: "8px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
            >
            <HexColorPicker
              color={field.value || "#ff0000"}
              onChange={handleColorChange}
            />
          </Box>
        </ClickAwayListener>
      </Popper>

    </Box>
  );
};

export default ColourPicker;