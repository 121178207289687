import { FC } from "react";
import { t } from "i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { selectPaymentGatewayConfigured } from "@/repositories/storefront";
import { useCancelModuleMutation } from "@/repositories/subscription_module";

// Styes
import { ArrowRight } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal";
import { useSelector } from "react-redux";
import { useGetUpcomingInvoiceQuery } from "@/repositories/invoice";



interface _CancelPaymentsModalProps {
    open: boolean;
    onClose: () => void;
}

const CancelPaymentsModal: FC<_CancelPaymentsModalProps> = ({
    open,
    onClose
}) => {

    const { data: invoice } = useGetUpcomingInvoiceQuery();
    const [cancelModule, { isLoading }] = useCancelModuleMutation();
    const nextInvoiceDate = invoice ? new Date(invoice.date).toLocaleDateString() : '-';
    const paymentsConfigured = useSelector((state) => selectPaymentGatewayConfigured(state, undefined));

    const submit = () => {
        const payload = {module: 'payments'};
        cancelModule(payload).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.billing.paymentModuleRemoved.title'),
                message: t('notifications.billing.paymentModuleRemoved.message', {date: nextInvoiceDate})
            });
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            showClose={!!paymentsConfigured}
            title={t('modals.cancelPaymentsAddon.title')}
            text={t('modals.cancelPaymentsAddon.text', {context: `${!paymentsConfigured}`})}
            action1={paymentsConfigured ? undefined :{
                kind: "danger-ghost",
                label: t('components.buttons.confirmCancelPaymentsAddon'),
                icon: <ArrowRight />,
                onClick: () => submit(),
                loading: isLoading,
            }}

            cancel={paymentsConfigured ? undefined :{
                label: t('components.buttons.keepPaymentsAddon'),
                onClick: onClose,
                disabled: isLoading
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '608px', background: 'var(--background)'}}}
            />
    )
}

export default CancelPaymentsModal;