import { FC } from "react";
import { useSelector } from "react-redux";

// Services and interfaces
import { selectPaymentsEnabled } from "@/store/subscription";

// Components
import BillingTable from "./billing/table";
import BillingHeader from "./billing/header";
import ActionBar from "./billing/action_bar";
import { Box, Container } from "@mui/material";
import PaymentMethods from "./billing/payment_methods";
import ErrorNotification from "./billing/error_notification";
import PaymentsModuleHeader from "./billing/payments_module_header";


const BillingPage: FC = () => {

    const paymentModuleEnabled = useSelector(selectPaymentsEnabled);

    return (
        <Container>
            <Box display="flex" flexDirection="column" justifyContent="flex-start" textAlign="left">

                {/* Flexible space */}
                <Box sx={{height: 'clamp(30px, 10vh, 70px)'}} />

                <ErrorNotification />

                {!paymentModuleEnabled && <PaymentsModuleHeader />}
                
                <BillingHeader />

                {/* Spacer */}
                <Box height="40px" />

                <PaymentMethods />

                {/* Spacer */}
                <Box height="4px" />

                <ActionBar />

                {/* Spacer */}
                <Box height="40px" />

                <BillingTable />

                {/* Spacer */}
                <Box height="40px" />

            </Box>
        </Container>
    )
}

export default BillingPage;