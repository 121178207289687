import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Services and interfaces
import { selectPaymentsEnabled } from "@/store/subscription";

// Components
import { Box } from "@mui/material";
import PaymentsPreview from "./setup/payments_preview";


const BusinessSetupPage: FC = () => {

    const navigate = useNavigate();
    const paymentsModuleEnabled = useSelector(selectPaymentsEnabled)

    useEffect(() => {
        if (paymentsModuleEnabled) {
            navigate('/settings/1fitpay')
        }
    }, [paymentsModuleEnabled, navigate])

    return (
        <Box display="flex" flexDirection="column" sx={{padding: '40px', overflow: 'hidden'}}>

            <PaymentsPreview />

        </Box>
    );
}

export default BusinessSetupPage;