import { t } from "i18next";
import { IRadioItem } from "@/components/radio_group";
import { getOrdinalSuffix } from "@/_helpers/date_functions";
import { ISelectOption } from "@/interfaces/components/select_option";


export const clientStateBehaviourItems: IRadioItem[] = [
    {label: t('components.radioGroup.options.immediatelyCancel'), value: 'cancel'},
    {label: t('components.radioGroup.options.doNotCancel'), value: 'maintain'},
];

export const subscriptionEndBehaviourItems: IRadioItem[] = [
    {label: t('components.radioGroup.options.makeInactive'), value: 'inactive'},
    {label: t('components.radioGroup.options.keepActive'), value: 'active'},
];

export const payoutScheduleItems: IRadioItem[] = [
    {label: t('components.radioGroup.options.automatic'), value: 'automatic'},
    {label: t('components.radioGroup.options.manual'), value: 'manual'},
];

export const payoutFrequencyOptions: ISelectOption<string>[] = [
    {id: 1, label: t('components.select.options.payoutFrequency.daily'), value: 'daily'},
    {id: 2, label: t('components.select.options.payoutFrequency.weekly'), value: 'weekly'},
    {id: 3, label: t('components.select.options.payoutFrequency.monthly'), value: 'monthly'},
];

export const payoutWeekdayOptions: ISelectOption<string>[] = [
    {id: 1, label: t('timeDate.days.monday'), value: 'monday'},
    {id: 2, label: t('timeDate.days.tuesday'), value: 'tuesday'},
    {id: 3, label: t('timeDate.days.wednesday'), value: 'wednesday'},
    {id: 4, label: t('timeDate.days.thursday'), value: 'thursday'},
    {id: 5, label: t('timeDate.days.friday'), value: 'friday'},
    {id: 6, label: t('timeDate.days.saturday'), value: 'saturday'},
    {id: 7, label: t('timeDate.days.sunday'), value: 'sunday'},
];

export const payoutMonthDayOptions: ISelectOption<number>[] = Array.from({length: 31}, (_, i) => {
    return {id: i + 1, label: getOrdinalSuffix(i+1), value: i + 1};
});