import dayjs from "dayjs";
import { FC } from "react";
import { t } from "i18next";
import { Trans } from "react-i18next";

// Services and interfaces
import { RequirementStatus } from "@/interfaces/storefront/storefront";

// Components
import { InlineNotification } from "@/components/notification/notification"


interface _PersonalIdRequiredProps {
    status: RequirementStatus;
    current_deadline?: string;
}

const PersonalIdRequired: FC<_PersonalIdRequiredProps> = ({
    status,
    current_deadline
}) => {

    const hasAction = status !== 'pending_verification';
    const date = current_deadline && new Date(current_deadline).toLocaleDateString();
    const hasDatePassed = dayjs(date).isBefore(dayjs(), 'day');

    const getSeverity = () => {
        if (!status) return 'info';
        if (['eventually_due'].includes(status)) return 'info';
        if (['currently_due', 'alternative'].includes(status)) return 'warning';
        if (['past_due', 'error'].includes(status)) return 'error';
        return 'success';
    }

    const getTranslation = (): string => {
        if (['eventually_due'].includes(status)) return 'required';
        if (['currently_due', 'alternative'].includes(status)) return 'due';
        if (['past_due', 'error'].includes(status)) return 'overdue';
        if (status === 'pending_verification') return 'pending';
        return 'required';
    }

    const openAccount = () => {
        window.open('https://dashboard.stripe.com/dashboard', '_blank');
    }

    if (!status) return null;

    return (
        <InlineNotification
            type={getSeverity()}
            title={t(`inlineNotifications.1fitpay.personal_id.${getTranslation()}.title`)}
            message={<Trans
                i18nKey={`inlineNotifications.1fitpay.personal_id.${getTranslation()}.message`}
                values={{
                    context: `${hasDatePassed}`,
                    date: date
                }}
                components={{ strong: <strong /> }}
                />}
            action={hasAction ? {
                label: t('components.buttons.uploadDocuments'),
                onClick: openAccount
            } : undefined}
            />
    )
}

export default PersonalIdRequired;