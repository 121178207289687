import { t } from "i18next";
import { FC, useState } from "react";

// Services and interfaces
import { useGetTransactionsQuery } from "@/repositories/transaction";
import { ITransactionQueryParams } from "@/interfaces/transaction/transaction";
import IClient, { ICheckoutSession, IPaymentProduct } from "@/interfaces/client/client";

// Components
import Tag from "@/components/tag";
import { Box } from "@mui/material";
import Button from "@/components/button";
import DataTable from "@/components/datatable";
import CancelChangeModal from "./cancel_change_modal";
import RightPanel from "@/components/panel/right_panel";
import CancelCheckoutModal from "./cancel_checkout_modal";
import SubscriptionCancelModal from "./subscription_cancel_modal";
import SubscriptionChangeModal from "./subscription_change_modal";
import useTransactionTableHeaders from "./transaction_table_headers";


interface _SubscriptionPanelProps {
    open: boolean;
    onClose: () => void;
    client: IClient;
    product?: IPaymentProduct;
    checkout_session?: ICheckoutSession;
}

const SubscriptionPanel: FC<_SubscriptionPanelProps> = ({
    open,
    onClose,
    client,
    product,
    checkout_session
}) => {

    return (
        <RightPanel
            open={open}
            onClose={onClose}
            showClose
            title={t('rightPanel.clientSubscriptions.manage')}
            subtitle={client.full_name}
            children={
                <SubscriptionPanelContent client={client} product={product} checkout_session={checkout_session} />
            }
            panelWidth="50vw"
            />
    )
}

export default SubscriptionPanel;


interface SubscriptionPanelContentProps {
    client: IClient;
    product?: IPaymentProduct;
    checkout_session?: ICheckoutSession;
}

const SubscriptionPanelContent: FC<SubscriptionPanelContentProps> = ({
    client,
    product,
    checkout_session
}) => {

    const [open, setOpen] = useState<string|null>(null)
    const params: ITransactionQueryParams = { client_id: client.id };
    const headers = useTransactionTableHeaders({params});
    const hasImage = !!product?.product_image || !!checkout_session?.product_image;
    const { data: transactions, isLoading } = useGetTransactionsQuery(params);

    const image = checkout_session?.product_image ?? product?.product_image;
    const status = checkout_session?.status ?? product?.status;
    const isPending = checkout_session?.status === 'pending';
    const hasPendingChange = product?.status === 'scheduled' || product?.status === 'provisional';
    const canCancelChange = product?.status === 'provisional';

    const tagColor = () => {
        switch (status) {
            case 'active':
                return 'green';
            case 'incomplete':
                return 'grey';
            case 'past_due':
            case 'cancelled':
                return 'red';
            case 'pending':
            case 'scheduled':
            case 'provisional':
                return 'orange';
            default:
                return 'grey';
        }
    }

    return (
        <Box display="flex" flexDirection="column" rowGap="12px">
            <span className="heading-05">{t(`rightPanel.clientSubscriptions.${isPending ? 'pendingCheckout' : 'subscribedProduct'}`)}</span>

            {/* Product */}
            <Box display="flex" borderRadius="6px" border="solid 1px var(--border-subtle-01)" bgcolor="var(--layer-01)">
                {!hasImage && <img 
                    src={image ?? 'https://www.svgrepo.com/show/508699/landscape-placeholder.svg'} 
                    alt="product" 
                    style={{maxWidth: '230px', minWidth: '190px', borderTopLeftRadius: '6px', borderBottomLeftRadius: '6px'}} 
                    />}
                <Box display="flex" flexDirection="column" width="100%" p={'2%'} rowGap="8px">
                    <Box display="flex" justifyContent="space-between" marginBottom="8px">
                        <span className="heading-06-compact">{checkout_session?.product_name ?? product?.product_name}</span>
                        <Tag label={t(`enums.productSubscriptionStatus.${status}`)} colour={tagColor()} />
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                        <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{'Added Date'}</span>
                        {product ? <span className="body-02">{new Date(product.added_at).toLocaleDateString()}</span> :
                        checkout_session && <span className="body-02">{new Date(checkout_session.added_at).toLocaleDateString()}</span>}
                    </Box>
                    {/* //NOTE does this really have any value? */}
                    {/* <Box display="flex" justifyContent="space-between">
                        <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{'Paid Date'}</span>
                        <span className="body-02">{'13/12/2023'}</span>
                    </Box> */}
                    <Box display="flex" justifyContent="space-between">
                        <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{'Start Date'}</span>
                        <span className="body-02">{product?.starts_at ? new Date(product?.starts_at).toLocaleDateString() : '-'}</span>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                        <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{'End Date'}</span>
                        <span className="body-02">{product?.ends_at ? new Date(product?.ends_at).toLocaleDateString() : '-'}</span>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                        <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{'Next Payment'}</span>
                        <span className="body-02">{product?.ends_at || !product?.current_period_end ? '-' : new Date(product.current_period_end).toLocaleDateString()}</span>
                    </Box>
                </Box>
            </Box>

            {/* Actions */}
            <Box display="flex" justifyContent="flex-end">
                {!isPending && !hasPendingChange && <Button
                    kind="ghost"
                    size="small"
                    label={t('components.buttons.changeProduct')}
                    minWidth={false}
                    onClick={() => setOpen('change')}
                    />}
                    {open === 'change' && product && <SubscriptionChangeModal 
                        open={open === 'change'} 
                        onClose={() => setOpen(null)}
                        client_id={client.id}
                        product={product}
                        />}
                {!isPending && !canCancelChange && <Button
                    size="small"
                    kind="danger-ghost"
                    label={t('components.buttons.cancelProduct')}
                    minWidth={false}
                    onClick={() => setOpen('cancel')}
                    />}
                    {open === 'cancel' && product && <SubscriptionCancelModal 
                        open={open === 'cancel'} 
                        onClose={() => setOpen(null)}
                        client_id={client.id}
                        product={product}
                        />}
                {canCancelChange && <Button
                    size="small"
                    kind="danger-ghost"
                    label={t('components.buttons.cancelPendingChange')}
                    minWidth={false}
                    onClick={() => setOpen('cancel_change')}
                    />}
                    {open === 'cancel_change' && product && <CancelChangeModal 
                        open={open === 'cancel_change'} 
                        onClose={() => setOpen(null)}
                        client_id={client.id}
                        />}
                {isPending && <Button
                    size="small"
                    kind="danger-ghost"
                    label={t('components.buttons.cancelPendingCheckout')}
                    minWidth={false}
                    onClick={() => setOpen('cancel_checkout')}
                    />}
                    {open === 'cancel_checkout' && checkout_session && <CancelCheckoutModal 
                        open={open === 'cancel_checkout'} 
                        onClose={() => setOpen(null)}
                        client={client}
                        session_id={checkout_session.id}
                        />}
            </Box>

            {/* Transaction history */}
            <DataTable
                data={transactions}
                columns={headers}
                header={{
                    title: 'Transaction History',
                }}
                hideFilter
                minRows={5}
                initialPageLimit={5}
                disablePageLimit
                isLoading={isLoading}
                />


        </Box>
    )
}