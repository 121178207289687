import baseApi from '@/repositories/base_api';

// Interfaces
import { phaseApi } from './phase';
import { IAPIResponse } from '@/interfaces/api/response';
import { IPhaseMilestone } from '@/interfaces/phase_milestone/phase_milestone';
import { IPhaseMilestoneForm } from '@/interfaces/phase_milestone/phase_milestone_form';


export const phaseMilestoneApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    addPhaseMilestone: build.mutation<IPhaseMilestone, {client_id: number, phase_id: string, data: IPhaseMilestoneForm}>({
      query: ({client_id, phase_id, data}) => ({ url: `clients/${client_id}/phases/${phase_id}/milestones`, method: 'post', data }),
      transformResponse: (response: IAPIResponse<IPhaseMilestone>) => response.data,
      async onQueryStarted({ client_id, phase_id }, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) =>
        dispatch(phaseApi.util.updateQueryData('getPhase', { client_id, phase_id }, phase => {
          phase.milestones?.push(response.data);
          phase.milestones?.sort((a, b) => a.date.localeCompare(b.date));
        }))).catch(() => {});
      },
    }),
    updatePhaseMilestone: build.mutation<IPhaseMilestone, {client_id: number, phase_id: string, id: string, data: IPhaseMilestoneForm}>({
      query: ({client_id, phase_id, id, data}) => ({ url: `clients/${client_id}/phases/${phase_id}/milestones/${id}`, method: 'patch', data }),
      transformResponse: (response: IAPIResponse<IPhaseMilestone>) => response.data,
      async onQueryStarted({ client_id, phase_id }, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) =>
        dispatch(phaseApi.util.updateQueryData('getPhase', { client_id, phase_id }, phase => {
          const index = phase.milestones?.findIndex((item) => item.id === response.data.id);
          if (index == -1 || index == undefined) return;
          phase.milestones?.splice(index, 1, response.data);
        }))).catch(() => {});
      },
    }),
    deletePhaseMilestone: build.mutation<IPhaseMilestone, {client_id: number, phase_id: string, id: string}>({
      query: ({client_id, phase_id, id}) => ({ url: `clients/${client_id}/phases/${phase_id}/milestones/${id}`, method: 'delete' }),
      transformResponse: (response: IAPIResponse<IPhaseMilestone>) => response.data,
      async onQueryStarted({ client_id, phase_id, id }, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() =>
        dispatch(phaseApi.util.updateQueryData('getPhase', { client_id, phase_id }, phase => {
          phase.milestones = phase.milestones?.filter(milestone => milestone.id !== id);
        }))).catch(() => {});
      },
    }),
  }),
})

export const { 
  useAddPhaseMilestoneMutation,
  useUpdatePhaseMilestoneMutation,
  useDeletePhaseMilestoneMutation
} = phaseMilestoneApi