import { t } from "i18next";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FC, ReactNode, useState } from "react";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { useRenewModuleMutation } from "@/repositories/subscription_module";
import { selectPaymentsCancelled, selectPaymentsEnabled } from "@/store/subscription";

// Styes
import { Currency, UserIdentification } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import Button from "@/components/button";
import Modal from "@/components/modal/modal";
import CancelPaymentsModal from "./cancel_payments_modal";
import CancelSubscriptionModal from "./cancel_subscription_modal";


interface _ManageSubscriptionsModalProps {
    open: boolean;
    onClose: () => void;
}

const ManageSubscriptionsModal: FC<_ManageSubscriptionsModalProps> = ({
    open,
    onClose
}) => {

    const { t } = useTranslation();
    const paymentsModuleEnabled = useSelector(selectPaymentsEnabled);
    const paymentsModuleCancelled = useSelector(selectPaymentsCancelled);
    const [cancelOpen, setCancelOpen] = useState<string|null>(null);
    const [renewPaymentsModule, { isLoading: isRenewingPayments }] = useRenewModuleMutation();

    const handleClickPayments = () => {
        if (!paymentsModuleCancelled) return setCancelOpen('cancel_payments');
        renewPaymentsModule({module: 'payments'}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t(`notifications.billing.paymentModuleResumed.title`),
                message: t(`notifications.billing.paymentModuleResumed.message`)
            })
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            showClose
            title={t('modals.manageSubscriptions')}
            children={<Box display="flex" flexDirection="column" width="100%">
                <_MenuItem
                    icon={<UserIdentification />}
                    label={t('pages.settings.billing.manageSubscriptions.coachPlan')}
                    isDisabled={isRenewingPayments}
                    onClick={() => setCancelOpen('cancel_base')}
                    />
                    {cancelOpen === 'cancel_base' && <CancelSubscriptionModal 
                        open={cancelOpen === 'cancel_base'} 
                        onClose={() => setCancelOpen(null)}
                        />}
                {paymentsModuleEnabled && <_MenuItem
                    icon={<Currency />}
                    label={t('pages.settings.billing.manageSubscriptions.paymentsModule')}
                    resume={paymentsModuleCancelled}
                    isLoading={isRenewingPayments}
                    onClick={handleClickPayments}
                    />}
                    {cancelOpen === 'cancel_payments' && <CancelPaymentsModal 
                        open={cancelOpen === 'cancel_payments'} 
                        onClose={() => setCancelOpen(null)}
                        />}
            </Box>}
            sx={{'& .MuiPaper-root': {maxWidth: '596px', width: '100%', background: 'var(--background)'}}}
            />
    )
}

export default ManageSubscriptionsModal;


interface _MenuItemProps {
    icon: ReactNode;
    label: string;
    resume?: boolean;
    isLoading?: boolean;
    isDisabled?: boolean;
    onClick: () => void;
}
const _MenuItem: FC<_MenuItemProps> = ({
    icon,
    label,
    resume,
    isLoading,
    isDisabled,
    onClick
}) => {

    return (
        <Box display="flex" alignItems="center" padding="8px 16px" borderBottom="solid 1px var(--border-subtle-01)">
            {icon}
            <span className="body-02" style={{marginLeft: '16px'}}>{label}</span>
            <Box flexGrow={1} />
            <Button
                kind="danger-ghost"
                size="small"
                label={t(`components.buttons.${resume ? 'resumeSubscription' : 'cancelSubscription'}`)}
                loading={isLoading}
                disabled={isDisabled}
                onClick={onClick}
                />
        </Box>
    )
}