import { FC } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { FormProvider, useForm, useFormContext } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import IPhase from "@/interfaces/phase/phase";
import { ApiError } from "@/interfaces/api/error";
import { useActivePhaseMutation, useGetPhasesQuery } from "@/repositories/phase";
import { activePhaseSchema, IActivePhaseForm } from "@/interfaces/phase/active_phase_form";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal";
import DateInput from "@/components/date_input";
import { zodResolver } from "@hookform/resolvers/zod";


interface ActivePhaseModalProps {
    open: boolean;
    onClose: () => void;
    phase: IPhase;
}

const ActivePhaseModal: FC<ActivePhaseModalProps> = ({
    open,
    onClose,
    phase
}) => {
    
    const { id } = useParams();
    const { t } = useTranslation();
    const { data: phases } = useGetPhasesQuery(Number(id));
    const [setActivePhase, { isLoading }] = useActivePhaseMutation();

    const activePhase = phases?.find(p => p.start && !p.end);
    const lastCompletedPhase = phases?.filter(p => p.end).reverse()[0];

    const startDate = () => {
        if (activePhase) {
            const now = dayjs().startOf('day').format('YYYY-MM-DDTHH:mm:ss');
            const active = dayjs(activePhase.start).startOf('day').add(2, 'day').format('YYYY-MM-DDTHH:mm:ss');
            return dayjs(active).isAfter(dayjs(now)) ? active : now;
        }
        if (lastCompletedPhase) return dayjs(dayjs.utc(lastCompletedPhase?.end).format('YYYY-MM-DDTHH:mm:ss')).startOf('day').add(1, 'day').format('YYYY-MM-DDTHH:mm:ss');
        return dayjs().startOf('day').format('YYYY-MM-DDTHH:mm:ss');
    }

    const isFutureStart = dayjs(startDate()).isAfter(dayjs().add(1, 'day'));

    const formMethods = useForm<IActivePhaseForm>({
        resolver: zodResolver(activePhaseSchema),
        mode: 'onBlur',
        defaultValues: {
            start: startDate()
        }
    });

    const handleActive = (data: IActivePhaseForm) => {
        const req = { client_id: Number(id), phase_id: phase.id, data }
        setActivePhase(req).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.phase.active.title'), 
                message: t('notifications.phase.active.message', {name: phase.name})
            });
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    if (isFutureStart) {
        return (
            <Modal 
                open={open}
                onClose={onClose}
                title={t('modals.futurePhase.title')}
                text={t('modals.futurePhase.text')}
                showClose
                sx={{'& .MuiPaper-root': {maxWidth: '448px', background: 'var(--background)'}}}
            />
        )
    }

    return (
        <Modal 
            open={open}
            onClose={onClose}
            title={t('modals.activePhase.title')}
            text={<Trans i18nKey="modals.activePhase.text"/>}
            children={
                <FormProvider {...formMethods}>
                    <_ActiveForm activePhase={activePhase} lastCompletedPhase={lastCompletedPhase} />
                </FormProvider>
            }
            action1={{
                icon: <ArrowRight />,
                label: t('components.buttons.setActive'),
                loading: isLoading,
                onClick: formMethods.handleSubmit(handleActive),
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '448px', background: 'var(--background)'}}}
        />
    )
}

export default ActivePhaseModal;


interface _ActiveFormProps {
    activePhase?: IPhase;
    lastCompletedPhase?: IPhase;
}

const _ActiveForm: FC<_ActiveFormProps> = ({
    activePhase,
    lastCompletedPhase,
}) => {

    const { control } = useFormContext();
    const { t } = useTranslation();

    const startDate = (): Dayjs => {
        if (activePhase) return dayjs(activePhase.start).startOf('day').add(2, 'day');
        if (lastCompletedPhase) return dayjs(dayjs.utc(lastCompletedPhase?.end).format('YYYY-MM-DDTHH:mm:ss')).startOf('day').add(1, 'day')
        return dayjs('1970-01-01');
    }

    return (
        <DateInput
            name="start"
            label={t('inputs.titles.startDate')}
            control={control}
            availableDates={{
                start: startDate(),
                // end: startDate().isAfter(dayjs()) ? dayjs().add(1, 'day') : dayjs().add(1, 'day')
                end: dayjs().add(1, 'day')
            }}
            />
    )
}