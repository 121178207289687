import { FC } from "react";

// Styles
import { Close } from "@carbon/icons-react";

// Components
import Box from "@mui/material/Box";
import Button from "@/components/button";
import Drawer from "@mui/material/Drawer";
import { PanelProps } from "./right_panel";
import IconButton from "@/components/icon_button";


interface _DrawerProps extends PanelProps {
    panelWidth?: string;
    onCancel: () => void;
}

const PanelDrawer: FC<_DrawerProps> = ({
    title,
    subtitle,
    panelWidth = '40vw',
    open,
    showClose = false,
    keepMounted = false,
    onClose,
    onCancel,
    children,
    action1,
    action2,
    cancel,
}) => (

    <Drawer
        open={open}
        onClose={onClose}
        anchor='right'
        className="RightPanel"
        sx={{zIndex: 1300, overflow: 'hidden'}}
        ModalProps={{
            keepMounted: keepMounted
        }}
        PaperProps={{sx: {
            width: panelWidth,
            bgcolor: 'var(--background)',
            display: 'flex',
            flexDirection: 'column'
        }}}>

        {/* HEADER */}
        <Box display='flex' alignItems='center' py={2} px={3} bgcolor='var(--nav-background)'>
            <Box flexGrow={1}>
                {!!subtitle && <span className='label-text-02' style={{color: 'var(--nav-text-secondary)', paddingBottom: 1}}>
                    {subtitle}
                </span>}
                <div className='heading-05' style={{color: 'var(--nav-text-primary)'}}>{title}</div>
            </Box>
            {showClose && <IconButton 
                size='small'
                icon={<Close size={20} />}
                onClick={onClose} 
                />}
        </Box>

        {/* CHILDREN */}
        <Box flexGrow={1} py={2} px={3} sx={{overflowY: 'auto'}}>
            {children}
        </Box>

        {/* ACTIONS */}
        {(action1 || action2) && (
            <Box py={2} px={3} 
                borderTop='1px solid var(--border-subtle-01)' 
                display='flex' justifyContent='end'
                >
                {cancel && <Button
                    kind="tertiary"
                    size="medium"
                    label={cancel?.label}
                    endIcon={cancel?.icon}
                    disabled={cancel?.disabled}
                    loading={cancel?.loading}
                    onClick={onCancel}
                    sx={{mr: 3}}
                />}

                {action2 && <Button
                    kind="tertiary"
                    size="medium"
                    label={action2?.label}
                    endIcon={action2?.icon}
                    disabled={action2?.disabled}
                    loading={action2?.loading}
                    onClick={action2?.onClick}
                    sx={{mr: 3}}
                />}

                {action1 && <Button
                    size="medium"
                    label={action1?.label}
                    endIcon={action1?.icon}
                    disabled={action1?.disabled}
                    loading={action1?.loading}
                    onClick={action1?.onClick}
                />}
            </Box>
        )}

    </Drawer>
)

export default PanelDrawer;