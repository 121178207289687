import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { selectIsClientReadonly } from "@/repositories/client";
import { INutritionPlan } from "@/interfaces/nutrition/nutrition_plan";
import { useDeleteNutritionPlansMutation, useGetNutritionPlansQuery } from "@/repositories/nutrition_plan";

// Components
import NoPlans from "./no_plans";
import DataTable from "@/components/datatable";
import useNutritionPlanTableHeaders from "./plan_headers";
import useBulkActions from "@/components/datatable/use_bulk_actions";
import DeletePlanModal from "@/pages/client/components/delete_modal";


const NutritionPlansTab: FC = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const headers = useNutritionPlanTableHeaders();
    const [open, setOpen] = useState<string|null>(null);
    const readonly = useSelector((state) => selectIsClientReadonly(state, id!));
    const { selected, showBulkActions, handleSetSelected, handleSetShowBulkActions } = useBulkActions();

    const { data: list, isLoading } = useGetNutritionPlansQuery(Number(id));
    const [deletePlans, { isLoading: isDeletingMany }] = useDeleteNutritionPlansMutation();

    const handleOpenPlan = (plan: INutritionPlan) => {
        if (showBulkActions) return;
        navigate(`/clients/${id}/nutrition/${plan.id}`);
    }

    const handleDeleteMany = () => {
        deletePlans({id: Number(id), plans: selected}).unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.plan.deleted.title', {count: selected.length})});
            setOpen(null);
            handleSetShowBulkActions(false);
            handleSetSelected([]);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <>
            <DataTable
                data={list}
                dataKey="id"
                columns={headers}
                filter={{showSelect: !readonly}}
                filterKeys={["name"]}
                bulkActions={{
                    disableSelect: (plan: INutritionPlan) => plan.active,
                    action1: {
                        label: t('components.buttons.delete'), 
                        onClick: () => setOpen('deleteMany'),
                    },
                }}
                hideHeader
                localPagination
                noDataMessage={<NoPlans message={t('components.dataTable.noData.noPlans')} disabled={readonly} />}
                noDataFoundMessage={<NoPlans message={t('components.dataTable.noData.foundDefault')} disabled={readonly} />}
                isLoading={isLoading}
                rowClick={handleOpenPlan}
            />

            {open === 'deleteMany' && <DeletePlanModal
                open={open == 'deleteMany'}
                onClose={()=> setOpen(null)}
                name=''
                count={selected.length}
                isLoading={isDeletingMany}
                onDelete={handleDeleteMany}
            />}

        </>
    )
}

export default NutritionPlansTab