import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm, useWatch } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { selectHasTeam } from "@/store/team";
import { ApiError } from "@/interfaces/api/error";
import { useGetClientQuery } from "@/repositories/client";
import { IActivityBuilder } from "@/interfaces/activity_plan/activity_builder";
import { IActivityPlan, activityPlanSchema } from "@/interfaces/activity_plan/activity_plan";
import { useLazyGetActivityPlanQuery, useUpdateActivityPlanMutation } from "@/repositories/activity_plan";

// Styles
import { Save, Settings } from "@carbon/icons-react";

// Components
import { Box, Container } from "@mui/material";
import Steps from "@/components/activity_builder/steps";
import Cardio from "@/components/activity_builder/cardio";
import SecondaryToolbar from "@/components/secondary_toolbar";
import UnsavedChangesModal from "@/pages/common/unsaved_changes_modal";
import ActivityMenu from "@/components/activity_builder/activity_menu";
import ActivityModal from "@/components/activity_builder/activity_modal";



const EditActivityPlanPage: FC = () => {

    const { id, planId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const hasTeam = useSelector(selectHasTeam)
    const [open, setOpen] = useState<string|null>(null);
    const [selectedPlan, setSelectedPlan] = useState<string>('steps');
    
    const { data: client } = useGetClientQuery(Number(id));
    const [getPlan, { data: plan }] = useLazyGetActivityPlanQuery();
    const [savePlan, { isLoading: isSaving }] = useUpdateActivityPlanMutation();
    const [saveDraftPlan, { isLoading: isSavingDraft }] = useUpdateActivityPlanMutation();

    const disableSteps = client?.coach_settings.activity.steps === false;
    const disableCardio = client?.coach_settings.activity.cardio === false;

    const formMethods = useForm<IActivityPlan>({
        // resolver: zodResolver(activityPlanSchema(!disableSteps, !disableCardio)),
        resolver: zodResolver(activityPlanSchema(true, true)),
        mode: 'onBlur',
        defaultValues: {
            name: '',
            steps_notes: '',
            cardio_notes: '',
            steps: [],
            cardio: [],
            draft: true
        }
    });

    const name = useWatch({ control: formMethods.control, name: 'name' });

    useEffect(() => {
        if (id && planId) {
            getPlan({client_id: Number(id), plan_id: planId}).unwrap().then((p) => {
                formMethods.reset({
                    name: p.name,
                    steps_notes: p.steps_notes,
                    cardio_notes: p.cardio_notes,
                    steps: p.steps,
                    cardio: p.cardio,
                    draft: p.draft ?? false
                })
                setLoading(false);
            }).catch((error: ApiError) => {
                navigate(`/clients/${id}/activity/plans`);
                showToast({type: 'error', apiError: error.type})
            });
        }
    }, [id, formMethods, getPlan, navigate, hasTeam]);

    useEffect(() => {
        if (!loading && plan?.readonly) showToast({
            type: 'info',
            title: t('notifications.plan.readonly.title'),
            message: t('notifications.plan.readonly.message')
        });
    }, [loading]);

    const handleSelectPlan = (plan: 'steps' | 'cardio') => {
        setSelectedPlan(plan);
    }

    const handleUpdateSettings = (data: IActivityBuilder) => {
        formMethods.setValue('name', data.name, { shouldDirty: true });
        formMethods.setValue('steps_notes', data.steps_notes, { shouldDirty: true });
        formMethods.setValue('cardio_notes', data.cardio_notes, { shouldDirty: true });
        setOpen(null);
    }

    const handleBack = () => {
        if (formMethods.formState.isDirty) return setOpen('unsaved');
        navigate(`/clients/${id}/activity/plans`);
    }

    const handleSave = (data: IActivityPlan) => {
        if (!id || !planId) return;
        data.draft = false;
        savePlan({p: {client_id: Number(id), plan_id: planId}, data}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.plan.updated.title'), 
                message: t('notifications.plan.updated.message', {name: name})
            });
            navigate(`/clients/${id}/activity/plans`)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    const handleSaveDraft = () => {
        if (!id || !planId) return;
        const data = formMethods.getValues();
        data.draft = true;
        saveDraftPlan({p: {client_id: Number(id), plan_id: planId}, data}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.plan.updated.title'), 
                message: t('notifications.plan.updated.message', {name: name})
            });
            navigate(`/clients/${id}/activity/plans`)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Box display="flex" flexDirection="column" height="calc(100vh - 40px)" sx={{overflow: 'hidden'}}>

            <SecondaryToolbar
                title={name ?? t('components.activityBuilder.plan.untitledPlan')}
                isLoading={loading}
                onBack={handleBack}
                iconActions={([{kind: "ghost", icon: <Settings />, onClick: () => setOpen('settings')}])}
                action1={{
                    label: t('components.buttons.save'),
                    icon: <Save />,
                    loading: isSaving,
                    disabled: isSavingDraft || plan?.readonly,
                    onClick: formMethods.handleSubmit(handleSave),
                    sx: {minWidth: '115px'}
                }}
                action2={{
                    label: t('components.buttons.saveDraft'),
                    icon: <Save />,
                    loading: isSavingDraft,
                    disabled: isSaving || plan?.active || plan?.readonly,
                    onClick: handleSaveDraft,
                    sx: {minWidth: '115px'}
                }}
                sx={{borderBottom: 'none'}}
                />
            {open === 'settings' && <ActivityModal
                open={open === 'settings'}
                onClose={() => setOpen(null)}
                activity={formMethods.getValues()}
                onSave={handleUpdateSettings}
                />}
            {open === 'unsaved' && 
                <UnsavedChangesModal 
                    open={open === 'unsaved'} 
                    onClose={() => setOpen(null)}
                    onSubmit={() => navigate(`/clients/${id}/activity/plans`)}
                    />}

            <FormProvider {...formMethods}>
                <Container sx={{height: '100%',padding: '16px 24px 0 24px', overflow: 'hidden'}}>
                    <Box className="Builder">

                        <ActivityMenu 
                            section={selectedPlan}
                            onSelectPlan={handleSelectPlan}
                            disableSteps={disableSteps}
                            disableCardio={disableCardio}
                            />

                        {/* //NOTE - Left in until can be turned back on */}
                        {/* {selectedPlan == 'steps' && <Steps readonly={disableSteps || plan?.readonly} />}
                        {selectedPlan == 'cardio' && <Cardio readonly={disableCardio || plan?.readonly} />} */}

                        {selectedPlan == 'steps' && <Steps readonly={plan?.readonly} />}
                        {selectedPlan == 'cardio' && <Cardio readonly={plan?.readonly} />}
                        

                    </Box>
                </Container>
            </FormProvider>

        </Box>
    )
}

export default EditActivityPlanPage;