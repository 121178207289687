import baseApi from '@/repositories/base_api';
import { IPlanForm } from '@/interfaces/plan/plan';
import { IAPIResponse } from '@/interfaces/api/response';
import { ICopyPlanForm } from '@/interfaces/plan/copy_plan';
import { IActivityPlan } from '@/interfaces/activity_plan/activity_plan';


export const activityPlanApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getActivityPlans: build.query<IActivityPlan[], number>({
            query: (id) => ({ url: `clients/${id}/activity`, method: 'get' }),
            transformResponse: (response: IAPIResponse<IActivityPlan[]>) => response.data.sort((a,b) => Number(b.active)-Number(a.active)),
            providesTags: (result, error, id) => [
                { type: 'Plans.activity.list', id }, 
                { type: 'Plans.activity.list', id: 'ALL' }
            ],
            keepUnusedDataFor: 900
        }),
        getActivityPlan: build.query<IActivityPlan, IPlanForm>({
            query: (p) => ({ url: `/clients/${p.client_id}/activity/${p.plan_id}`, method: 'get' }),
            transformResponse: (response: IAPIResponse<IActivityPlan>) => response.data,
        }),
        addActivityPlan: build.mutation<IActivityPlan, {id: number, data: IActivityPlan}>({
            query: ({id, data}) => ({ url: `/clients/${id}/activity`, method: 'post', data }),
            transformResponse: (response: IAPIResponse<IActivityPlan>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(activityPlanApi.util.updateQueryData('getActivityPlans', _.id, plans => {
                    plans.push(response.data)
                    plans.sort((a, b) => a.name.localeCompare(b.name)).sort((a,b) => Number(b.active)-Number(a.active));
                }))).catch(() => {});
            },
        }),
        updateActivityPlan: build.mutation<IActivityPlan, {p: IPlanForm, data: IActivityPlan}>({
            query: ({p, data}) => ({ url: `/clients/${p.client_id}/activity/${p.plan_id}`, method: 'patch', data: data }),
            transformResponse: (response: IAPIResponse<IActivityPlan>) => response.data,
            async onQueryStarted({p}, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(activityPlanApi.util.updateQueryData('getActivityPlans', p.client_id, plans => {
                    const index = plans.findIndex((item) => item.id === response.data.id);
                    plans.splice(index, 1, response.data);
                }))).catch(() => {});
            },
        }),
        deleteActivityPlan: build.mutation<void, IPlanForm>({
            query: (p) => ({ url: `/clients/${p.client_id}/activity/${p.plan_id}`, method: 'delete' }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(p, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(activityPlanApi.util.updateQueryData('getActivityPlans', p.client_id, plans => {
                    return plans.filter((item) => item.id !== p.plan_id)
                }))).catch(() => {});
            },
        }),
        deleteActivityPlans: build.mutation<void, {id: number, plans: string[]}>({
            query: ({id, plans}) => ({ url: `/clients/${id}/activity/bulk-delete`, method: 'post', data: {ids: plans} }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(params, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(activityPlanApi.util.updateQueryData('getActivityPlans', params.id, plans => {
                    return plans.filter((item) => !params.plans.includes(item.id))
                }))).catch(() => {});
            },
        }),
        copyActivityPlan: build.mutation<IActivityPlan, ICopyPlanForm>({
            query: (p) => ({ url: `/clients/${p.client_id}/activity/${p.plan_id}/copy?template=${p.copy_to_template}`, method: 'post' }),
            transformResponse: (response: IAPIResponse<IActivityPlan>) => response.data,
            invalidatesTags: (result, _, arg) => {
                if (arg.copy_to_template) return ['Templates.activity.list'];
                return [];
            },
            async onQueryStarted({ client_id }, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(activityPlanApi.util.updateQueryData('getActivityPlans', client_id, plans => {
                    if (!response.data) return;
                    plans.push(response.data);
                    plans.sort((a, b) => a.name.localeCompare(b.name)).sort((a,b) => Number(b.active)-Number(a.active));
                }))).catch(() => {});
            },
        }),
        setActiveActivityPlan: build.mutation<IActivityPlan, {client_id: number, plan_id: string}>({
            query: ({client_id, plan_id}) => ({ url: `/clients/${client_id}/activity/${plan_id}/active`, method: 'patch' }),
            transformResponse: (response: IAPIResponse<IActivityPlan>) => response.data,
            async onQueryStarted({ client_id }, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(activityPlanApi.util.updateQueryData('getActivityPlans', client_id, plans => {
                    const index = plans.findIndex((item) => item.id === response.data.id);
                    if (index == -1) return plans;
                    plans.splice(index, 1, response.data);
                    plans.map((item) => {
                        if (item.id !== response.data.id) return item.active = false;
                        return item;
                    });
                }))).catch(() => {});
            },
        }),
    }),
})

export const { 
    useGetActivityPlansQuery,
    useGetActivityPlanQuery,
    useLazyGetActivityPlanQuery,
    useAddActivityPlanMutation,
    useUpdateActivityPlanMutation,
    useDeleteActivityPlanMutation,
    useDeleteActivityPlansMutation,
    useCopyActivityPlanMutation,
    useSetActiveActivityPlanMutation,
} = activityPlanApi;

