import { FC } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { IProduct } from "@/interfaces/product/product";
import { selectStorefrontCurrency } from "@/repositories/storefront";
import { IProductUpdateForm } from "@/interfaces/product/product_update_form";
import { IProductForm, productFormSchema } from "@/interfaces/product/product_form";
import { useAddProductMutation, useUpdateProductMutation } from "@/repositories/product";

// Components
import ProductForm from "./product_form";
import RightPanel from "@/components/panel/right_panel";


interface _ProductPanelProps {
    open: boolean;
    onClose: () => void;
    product?: IProduct;
}

const ProductPanel: FC<_ProductPanelProps> = ({
    open,
    onClose,
    product
}) => {

    const { t } = useTranslation();
    const readonly = !!product;
    const [addProduct, { isLoading: isAdding }] = useAddProductMutation();
    const [updateProduct, { isLoading: isUpdating }] = useUpdateProductMutation();
    const defaultCurrency = useSelector((state) => selectStorefrontCurrency(state, undefined));


    const formMethods = useForm<IProductForm|IProductUpdateForm>({
        resolver: zodResolver(productFormSchema),
        mode: 'onBlur',
        defaultValues: {
            name: product?.name ?? '',
            description: product?.description ?? '',
            type: product?.type ?? 'service',
            subtype: product?.subtype ?? 'recurring',
            currency: product?.currency ?? defaultCurrency ?? '',
            price: {
                unit_amount: product?.price.unit_amount ?? '',
                interval: product?.price.interval ?? '',
                interval_count: product?.price.interval_count ?? 1,
            },
            schedule: product?.schedule ?? {
                end_behaviour: 'release',
                phases: [
                    {
                        unit_amount: '',
                        interval: '',
                        interval_count: 1,
                        iterations: 1
                    }
                ]
            }
        }
    });

    console.log(formMethods.formState.errors);

    const handleClose = () => {
        formMethods.reset();
        onClose();
    }

    const submitAddProduct = (data: IProductForm) => {
        addProduct(data).unwrap().then((product) => {
            showToast({
                type: 'success', 
                title: t('notifications.product.created.title'), 
                message: t('notifications.product.created.message', {name: product.name})
            });
            handleClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const submitUpdateProduct = (data: IProductUpdateForm) => {
        if (!product) return;
        updateProduct({id: product.id, data: data}).unwrap().then((product) => {
            showToast({
                type: 'success', 
                title: t('notifications.product.updated.title'), 
                message: t('notifications.product.updated.message', {name: product.name})
            });
            handleClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }


    return (
        <RightPanel
            open={open}
            onClose={formMethods.formState.isDirty ? undefined : onClose}
            title={product ? t('rightPanel.product.editProduct') : t('rightPanel.product.addProduct')}
            children={
                <FormProvider {...formMethods}>
                    <ProductForm canEdit={!product?.readonly} readonly={readonly} />
                </FormProvider>
            }
            action1={{
                label: t('components.buttons.save'),
                disabled: !formMethods.formState.isDirty,
                loading: isAdding || isUpdating,
                onClick: formMethods.handleSubmit(product ? submitUpdateProduct : submitAddProduct),
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isAdding || isUpdating || product?.readonly,
                onClick: handleClose
            }}
            />
    )
}

export default ProductPanel;