import { t } from "i18next";
import { FC, useEffect, useState } from "react";

// Services and interfaces
import IPaymentMethod from "@/interfaces/payment_methods/payment_method";
import { useGetPaymentMethodsQuery } from "@/repositories/payment_methods";

// Styes
import { Add } from "@carbon/icons-react";

// Components
import Button from "@/components/button";
import PaymentMethod from "./payment_method";
import { Box, RadioGroup } from "@mui/material";
import AddPaymentModal from "./add_payment_modal";

interface _CardListProps {
    selectedCard: IPaymentMethod|undefined;
    onSelectCard: (paymentMethod: IPaymentMethod) => void;
}

const CardList: FC<_CardListProps> = ({
    selectedCard,
    onSelectCard
}) => {

    const { data: paymentMethods } = useGetPaymentMethodsQuery(undefined);
    const [openAddPaymentMethod, setOpenAddPaymentMethod] = useState<boolean>(false);

    useEffect(() => {
        if (paymentMethods && !selectedCard) {
            const card = paymentMethods.find((item) => item.default == true);
            if (card) onSelectCard(card);
        }
    }, [paymentMethods, selectedCard, onSelectCard])

    return (
        <Box display="flex" flexDirection="column" alignItems="center">

            <RadioGroup
                defaultValue="female"
                name="radio-buttons-group"
                >
                {paymentMethods?.map((item: IPaymentMethod) => (
                    <PaymentMethod 
                        key={item.id} 
                        paymentMethod={item} 
                        isSelected={selectedCard?.id === item.id}
                        onSelect={onSelectCard}
                        />
                ))}
            </RadioGroup>

            <Button
                kind="ghost"
                size="small"
                label={t('components.buttons.addPaymentMethod')}
                endIcon={<Add />}
                onClick={() => setOpenAddPaymentMethod(true)}
                minWidth={false}
                />
                {openAddPaymentMethod && <AddPaymentModal 
                    open={openAddPaymentMethod} 
                    onClose={() => setOpenAddPaymentMethod(false)} 
                    />}

        </Box>
    )
}

export default CardList;