import { useParams } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";

// Services and interfaces
import { useGetCheckoutQuery } from "@/repositories/checkout";
import ICheckoutProcessed from "@/interfaces/checkout/checkout_processed";

// Styles
import appearance from "@/_constants/stripe_appearance";

// Components
import SuccessPage from "./success";
import ProductPane from "./product_pane";
import MobileFooter from "./mobile_footer";
import SubscriptionForm from "./checkout_form";
import { Box, CircularProgress } from '@mui/material';


const CheckoutPage: FC = () => {

    const { id } = useParams();
    const [checkoutComplete, setCheckoutComplete] = useState<ICheckoutProcessed|null>(null);
    const { data: session, isLoading, isError } = useGetCheckoutQuery(id!);

    const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY, {
        stripeAccount: session?.storefront.account_id
    });

    const options: StripeElementsOptions = {
        mode: 'setup',
        currency: 'gbp',
        paymentMethodCreation: 'manual',
        appearance: appearance,
    }

    useEffect(() => {
        if (isError) {
            setTimeout(() => {
                window.location.href = 'https://1fit.com';
            }, 1000);
        }
    }, [isError])

    const handleCheckoutProcessed = (response: ICheckoutProcessed) => {
        setCheckoutComplete(response);
    }

    if (isError) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh" width="100vw">
                <span>Error loading checkout</span>
            </Box>
        )
    }

    if (!session || isLoading) {
        return (
            <CircularProgress />
        )
    }

    return (    
        <Box display="grid" gridTemplateColumns={{xs: '1fr', md: '2fr 3fr'}} height="100vh" width="100vw">

            <ProductPane session={session} isLoading={isLoading} isComplete={!!checkoutComplete} />

            {!checkoutComplete && <Elements stripe={stripePromise} options={options}>
                <SubscriptionForm onPaymentComplete={handleCheckoutProcessed} />
            </Elements>}

            {checkoutComplete && <SuccessPage email={checkoutComplete.email} invoice_url={checkoutComplete.invoice_url} />}

            {/* Footer when mobile view */}
            <MobileFooter session={session} />

        </Box>
    )
}

export default CheckoutPage;