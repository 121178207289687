import { BaseSyntheticEvent, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Button from "@/components/button";
import Container from "@mui/material/Container";
import Accordion from "@mui/material/Accordion";
import AccordionSummary, { accordionSummaryClasses } from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import { ChevronDown, Roadmap } from "@carbon/icons-react";

import SecondaryToolbar from "@/components/secondary_toolbar";
import PhaseOverview from "@/components/phases/phase_overview";
import PhaseDetails from "@/components/phases/phase_details";
import { useGetPhasesQuery } from "@/repositories/phase";
import { TagProps } from "@/components/tag";
import IPhase from "@/interfaces/phase/phase";
import PhaseSkeleton from "@/components/phases/phase_loading";

interface filter {
    completed: boolean;
    notstarted: boolean;
}

export default function Overview() {
    
    const { id } = useParams()
    const { data: phases, isLoading } = useGetPhasesQuery(Number(id))
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [phaseFilter, setPhaseFilter] = useState<filter>({completed: true, notstarted: true})
    const toggleFilter = (key: keyof filter) => setPhaseFilter({...phaseFilter, [key]: !phaseFilter[key]})
    
    const filteredPhases = useMemo(() => {
        let f = phases ?? []
        if (!phaseFilter.completed) f = f.filter(p => !p.end)
        if (!phaseFilter.notstarted) f = f.filter(p => p.start)
        return f
    }, [phaseFilter, phases])

    const phaseTags: TagProps[] = [
        {
            size: 'medium',
            label: t('components.phases.notStartedCount', { count: phases?.filter(p => !p.start).length ?? 0}),
            active: phaseFilter.notstarted,
            onSelect:() => toggleFilter('notstarted')
        },
        {
            size: 'medium',
            label: t('components.phases.completedCount', { count: phases?.filter(p => p.end).length ?? 0}),
            active: phaseFilter.completed,
            onSelect:() => toggleFilter('completed')
        }
    ]

    return (
      <Box width='100%' height='100%'>
        {/* top */}
        <SecondaryToolbar title={t('pages.client.overview')} kind="primary"
            tags={phaseTags}
            action1={{
                label: t('pages.client.roadmap'),
                icon: <Roadmap />,
                onClick:() => navigate(`/clients/${id}/phases/roadmap`)
            }}
        />

        {/* main */}
        <Box sx={{overflowY: 'auto', height: 'calc(100% - 48px)'}}>
            <Container sx={{py: 4}}>
                {/* loading state */}
                {isLoading && new Array(5).fill(0).map((_,i) => (
                    <Box key={i} mb={2}>
                        <PhaseSkeleton />
                    </Box>
                ))}

                {/* no data */}
                {!isLoading && filteredPhases.length === 0 && (
                    <Box className='body-02-compact text-secondary' sx={{
                        bgcolor: 'white',
                        py: 2,
                        borderRadius: '6px',
                        border: '1px solid var(--border-subtle-01)'
                    }}>
                        <Box mb={1}>
                            {!phaseFilter.notstarted || !phaseFilter.completed ? 
                            <Trans i18nKey='components.phases.noActivePhases' /> :
                            <Trans i18nKey='components.phases.noData' />}
                        </Box>
                        <Button 
                            size='small'
                            kind='ghost'
                            label={t('components.buttons.openPhaseRoadmap')}
                            onClick={()=>navigate(`/clients/${id}/phases/roadmap`)}
                        />
                    </Box>
                )}

                {/* main */}
                <Box sx={{
                    position: 'relative',
                    '&::before': {
                        content: '" "',
                        position: 'absolute',
                        overflow: 'hidden',
                        borderLeft: '2px solid var(--border-subtle-02)',
                        left: 40,
                        top: 20,
                        bottom: 20
                    }
                    }}>
                    {filteredPhases.map((phase) => (
                        <Box key={phase.id} mb={2}>
                            <PhaseSection phase={phase} />
                        </Box>
                    ))}
                </Box>
            </Container>
        </Box>
      </Box>  
    );
}

const PhaseSection = ({phase}: {phase: IPhase}) => {
    
    const [open, setOpen] = useState<boolean>(false)
    const phaseRef = useRef(null);

    const handleChange = (e: BaseSyntheticEvent) => {
        const classList = e.target.classList;
        if (!classList.contains('_Expandable') && !classList.contains('MuiAccordionSummary-content')) return;
        e.stopPropagation();
        setOpen(!open);
    }

    return (
        <Accordion 
        ref={phaseRef}
        expanded={open}
        onChange={handleChange}
        slotProps={{ transition: { unmountOnExit: true } }} sx={{
            m: 0, 
            width: '100%', 
            boxShadow: '0', 
            border: `1px solid var(--border-subtle-01)`
        }}>

            <AccordionSummary
                expandIcon={<ChevronDown className="_Expandable" />}
                sx={{
                    ml: 3, p: 0,
                    flexDirection: 'row-reverse',
                    [`& .${accordionSummaryClasses.content}`]: {
                        m: 0, p: 2,
                        [`&.${accordionSummaryClasses.expanded}`]: {
                            m: 0
                        }
                    }
                }}
            >
                <PhaseOverview phase={phase} />
            </AccordionSummary>

            <AccordionDetails sx={{p:0, textAlign: 'left'}}>
                <PhaseDetails phase_id={phase.id} />
            </AccordionDetails>

        </Accordion>
    );
}