import dayjs from "dayjs";
import { FC } from "react";
import { useTranslation } from "react-i18next";

// Services and interfaces
import IPaymentMethod from "@/interfaces/payment_methods/payment_method";

// Components
import { Box, Card, Radio } from "@mui/material";
import CardIcon, { CardKey } from "@/components/card_icon";


interface _PaymentMethodProps {
    paymentMethod: IPaymentMethod;
    isSelected: boolean;
    onSelect: (paymentMethod: IPaymentMethod) => void;
}

const PaymentMethod: FC<_PaymentMethodProps> = ({
    paymentMethod,
    isSelected,
    onSelect
}) => {

    const { t } = useTranslation();
    const isExpired = dayjs().isAfter(dayjs(`${paymentMethod.exp_year}-${paymentMethod.exp_month}-31`), 'month');

    const cardStyles = {
        display: 'flex',
        alignItems: 'center',
        width: '384px',
        backgroundColor: 'var(--layer-01) !important',
        padding: '16px 32px', 
        border: isSelected && !isExpired ? 'solid 1px var(--border-inverse)' : 'solid 1px var(--border-subtle-01)', 
        borderRadius: '6px', marginBottom: '12px',
        color: isExpired ? 'var(--text-disabled)' : 'var(--text-primary)'
    }

    const handleSelect = () => {
        onSelect(paymentMethod);
    }

    return (
        <Card 
            key={paymentMethod.id} 
            elevation={0}
            sx={cardStyles}
            >
            <Radio size="small" checked={isSelected} onChange={handleSelect} disabled={isExpired} sx={{padding: 0}} />
            <Box width={32} />
            <CardIcon type={paymentMethod.brand as CardKey} size="medium" />
            <Box width={32} />
            <Box display="flex" flexDirection="column" rowGap="8px" width="100%">
                <span className="label-text-01">{t('billing.endsWith', {last4: paymentMethod.last_four})}</span>
                <span className="label-text-01">{t('billing.expirey', {expirey: `${paymentMethod.exp_month}/${paymentMethod.exp_year}`})}</span>
            </Box>
            {paymentMethod.default && <Box display="flex" flexGrow={1}>
                <span className="label-text-01" style={{color: 'var(--focus)'}}>{t('components.buttons.default')}</span>
            </Box>}
        </Card>
    )
}

export default PaymentMethod;