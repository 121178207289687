import { FC } from "react";
import { t } from "i18next";

// Helpers
import { formatAmount } from "@/_helpers/number_functions";

// Services and interfaces
import { ITransaction } from "@/interfaces/transaction/transaction";

// Styles
import { ArrowUpRight } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal";
import { Box } from "@mui/material";
import { getCurrencySymbol } from "@/_helpers/currency_functions";


interface _DetailsModalProps {
    open: boolean;
    onClose: () => void;
    transaction: ITransaction;
}

const DetailsModal: FC<_DetailsModalProps> = ({
    open,
    onClose,
    transaction,
}) => {

    const hasRefunds = transaction.refunds.length > 0;
    const refundTotal = transaction.refunds.reduce((acc, refund) => acc + refund.amount, 0);
    const providerFees = transaction.fees.find(fee => fee.type === 'provider_fees');
    const platformFees = transaction.fees.find(fee => fee.type === 'platform_fees');
    const netAmount = formatAmount(transaction.amount - (refundTotal ?? 0) - (providerFees?.amount ?? 0) - (platformFees?.amount ?? 0));

    const getLineItem = (label: string, value: string, hideBorder?: boolean, bold?: boolean) => {
        return (
            <Box display="flex" justifyContent="space-between" py="8px" style={{borderBottom: hideBorder ? 'unset' : 'solid 1px var(--border-subtle-01)'}}>
                <span className={`label-text-02${bold ? '-semibold' : ''}`} style={{color: 'var(--text-secondary)'}}>{label}</span>
                <span className={`label-text-02${bold ? '-semibold' : ''}`} style={{color: 'var(--text-secondary)'}}>{value}</span>
            </Box>
        )
    }

    const handleGoToStripe = () => {
        window.open('https://dashboard.stripe.com/payments', '_blank');
    }

    return (
        <Modal 
            open={open}
            onClose={onClose}
            showClose
            title={t('modals.transactionDetails.title')}
            text={t('modals.transactionDetails.text')}
            children={
                <Box display="flex" flexDirection="column">
                    <Box display="flex" flexDirection="column" width="100%">
                        {getLineItem(t('modals.transactionDetails.list.datePaid'), new Date(transaction.paid_at).toLocaleDateString())}
                        {hasRefunds && getLineItem(t('modals.transactionDetails.list.dateRefunded'), new Date(transaction.refunds[0].refunded_at).toLocaleDateString())}
                        {getLineItem(t('modals.transactionDetails.list.paymentAmount'), `${getCurrencySymbol(transaction.currency)}${transaction.amount}`)}
                        {hasRefunds && getLineItem(t('modals.transactionDetails.list.refundAmount'), `-${getCurrencySymbol(transaction.currency)}${formatAmount(refundTotal)}`)}
                        {providerFees && getLineItem(t('modals.transactionDetails.list.providerFees'), `-${getCurrencySymbol(providerFees?.currency)}${providerFees?.amount}`)}
                        {platformFees && getLineItem(t('modals.transactionDetails.list.platformFees'), `-${getCurrencySymbol(platformFees?.currency)}${platformFees?.amount}`)}
                        {getLineItem(t('modals.transactionDetails.list.net'), `${getCurrencySymbol(transaction.currency)}${netAmount}`, true, true)}
                    </Box>
                </Box>
            }
            action1={{
                label: t('components.buttons.goToStripe'),
                icon: <ArrowUpRight />,
                loading: false,
                onClick: handleGoToStripe
            }}
            action2={{
                kind: "ghost",
                label: t('components.buttons.viewFees'),
                icon: <ArrowUpRight />,
                loading: false,
                onClick: () => undefined,
                sx: {minWidth: 'fit-content'}
            }}
            sx={{'& .MuiPaper-root': {width: '100%', maxWidth: '480px', background: 'var(--background)'}}}
            />
    )
}

export default DetailsModal;