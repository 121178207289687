import dayjs from "dayjs";
import { t } from "i18next";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Services and interfaces
import { useGetPhasesQuery } from "@/repositories/phase";
import { selectIsClientReadonly, useGetClientQuery } from "@/repositories/client";

// Styles
import { Add } from "@carbon/icons-react";

// Components
import Box from "@mui/material/Box";
import Card from "@/components/card";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import YearlyCalendar from "@/components/calendar/yearly";
import NavToolbar from "@/components/navigation/nav_toolbar";
import SecondaryToolbar from "@/components/secondary_toolbar";
import PhaseOverview from "@/components/phases/phase_overview";
import PhasePanel from "@/components/phases/phase/phase_panel";


const PhaseRoadmap: FC = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const { data: _ } = useGetClientQuery(Number(id));
    const { data: phases } = useGetPhasesQuery(Number(id));
    const readonly = useSelector((state) => selectIsClientReadonly(state, id!));
    
    return (
        <Box minHeight='100vh'>

            <NavToolbar />
            <Toolbar variant='dense' />

            <SecondaryToolbar 
                onBack={() => navigate(`/clients/${id}/overview`)} 
                title={t('pages.client.roadmap')}
                action1={{
                    label: t('components.buttons.newPhase'), 
                    icon: <Add />,
                    disabled: readonly,
                    onClick: () => setOpen(true)
                }}
                />
                {open && <PhasePanel
                    open={open}
                    onClose={() => setOpen(false)}
                    />}

            {/* main */}
            <Container sx={{display: 'flex', width: '100%', pt: 4}}>

                {phases?.length === 0 && (
                    <Box width='45%'>
                        <Box className='body-02-compact text-secondary' sx={{
                            bgcolor: 'white',
                            py: 2,
                            borderRadius: '6px',
                            border: '1px solid var(--border-subtle-01)'
                        }}>
                            {t('components.phases.noDataAlt')}
                        </Box>
                    </Box>
                )}

                <Box flexGrow={1}>

                    <Box sx={{
                        position: 'relative',
                        '&::before': {
                            content: '" "',
                            position: 'absolute',
                            overflow: 'hidden',
                            borderLeft: '2px solid var(--border-subtle-02)',
                            left: 40,
                            top: 20,
                            bottom: 20,
                        }
                    }}>
                    {phases?.map(p => {
                        return (
                            <Card key={p.id} sx={{ mb: 2 }}>
                                <PhaseOverview phase={p} />
                            </Card>
                        );
                    })}
                    </Box>

                </Box>

                <Box mx={2} flexGrow={0} />

                <Box width='50%'>
                    <YearlyCalendar 
                        range={phases?.map(p => ({
                            key: p.id,
                            title: p.name,
                            kind: p.start && !p.end ? 'active' : 'secondary',
                            range: [
                                dayjs(dayjs.utc(p.start ?? p.expected_start).format('YYYY-MM-DDTHH:mm:ss')), 
                                dayjs(dayjs.utc(p.end ?? p.expected_end).format('YYYY-MM-DDTHH:mm:ss')),
                            ]
                        }))}
                        />
                </Box>
                
            </Container>

        </Box>
    );
}

export default PhaseRoadmap;