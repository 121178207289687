import dayjs, { Dayjs } from "dayjs";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm, useWatch } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import IPhase from "@/interfaces/phase/phase";
import { ApiError } from "@/interfaces/api/error";
import { IPhaseForm } from "@/interfaces/phase/phase_form";
import { phaseSchema } from "@/interfaces/phase/phase_form";
import { useAddPhaseMutation, useGetPhasesQuery, useUpdatePhaseMutation } from "@/repositories/phase";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import PhaseForm from "./phase_form";
import RightPanel from "@/components/panel/right_panel";


interface FormProps {
    open: boolean;
    onClose: ()=>void;
    phase?: IPhase;
}

const PhasePanel: FC<FormProps> = ({
    open,
    onClose,
    phase
}) => {

    const { id } = useParams();
    const { t } = useTranslation();
    const { data: phases } = useGetPhasesQuery(Number(id));
    const [addPhase, { isLoading: isAdding }] = useAddPhaseMutation();
    const [updatePhase, { isLoading: isEditing }] = useUpdatePhaseMutation();

    const lastPhase = phases?.[phases.length - 1];
    const lastCompletedPhase = phases?.filter(p => p.end).reverse()[0];
    const currentPhase = phases?.find(p => p.start && !p.end);
    const expected_start = lastPhase ? dayjs.utc(lastPhase.end ?? lastPhase.expected_end).add(1, 'day').startOf('day').format('YYYY-MM-DDTHH:mm:ss') : dayjs().startOf('day').format('YYYY-MM-DDTHH:mm:ss');
    const earliestStart = (): Dayjs => {
        if (currentPhase && currentPhase.id === phase?.id) return dayjs(currentPhase.start).startOf('day');
        if (currentPhase) return dayjs(currentPhase.start).startOf('day').add(2, 'day');
        if (lastCompletedPhase) return dayjs(dayjs.utc(lastCompletedPhase?.end).format('YYYY-MM-DDTHH:mm:ss')).startOf('day').add(1, 'day')
        return dayjs('1970-01-01').startOf('day');
    }

    const formMethods = useForm<IPhaseForm>({
        resolver: zodResolver(phaseSchema),
        mode: 'onBlur',
        defaultValues: {
            name: phase?.name ?? '',
            expected_start: phase?.expected_start ?? expected_start,
            expected_end: phase?.expected_end ?? dayjs(expected_start).endOf('day').add(1, 'day').format('YYYY-MM-DDTHH:mm:ss'),
            start: phase?.start ?? undefined,
            end: phase?.end ?? undefined,
            description: phase?.description ?? ''
        }
    });

    const expectedStart = useWatch({control: formMethods.control, name: 'expected_start'});
    const expectedEnd = useWatch({control: formMethods.control, name: 'expected_end'});
    const availableDates = [
        {start: dayjs(earliestStart()).startOf('day'), end: dayjs.utc('2099-12-31')},
        {start: dayjs(expectedStart).startOf('day').add(1, 'day'), end: dayjs.utc('2099-12-31')}
    ]

    useEffect(() => {
        if (dayjs(expectedStart).isSame(dayjs(expectedEnd), 'day') || dayjs(expectedStart).isAfter(dayjs(expectedEnd), 'day')) {
            formMethods.setValue('expected_end', dayjs.utc(expectedStart).endOf('day').add(1, 'day').format('YYYY-MM-DDTHH:mm:ss'))
        }
    }, [expectedStart])

    const submitAddPhase = (data: IPhaseForm) => {
        // Set the expected start and end to the start and end of the day
        data.expected_start = dayjs(data.expected_start).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
        data.expected_end = dayjs(data.expected_end).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
        addPhase({client_id: Number(id), data}).unwrap().then((p) => {
            showToast({
                type: 'success',
                title: t('notifications.phase.created.title'),
                message: t('notifications.phase.created.message', {name: p.name})
            });
            handleClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    const handleUpdatePhase = (data: IPhaseForm) => {
        // Set the expected start and end to the start and end of the day
        data.expected_start = dayjs(data.expected_start).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
        data.expected_end = dayjs(data.expected_end).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
        if (!phase) return;
        const req = {client_id: Number(id), phase_id: phase.id, data: data}
        updatePhase(req).unwrap().then((p) => {
            showToast({
                type: 'success',
                title: t('notifications.phase.updated.title'),
                message: t('notifications.phase.updated.message', {name: p.name})
            });
            handleClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    const handleClose = () => { 
        formMethods.reset(); 
        onClose();
    }

    return (
        <RightPanel
            open={open}
            onClose={formMethods.formState.isDirty ? undefined : onClose}
            title={phase ? t('rightPanel.phase.editPhase') : t('rightPanel.phase.addPhase')}
            action1={{
                label: t('components.buttons.save'),
                icon: <ArrowRight />,
                loading: isAdding || isEditing,
                disabled: !formMethods.formState.isDirty,
                onClick: formMethods.handleSubmit(phase ? handleUpdatePhase : submitAddPhase)
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isAdding || isEditing,
                onClick: handleClose
            }}
            children={
                <FormProvider {...formMethods}>
                    <PhaseForm phase={phase} availableDates={availableDates} />
                </FormProvider>
            }
            />
    )
}

export default PhasePanel;