import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, useParams } from "react-router-dom";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { selectIsClientReadonly } from "@/repositories/client";
import { ITemplateAssignForm } from "@/interfaces/template/assign_form";
import { IActivityPlan } from "@/interfaces/activity_plan/activity_plan";
import { useAddActivityPlanMutation } from "@/repositories/activity_plan";
import { IActivityBuilder } from "@/interfaces/activity_plan/activity_builder";
import { useAssignActivityTemplateMutation, useGetActivityTemplatesQuery } from "@/repositories/activity_template";

// Components
import { Box } from "@mui/material";
import PlanMenu from "@/pages/client/components/plan_menu";
import SecondaryToolbar from "@/components/secondary_toolbar";
import AssignModal from "@/pages/client/components/assign_modal";
import ActivityModal from "@/components/activity_builder/activity_modal";


const ActivityPage: FC = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = useState<string|null>(null)
    const readonly = useSelector((state) => selectIsClientReadonly(state, id!));
    const [addActivityPlan, { isLoading }] = useAddActivityPlanMutation();
    const [assignActivityTemplate, { isLoading: isAssigning }] = useAssignActivityTemplateMutation();
    const { data: templates, isLoading: isTemplatesLoading } = useGetActivityTemplatesQuery();


    const tabs = [
        {
            label: t('tabs.data'),
            link: `/clients/${id}/activity/data`,
            sx: {width: '120px'}
        },
        {
            label: t('tabs.plans'),
            link: `/clients/${id}/activity/plans`,
            sx: {width: '120px'}
        }
    ];

    const handleAdd = (data: IActivityBuilder) => {
        addActivityPlan({id: Number(id), data: data as IActivityPlan}).unwrap().then((plan) => {
            showToast({
                type: 'success', 
                title: t('notifications.plan.created.title'), 
                message: t('notifications.plan.created.message', {name: plan.name})
            });
            setOpen(null);
            navigate(`/clients/${id}/activity/${plan.id}`)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const handleAssign = (data: ITemplateAssignForm) => {
        assignActivityTemplate(data).unwrap().then((plan) => {
            showToast({
                type: 'success', 
                title: t('notifications.template.assigned.title', { count: 1 }), 
            });
            setOpen(null);
            navigate(`/clients/${id}/activity/${plan.id}`)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Box width='100%' height='100%'>

            <SecondaryToolbar
                kind="primary"
                title={t('pages.plans.activity.title')}
                tabs={tabs}
                slot={<PlanMenu 
                    label={t('components.buttons.addActivityPlan')} 
                    disabled={readonly} 
                    setOpen={setOpen}
                />}
            />
            {open === 'add' && <ActivityModal
                open={open === 'add'}
                onClose={() => setOpen(null)}
                isLoading={isLoading}
                onSave={handleAdd}
                />}
            {open === 'assign' && <AssignModal 
                open={open === 'assign'} 
                onClose={() => setOpen(null)}
                client_id={Number(id)}
                templates={templates?.filter((t) => !t.draft) ?? []}
                isLoading={isAssigning}
                isTemplatesLoading={isTemplatesLoading}
                onSave={handleAssign}
                />}

            <Outlet />

        </Box>
    )
}

export default ActivityPage;