import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Config
import useSettingsMenuItems from "@/_configs/settings";

// Components
import Box from '@mui/material/Box';
import NavToolbar from "@/components/navigation/nav_toolbar";
import NavSideMenu from "@/components/navigation/side_menu";


export default function SettingsLayout() {

    const { t } = useTranslation();
    const SettingsMenuItems = useSettingsMenuItems();

    return (
        <>
            <NavToolbar />
            <Box component='main' display='flex' height='calc(100vh - 40px)' overflow="hidden">
                {/* <Toolbar variant='dense' /> */}
                
                <NavSideMenu
                    kind='ghost'
                    label={t('components.settingsMenu.title')}
                    items={SettingsMenuItems}
                    />

                <Box display='flex' width="100%" overflow="auto">
                    <Outlet /> 
                </Box>
                        
            </Box>
        </>
    )
}

