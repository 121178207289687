
import { createSlice } from "@reduxjs/toolkit";


interface CheckinPopupState {
    open: boolean;
    minimized: boolean;
    client_id?: number;
    submission_id?: string;
}

const initialState: CheckinPopupState = {
    open: false,
    minimized: false,
    client_id: undefined,
    submission_id: undefined
};

export const checkinPopupSlice = createSlice({
  name: "checkinPopup",
  initialState,
  reducers: {
    setOpen: (state, { payload }) => {
      state.open = payload;
    },
    setMinimized: (state, { payload }) => {
      state.minimized = payload;
    },
    setCheckin: (state, { payload }) => {
      state.client_id = payload.client_id;
      state.submission_id = payload.submission_id;
    }
  },
  selectors: {
    selectOpen: (state): boolean => state.open,
    selectMinimized: (state): boolean => state.minimized,
    selectClientId: (state): number | undefined => state.client_id,
    selectSubmissionId: (state): string | undefined => state.submission_id
  }
});

export const { selectOpen, selectMinimized, selectClientId, selectSubmissionId } = checkinPopupSlice.selectors;
export const { setOpen, setMinimized, setCheckin } = checkinPopupSlice.actions;
export default checkinPopupSlice.reducer;