import dayjs from "dayjs";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Helpers
import { snakeToCamel } from "@/_helpers/text_functions";
import { roundNumber } from "@/_helpers/number_functions";

// Services and interfaces
import { IClientActivity } from "@/interfaces/client/client_activity";
import { useGetClientActivityQuery } from "@/repositories/client_data";

// Components
import { Box } from "@mui/material";
import { TotalValues } from "./totals_card";
import DataTable from "@/components/datatable";
import Container from "@mui/material/Container";
import Chart from "@/pages/client/components/chart";
import useActivityDataTableHeaders from "./data_headers";
import ActivityDetailsPanel from "./activity_details_panel";
import ChartCard from "@/pages/client/components/chart_card";


const ActivityDataTab: FC = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [chartMetric, setChartMetric] = useState<string>('');
    const [selectedRow, setSelectedRow] = useState<IClientActivity|null>(null);
    
    const headers = useActivityDataTableHeaders();
    const { data, isLoading } = useGetClientActivityQuery(Number(id));

    const chartData = data?.map((item) => {
        return {
            date: item.date,
            steps: item.steps,
            cardio: item.activity.reduce((acc, log) => acc + log.duration, 0) / 60
        }
    });
    const hasStepsData = chartData?.some(item => item.steps);
    const hasCardioData = chartData?.some(item => item.cardio);

    const totalSteps = (): TotalValues => {
        if (!data) return {total: 0, daysLogged: 0};
        const now = dayjs();
        const sevenDaysAgo = now.subtract(7, 'day');
        const items = data.filter((item) => item.steps && dayjs(item.date).isAfter(sevenDaysAgo));

        const total = roundNumber(items.reduce((acc, item) => acc + item.steps, 0), 0);

        return {total: total, daysLogged: items.length};
    }

    const totalCardio = (): TotalValues => {
        if (!data) return {total: 0, daysLogged: 0};
        const now = dayjs();
        const sevenDaysAgo = now.subtract(7, 'day');
        const items = data.filter((item) => item.activity.length > 0 && dayjs(item.date).isAfter(sevenDaysAgo));

        const total = roundNumber(items.reduce((acc, item) => {
            return acc + item.activity.reduce((acc, log) => acc + log.duration / 60, 0);
        }, 0), 0);

        return {total: total, daysLogged: items.length};
    }

    const handleOpenChart = (metric: string) => {
        setChartMetric(metric);
        setOpen(true);
    }

    return (
        <Box sx={{overflowY: 'auto', height: 'calc(100% - 48px)'}}>
        <Container sx={{py: '24px'}}>

            <Box display="flex" columnGap="16px">
                
                {headers.find(item => item.field === 'steps') && 
                    <ChartCard 
                        metric="steps" 
                        totals={totalSteps()} 
                        isLoading={isLoading} 
                        onShowChart={hasStepsData ? () => handleOpenChart('steps') : undefined} 
                        />}
                {headers.find(item => item.field === 'cardio') && 
                    <ChartCard 
                        metric="cardio" 
                        totals={totalCardio()} 
                        isLoading={isLoading} 
                        onShowChart={hasCardioData ? () => handleOpenChart('cardio') : undefined} 
                        />}

                {open == true && 
                    <Chart
                        open={open}
                        onClose={() => setOpen(false)}
                        kind="bar"
                        title={t(`metrics.${snakeToCamel(chartMetric)}`)}
                        data={chartData ?? []}
                        metric={chartMetric}
                        yLabel={t(`metrics.${snakeToCamel(chartMetric)}`)}
                    />}
            </Box>

            <DataTable
                data={data}
                dataKey="date"
                columns={headers} 
                hideHeader
                hideFilter
                localPagination
                noDataMessage={t('components.dataTable.noData.noLoggedData')}
                isLoading={isLoading}
                rowClick={(r) => setSelectedRow(r)}
                />

            <ActivityDetailsPanel 
                open={selectedRow != null}
                onClose={() => setSelectedRow(null)}
                logs={selectedRow}
                />

        </Container>
        </Box>
    )
}

export default ActivityDataTab;