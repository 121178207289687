import dayjs from "dayjs";
import { t } from "i18next";


export function formatDateAsText(date: string): string {
    if (!date || date === '') return t('timeDate.today');
    if (dayjs().isSame(date, 'day')) {
        return t('timeDate.today');
    } else if (dayjs().subtract(1, 'day').isSame(date, 'day')) {
        return t('timeDate.yesterday');
    } else if (dayjs().add(1, 'day').isSame(date, 'day')) {
        return t('timeDate.tomorrow');
    } else {
        return dayjs().to(date); // Default to relative time
    }
}

export function getOrdinalSuffix(n: number): string {
    const value = n % 100;
  
    if (value >= 11 && value <= 13) {
      return `${n}th`;
    }
  
    switch (value % 10) {
      case 1:
        return `${n}st`;
      case 2:
        return `${n}nd`;
      case 3:
        return `${n}rd`;
      default:
        return `${n}th`;
    }
  }