import { FC } from "react";
import { useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { IPhaseMilestone } from "@/interfaces/phase_milestone/phase_milestone";
import { useDeletePhaseMilestoneMutation } from "@/repositories/phase_milestone";

// Styles
import { TrashCan } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal";

interface DeleteMilestoneModalProps {
    open: boolean;
    onClose: () => void;
    phase_id: string;
    milestone: IPhaseMilestone;
}

const DeleteMilestoneModal: FC<DeleteMilestoneModalProps> = ({
    open,
    onClose,
    phase_id,
    milestone
}) => {

    const { id } = useParams();
    const { t } = useTranslation();
    const [deleteMilestone, { isLoading }] = useDeletePhaseMilestoneMutation();

    const handleDelete = () => {
        const req = {client_id: Number(id), phase_id: phase_id, id: milestone.id}
        deleteMilestone(req).unwrap().then(() => {
            showToast({type: 'success', 
                title: t('notifications.phaseMilestone.deleted.title'), 
                message: t('notifications.phaseMilestone.deleted.message', {name: milestone.title})
            });
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Modal 
            open={open}
            onClose={onClose}
            title={t('modals.deleteMilestone.title')}
            text={<Trans
                i18nKey="modals.deleteMilestone.text"
                components={{ strong: <strong /> }}
                values={{ name: milestone.title }}
            />}
            action1={{
                kind: 'danger',
                label: t('components.buttons.delete'),
                loading: isLoading,
                icon: <TrashCan />,
                onClick: handleDelete,
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '448px', background: 'var(--background)'}}}
        />
    )
}

export default DeleteMilestoneModal;