import i18n from "@/i18n";
import dayjs from "dayjs";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { FC, ReactNode, useRef, useState } from "react";
import logo from '/src/assets/logos/1fit_logo_avatar.png';

// Helpers
import { useNotifications } from "@/_lib/notification";

// Services and interfaces
import { INotification } from "@/interfaces/notifications/notification";
import { useReadAllNotificationsMutation, useReadNotificationMutation } from "@/repositories/notifications";

// Styles
import { CheckmarkOutline, Collaborate, Email, EmailNew, FaceDissatisfied, ListCheckedMirror, Notification, PiggyBank, ShareKnowledge, ShoppingCart, UserRole, Warning } from "@carbon/icons-react";

// Components
import Box from "@mui/material/Box";
import Avatar from "@/components/avatar";
import Tooltip from "@/components/tooltip";
import MuiAvatar from "@mui/material/Avatar";
import Menu from "@/components/navigation/nav_menu";
import Badge, { badgeClasses } from "@mui/material/Badge";
import NavHeaderAction from "@/components/navigation/nav_header_action";


export default function NavNotification() {
    // read actions
    const [ read ] = useReadNotificationMutation()
    const [ readAll ] = useReadAllNotificationsMutation()

    const badgeSx = {
        [`& .${badgeClasses.badge}`]: {
            bgcolor: 'var(--nav-background-brand)',
            transform: 'translate(3px, -4px)'
        }
    }

    const { t } = useTranslation();
    const anchor  = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState<boolean>(false)

    const { notifications, handleClickNotification } = useNotifications()
    const hasUnread = notifications.some(n => !n.read_at)
    const mappedNotifs = notifications.map(n => ({
        name: n.id,
        render: () => (<NotificationMenuItem notification={n} />),
        action: () => read(n.id).unwrap().then(() => handleClickNotification(n))
    }))

    if (mappedNotifs.length === 0) {
        mappedNotifs.push({
            name: 'no-unread',
            action: async () => {},
            render: () => (<NoUnreadMenuItem />),
        })
    }

    const handleReadAll = () => {
        readAll().unwrap().then(() => {
            notifications.splice(0, notifications.length)
        })
    }

    return (
        <>
            <Tooltip kind="nav" title={t('tooltips.notifications')}>
                <NavHeaderAction ref={anchor} icon={
                    <Badge sx={hasUnread ? badgeSx : undefined} overlap="circular" variant="dot">
                        <Notification size={20} />
                    </Badge>
                } onClick={() => setOpen(true)} />
            </Tooltip>
            <Menu
                anchorEl={anchor?.current}
                title={t('tooltips.notifications')}
                open={open}
                onClose={() => setOpen(false)}
                action={{icon: <ListCheckedMirror />, onClick: handleReadAll}}
                divider 
                removePadding 
                options={mappedNotifs}
            />
        </>
    );
}

interface NotificationItemProps { 
    notification: INotification 
}

const NotificationMenuItem: FC<NotificationItemProps> = ({ notification }) => {

    const isSystemNotif = ['service.updated', 'subscription.payment.failed'].includes(notification.data.kind);
    const notifTime = dayjs(notification.created_at)

    const badgeSx = {
        [`& .${badgeClasses.badge}`]: {
            bgcolor: 'var(--nav-background-brand)',
            transform: 'translate(4px, -6px)'
        }
    }

    const translationVars = {
        coach_name: notification.data.message_meta?.coach_name,
        client_name: notification.data.message_meta?.client_name,
        client_count: notification.data.message_meta?.client_count,
        member_name: notification.data.message_meta?.member_name,
        member_role: notification.data.message_meta?.member_role,
        prospect_name: notification.data.message_meta?.prospect_name,
        prospect_count: notification.data.message_meta?.prospect_count,
        team_name: notification.data.message_meta?.team_name,
        clients_retained: notification.data.message_meta?.clients_retained,
        referred_name: notification.data.message_meta?.referred_name,
        product_name: notification.data.message_meta?.product_name,
        model: notification.data.model?.split(/(?=[A-Z])/)[0]
    }

    const messageKeyExists = i18n.exists(`pushNotifications.${notification.data.kind}.message`) || i18n.exists(`pushNotifications.${notification.data.kind}.message_true`);

    const messageKey = () => {
        const transferredTo = notification.data.message_meta?.transferred_to;
        if (transferredTo !== undefined && transferredTo) return `pushNotifications.${notification.data.kind}.message_true`;
        if (transferredTo !== undefined && !transferredTo) return `pushNotifications.${notification.data.kind}.message_false`;
        return `pushNotifications.${notification.data.kind}.message`;
    }

    const notifIcon = (): ReactNode => {
        switch (notification.data.kind) {
            case 'service.updated':
            case 'team.invitation.sent':
                return <Email />;
            case 'team.invitation.accepted':
                return <CheckmarkOutline />
            case 'team.invitation.declined':
                return <Warning />
            case 'team.member.removed':
                return <Warning />
            case 'team.member.updated.role':
                return <UserRole />
            case 'team.template.shared':
                return <ShareKnowledge />
            case 'client.checkout.completed':
                return <ShoppingCart />
            case 'client.payment.failed':
                return <Warning />
            case 'client.subscription.ended':
                return <FaceDissatisfied />
            case 'prospect.lead_form.submitted':
                return <EmailNew />
            case 'referral.status.pending':
                return <Collaborate />
            case 'referral.status.paid':
                return <PiggyBank />
            case 'referral.status.cancelled':
                return <FaceDissatisfied />
            default:
                return <Email />;
            // default:
            //     return logo;
        }
    }

    return (
        <Box display='flex' minWidth="420px" p={2} pb={2} sx={{scrollbarWidth: 'none', msOverflowStyle: 'none'}} >
            <Badge 
                sx={!!notification.read_at ? undefined : badgeSx} 
                overlap="circular" variant="dot"
            >
                <MuiAvatar sx={{
                    width: 34, height: 34, 
                    bgcolor: !!notification.read_at ? undefined : 'var(--nav-background-brand)'
                }}>
                    {isSystemNotif ? 
                        <Avatar
                            size="medium"
                            name=""
                            src={logo}
                            /> : 
                        <MuiAvatar sx={{width: 32, height: 32, bgcolor: 'var(--nav-layer-02)'}}>{notifIcon()}</MuiAvatar>}
                </MuiAvatar>
            </Badge>
            <Box display="flex" flexDirection="column" maxWidth="400px" ml={2}>
                <span className='heading-07-compact' style={{color: 'var(--nav-text-primary)', whiteSpace: 'pre-wrap'}}>
                    {notification.data.kind ? t(`pushNotifications.${notification.data.kind}.title`, translationVars) : notification.data.message}
                </span>
                {messageKeyExists && <span className="body-02" style={{color: 'var(--nav-text-primary)', whiteSpace: 'pre-wrap'}}>
                    {t(messageKey(), translationVars)}
                </span>}
                <Box display="flex" alignItems="center">
                    {notifTime.isToday() && <span className="label-text-02">{t('timeDate.today')} &#x2022;</span>}
                    <span className="label-text-02" style={{marginLeft: '4px'}}>{notifTime.fromNow()}</span>
                </Box>
            </Box>
        </Box>
    )
}

const NoUnreadMenuItem: FC = () => {
    const { t } = useTranslation()
    return (
        <Box mx={2} p={2} pb={2} minWidth="420px" textAlign='center' sx={{color: 'var(--text-overlay)'}}>
            <div className="heading-07-compact">
                {t('components.notifications.caughtUp')}
            </div>
            <div className="body-02">
                {t('components.notifications.noUnread')}
            </div>
        </Box>
    )
}