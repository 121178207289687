import { FC } from "react"
import { Trans } from "react-i18next";

// Helpers
import { secondaryFontFromBackground } from "@/_helpers/colour_functions";

// Services and interfaces
import { ICheckout } from "@/interfaces/checkout/checkout";

// Components
import { Box } from "@mui/material";


interface _MobileFooterProps {
    session: ICheckout;
}

const MobileFooter: FC<_MobileFooterProps> = ({
    session
}) => {
    
    const backgroundColor = session?.storefront.primary_color;
    const secondaryFont = secondaryFontFromBackground(backgroundColor ?? 'var(--white)');

    return (
        <Box 
            display="flex" 
            flexGrow={1} 
            bgcolor={session.storefront.primary_color} 
            padding="32px 16px" 
            alignItems="center"
            justifyContent="center"
            sx={{display: {xs: 'flex', md: 'none'}}}
            >
            <Box display="flex" alignItems="center">
                <span className="body-02" style={{color: secondaryFont}}>{
                    <Trans 
                        i18nKey="pages.checkout.poweredBy"
                        components={{strong: <strong />}}
                        />
                }</span>
            </Box>
        </Box>
    )
}

export default MobileFooter;