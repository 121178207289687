import { z } from "zod";
import ruleset from "@/_helpers/ruleset";


export default interface IStorefrontSettingsForm {
    // client_state_behaviour: 'cancel'|'maintain';
    subscription_end_behaviour: 'inactive'|'active';
    payout_schedule: 'manual'|'automatic';
    payout_frequency: 'daily'|'weekly'|'monthly';
    weekly_anchor: string;
    monthly_anchor: number;
    default_currency: string;
}

export const storefrontSettingsFormSchema = z.object({
    // client_state_behaviour: ruleset.select,
    subscription_end_behaviour: ruleset.select,
    payout_schedule: ruleset.select,
    payout_frequency: ruleset.select,
    weekly_anchor: ruleset.required,
    monthly_anchor: ruleset.requiredNumber,
})