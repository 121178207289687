import { FC } from "react";
import { useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { IPhaseNote } from "@/interfaces/phase_note/phase_note";
import { useDeletePhaseNoteMutation } from "@/repositories/phase_note";

// Styles
import { TrashCan } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal";


interface DeleteNoteModalProps {
    open: boolean;
    onClose: () => void;
    phase_id: string;
    note: IPhaseNote;
}

const DeleteNoteModal: FC<DeleteNoteModalProps> = ({
    open,
    onClose,
    phase_id,
    note
}) => {
    
    const { id } = useParams();
    const { t } = useTranslation();
    const [deletePhaseNote, { isLoading }] = useDeletePhaseNoteMutation();

    const handleDelete = () => {
        const req = {client_id: Number(id), phase_id: phase_id, id: note.id}
        deletePhaseNote(req).unwrap().then(() => {
            showToast({
                type: 'success',
                title: t('notifications.phaseNote.deleted.title'),
                message: t('notifications.phaseNote.deleted.message', {name: note.title})
            });
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Modal 
            open={open}
            onClose={onClose}
            title={t('modals.deletePhaseNote.title')}
            text={<Trans 
                i18nKey="modals.deletePhaseNote.text" 
                values={{ name: note.title }}
                components={{ strong: <strong /> }}
                />}
            action1={{
                kind: 'danger',
                label: t('components.buttons.delete'),
                loading: isLoading,
                icon: <TrashCan />,
                onClick: handleDelete,
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '448px', background: 'var(--background)'}}}
        />
    )
}

export default DeleteNoteModal;