import { useSelector } from "react-redux";

// Services and interfaces
import { selectHasTeamOwnerRole } from "@/store/auth";
import { selectHasInvitation, selectHasTeam } from "@/store/team";

// Components
import RequestTeam from "./team/request_team";
import { Box, Container } from "@mui/material";
import NoTeam from "@/pages/settings/team/no_team";
import ManageInvitation from "./team/manage_invitation";
import ManageTeam from "@/pages/settings/team/manage_team";


const TeamPage = () => {

    // const { t } = useTranslation();
    const hasTeam = useSelector(selectHasTeam);
    const hasInvitation = useSelector(selectHasInvitation);
    const hasTeamOwnerRole = useSelector(selectHasTeamOwnerRole);
    const showRequestTeam = !hasTeam && !hasInvitation && !hasTeamOwnerRole;

    return (
        <Container sx={{py: '24px'}}>
            <Box display="flex" flexDirection="column" justifyContent="flex-start">

                {showRequestTeam && <RequestTeam />}

                {hasTeamOwnerRole && !hasTeam && !hasInvitation && <NoTeam />}

                {!hasTeam && hasInvitation && <ManageInvitation />}

                {hasTeam && <ManageTeam />}
                
                {/* Spacer */}
                <Box height="40" />

            </Box>
        </Container>
    )
}

export default TeamPage;