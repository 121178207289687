import dayjs from "dayjs";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import IPaymentMethod from "@/interfaces/payment_methods/payment_method";
import { useGetLatestInvoiceQuery, usePayInvoiceMutation } from "@/repositories/invoice";

// Styes
import { ArrowRight } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal";
import CardList from "@/components/billing/card_list";


interface _PayNowModalProps {
    open: boolean;
    onClose: () => void;
}

const PayNowModal: FC<_PayNowModalProps> = ({
    open,
    onClose
}) => {

    const { t } = useTranslation();

    const { data: invoice } = useGetLatestInvoiceQuery(undefined);
    const [payInvoice, { isLoading }] = usePayInvoiceMutation();
    const [selectedCard, setSelectedCard] = useState<IPaymentMethod|undefined>(undefined);

    const submitDisabled = () => {
        if (!selectedCard) return false;
        return dayjs().isAfter(dayjs(`${selectedCard.exp_year}-${selectedCard.exp_month}-31`), 'month');
    
    }

    const handleClose = () => {
        setSelectedCard(undefined);
        onClose();
    }
    const submit = () => {
        if (!invoice || !selectedCard) return;

        const payload = {
            invoice_id: invoice.id,
            payment_method_id: selectedCard.id
        }

        payInvoice(payload).unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.billing.paymentSubscriptionSuccessful.title'), message: t('notifications.billing.paymentSubscriptionSuccessful.message')})
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Modal  
            open={open}
            title={t('modals.payNow')}
            children={<CardList
                selectedCard={selectedCard}
                onSelectCard={setSelectedCard}
                />}
            action1={{
                label: t('components.buttons.continue'),
                icon: <ArrowRight />,
                loading: isLoading,
                disabled: submitDisabled(),
                onClick: submit,
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: handleClose,
            }}
            />
    )
}

export default PayNowModal;