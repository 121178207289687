import { Error } from '@mui/icons-material';
import { Control, useController } from "react-hook-form";
import { TextField, InputAdornment, Box, TextFieldProps } from "@mui/material";
import { BaseSyntheticEvent, ClipboardEvent, FC, ReactNode, useEffect, useState } from "react";

interface NumberInputProps {
  name: string;
  control: Control<any>;
  //REVIEW will this work?
  size?: 'small' | 'medium' | 'large';
  label?: string;
  placeHolder?: string;
  helperText?: string;
  tabIndex?: number;
  decimalPlaces?: number;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  prefixText?: string;
  suffixText?: string;
  maxLength?: number;
  maxValue?: number;
  gutter?: string;
  hideErrorMessage?: boolean;
  disabled?: boolean;
  onChange?: (value: number) => void;
  sx?: TextFieldProps['sx'];
}

const NumberInput: FC<NumberInputProps>  = ({
  name,
  control,
  size = 'small',
  label,
  placeHolder,
  helperText,
  tabIndex = -1,
  decimalPlaces,
  startIcon,
  endIcon,
  prefixText,
  suffixText,
  maxLength = 5,
  maxValue,
  gutter = '12px',
  hideErrorMessage = false,
  disabled = false,
  onChange,
  sx
}) => {

  const { field, fieldState } = useController({name, control});
  const [inputValue, setInputValue] = useState<string>(field.value?.toString() || '');
  
  const classes = {
    'TextInput': true,
    'TextInput-small': size == 'small',
    'TextInput-medium': size == 'medium',
    'TextInput-large': size == 'large',
  };

  const validClasses = Object.entries(classes)
      .filter(([,v]) => !!v)
      .map(([k,]) => k)
      .join(' ')

  useEffect(() => {
    if (field.value !== inputValue) setInputValue(field.value?.toString() || '');
  }, [field.value]);

  const handleBeforeInput = (event: BaseSyntheticEvent) => {
    const input = event.nativeEvent as InputEvent;
    const { data, target } = input;
    const value = (target as HTMLInputElement).value;
    const newValue = value + data;

    if (value === '0' && data === '0') {
      event.preventDefault();
      return;
    }

    if (decimalPlaces !== undefined && decimalPlaces > 0) {
      const regex = new RegExp(`^\\d{0,${maxLength}}(\\.\\d{0,${decimalPlaces}})?$`);
      const numericValue = parseFloat(newValue);

      if (!regex.test(newValue) || (maxValue !== undefined && numericValue > maxValue)) {
        event.preventDefault();
      }
    } else {
      const numericValue = parseInt(newValue);

      if (!/^\d*$/.test(data!) || (maxLength !== undefined && value.length >= maxLength) || 
          (maxValue !== undefined && numericValue > maxValue)) {
        event.preventDefault();
      }
    }
  };

  const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    const { clipboardData, target } = event;
    const pasteData = clipboardData.getData('Text');
    const value = (target as HTMLInputElement).value;

    if (decimalPlaces !== undefined && decimalPlaces > 0) {
      const regex = new RegExp(`^\\d{0,${maxLength}}(\\.\\d{0,${decimalPlaces}})?$`);
      const newValue = value + pasteData;

      if (!regex.test(newValue)) {
        event.preventDefault();
      }
    } else {
      if (maxLength !== undefined && value.length + pasteData.length > maxLength) {
        event.preventDefault();
      } else if (!/^\d*$/.test(pasteData)) {
        event.preventDefault();
      }  
    }
  };

  const handleChange = (event: BaseSyntheticEvent) => {
    const value = event.target.value;

    if (decimalPlaces !== undefined && decimalPlaces > 0) {
      if (value === "" || !isNaN(Number(value))) {
        if (value.split('.')[0].length > (maxLength)) {
          return;
        }
        setInputValue(value);
        if (value !== "" && !value.endsWith(".")) {
          field.onChange(parseFloat(value));
          onChange && onChange(parseFloat(value));
        }
      }
    } else {
      if (/^\d*$/.test(value)) {
        setInputValue(value);
        if (value !== "") {
          field.onChange(parseInt(value));
          onChange && onChange(parseInt(value));
        }
      }
    }
  }
  
  const handleBlur = () => {
    if (inputValue.endsWith(".")) {
      const newValue = inputValue.slice(0, -1);
      setInputValue(newValue);
      field.onChange(newValue === "" ? '' : parseFloat(newValue));
    } else if (decimalPlaces !== undefined && decimalPlaces > 0) {
      field.onChange(inputValue === "" ? '' : parseFloat(inputValue));
    } else {
      field.onChange(inputValue === "" ? '' : parseInt(inputValue));
    }
  } 

  return (
    // Width set to 100% to always fill what ever container it is in
    <Box className='input__container' sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', marginBottom: gutter, width: '100%'}}>
      {label && <Box display="flex" width="100%">
          <span className="label-text-02" style={{marginBottom: '0.5em', color: 'var(--text-secondary)'}}>{label}</span>
        </Box>}
      <TextField
        className={validClasses}
        value={inputValue || ''}
        inputProps={{
          ref: field.ref,
          name: field.name,
          // onChange: field.onChange,
          // onBlur: field.onBlur,
          maxLength: decimalPlaces !== undefined ? undefined : maxLength
        }}
        placeholder={placeHolder}
        tabIndex={tabIndex}
        autoComplete="true"
        error={fieldState.invalid}
        helperText={helperText && !fieldState.error?.message ? helperText : hideErrorMessage ? null : fieldState.error?.message}
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
        onBeforeInput={handleBeforeInput}
        onPaste={handlePaste}
        sx={sx}
        InputProps={{
          startAdornment: (
            <>
              {startIcon && <InputAdornment position="start" sx={{mr: 0, paddingRight: '12px'}}>{startIcon}</InputAdornment>}
              {prefixText && <InputAdornment position="start"><span style={{color: disabled ? 'var(--text-placeholder)' : 'var(--text-primary)', marginTop: '1px', marginRight: '-6px'}}>{prefixText}</span></InputAdornment>}
            </>
          ),
          endAdornment: (
            <>
              {suffixText && <InputAdornment position="end"><span style={{color: 'var(--text-placeholder)', marginRight: '4px'}}>{suffixText}</span></InputAdornment>}
              {fieldState.invalid && <InputAdornment position="end"><Error color="error" sx={{fontSize: '16px', marginRight: endIcon ? '4px' : '0'}} /></InputAdornment>}
              {endIcon && <InputAdornment position="end" sx={{ml: 0}}>{endIcon}</InputAdornment>}
            </>
          ),
        }}
      />
    </Box>
  );
};

  export default NumberInput;