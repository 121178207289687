import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm, useWatch } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { ISupplementBuilder } from "@/interfaces/supplement_plan/supplement_builder";
import { ISupplementPlan, supplementPlanFormSchema } from "@/interfaces/supplement_plan/supplement_plan";
import { useLazyGetSupplementPlanQuery, useUpdateSupplementPlanMutation } from "@/repositories/supplement_plan";

// Styles
import { Save, Settings } from "@carbon/icons-react";

// Components
import { Box, Container } from "@mui/material";
import Section from '@/components/supplement_builder/section';
import SecondaryToolbar from "@/components/secondary_toolbar";
import UnsavedChangesModal from "@/pages/common/unsaved_changes_modal";
import SectionMenu from '@/components/supplement_builder/section_menu';
import SupplementModal from "@/components/supplement_builder/supplement_modal";


const EditSupplementPlanPage: FC = () => {

    const { id, planId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [open, setOpen] = useState<string|null>(null);
    const [selectedSection, setSelectedSection] = useState<number>(0);
    
    const [getPlan, { data: plan }] = useLazyGetSupplementPlanQuery();
    const [savePlan, { isLoading: isSaving }] = useUpdateSupplementPlanMutation();
    const [saveDraftPlan, { isLoading: isSavingDraft }] = useUpdateSupplementPlanMutation();

    const formMethods = useForm<ISupplementPlan>({
        resolver: zodResolver(supplementPlanFormSchema),
        mode: 'onBlur',
        defaultValues: {
            name: '',
            focus: '',
            notes: '',
            sections: [{
                uuid: uuidv4(),
                name: '',
                supplements: []
            }],
            draft: true
        }
    });

    const name = useWatch({ control: formMethods.control, name: 'name' });

    useEffect(() => {
        if (id && planId) {
            getPlan({client_id: Number(id), plan_id: planId}).unwrap().then((p) => {
                formMethods.reset({
                    name: p.name,
                    focus: p.focus,
                    notes: p.notes,
                    sections: p.sections,
                    draft: p.draft ?? false
                })
                setLoading(false);
            }).catch((error: ApiError) => {
                navigate(`/clients/${id}/supplements`);
                showToast({type: 'error', apiError: error.type})
            });
        }
    }, [id, planId]);

    useEffect(() => {
        if (!loading && plan?.readonly) showToast({
            type: 'info',
            title: t('notifications.plan.readonly.title'),
            message: t('notifications.plan.readonly.message')
        });
    }, [loading]);

    const handleSelectSection = (index: number) => {
        setSelectedSection(index);
    }

    const handleUpdateSettings = (data: ISupplementBuilder) => {
        formMethods.setValue('name', data.name, { shouldDirty: true });
        formMethods.setValue('focus', data.focus, { shouldDirty: true });
        formMethods.setValue('notes', data.notes, { shouldDirty: true });
        setOpen(null);
    }

    const handleBack = () => {
        if (formMethods.formState.isDirty) return setOpen('unsaved');
        navigate(`/clients/${id}/supplements`);
    }

    const handleSave = (data: ISupplementPlan) => {
        if (!id || !planId) return;
        data.draft = false;
        savePlan({p: {client_id: Number(id), plan_id: planId}, data}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.plan.updated.title'), 
                message: t('notifications.plan.updated.message', {name: name})
            });
            navigate(`/clients/${id}/supplements`)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    const handleSaveDraft = () => {
        if (!id || !planId) return;
        const data = formMethods.getValues();
        data.draft = true;
        saveDraftPlan({p: {client_id: Number(id), plan_id: planId}, data}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.plan.updated.title'), 
                message: t('notifications.plan.updated.message', {name: name})
            });
            navigate(`/clients/${id}/supplements`)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Box display="flex" flexDirection="column" height="calc(100vh - 40px)" sx={{overflow: 'hidden'}}>

            <SecondaryToolbar
                title={name}
                isLoading={loading}
                onBack={handleBack}
                iconActions={([{kind: "ghost", icon: <Settings />, onClick: () => setOpen('settings')}])}
                action1={{
                    label: t('components.buttons.save'),
                    icon: <Save />,
                    loading: isSaving,
                    disabled: isSavingDraft || plan?.readonly,
                    onClick: formMethods.handleSubmit(handleSave),
                    sx: {minWidth: '115px'}
                }}
                action2={{
                    label: t('components.buttons.saveDraft'),
                    loading: isSavingDraft,
                    disabled: isSaving || plan?.active || plan?.readonly,
                    onClick: handleSaveDraft,
                }}
                sx={{borderBottom: 'none'}}
                />
            {open === 'settings' && <SupplementModal
                open={open === 'settings'}
                onClose={() => setOpen(null)}
                supplement={formMethods.getValues()}
                onSave={handleUpdateSettings}
                />}
            {open === 'unsaved' && 
                <UnsavedChangesModal 
                    open={open === 'unsaved'} 
                    onClose={() => setOpen(null)}
                    onSubmit={() => navigate(`/clients/${id}/supplements`)}
                    />}

            <FormProvider {...formMethods}>
                <Container sx={{padding: '16px 24px 0 24px', overflow: 'hidden'}}>
                    <Box className="Builder">

                        <SectionMenu
                            selectedIndex={selectedSection}
                            onSelectSection={handleSelectSection}
                            />

                        <Section
                            key={selectedSection}
                            selectedSection={selectedSection}
                            isLoading={loading}
                            />
                    </Box>
                </Container>
            </FormProvider>
        </Box>
    )
}

export default EditSupplementPlanPage;